import React from "react";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { ButtonFooter, FooterWrapper, CancelIcon, ButtonCancel } from "../based/ButtonStyles";
import TargetServices from "../based/services/TargetServices";
import useStore from "../based/store/useStore";
import { jwtDecode } from "jwt-decode";
import Common from "../based/Common";
import MessageValidation from "../based/MessageValidation";
import { CONSTANTS } from "../based/Constants";
import { useNavigate } from "react-router-dom";
const Modal = ({ show, children }) => {
    return <ModalWrapper className={show ? "show" : ""}>{children}</ModalWrapper>;
};

const Details = () => {
    const { gender } = useParams();
    const navigator = useNavigate();
    const [timedone, setTimedone] = useState(0);
    const [showMessage, setShowMessage] = useState(false);
    const [mss, setMss] = useState("");
    const [startDate, setStartDate] = useState("");
    const [listTargetByGender, setListTargetByGender] = useState([]);
    const [formAddUserTarget, setFormAddUserTarget] = useState({
        targetId: 0,
        timeStart: "",
        timeEnd: "",
    });

    const getCurrentDate = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        return `${day}/${month}/${year}`;
    };

    const GetDateAfter1Month = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 2).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        return `${day}/${month}/${year}`;
    };

    useEffect(() => {
        const token = Common.GetToken();
        if (token) {
            const decoded = jwtDecode(token);
            // console.log(decoded);
            async function fecthData() {
                const [err, data] = await TargetServices.GetUserTargetByUserId(decoded.id);
                if (!err) {
                    if (data != null) {
                        navigator("/plan");
                    }
                }
            }
            fecthData();
        }
    }, []);

    const HandleGetTargetByGender = async (gender) => {
        const [err, data] = await TargetServices.GetListTargetByGender(gender);
        if (!err) {
            setListTargetByGender(data);
        }
    };
    useEffect(() => {
        HandleGetTargetByGender(gender);
        setFormAddUserTarget({
            ...formAddUserTarget,
            timeStart: getCurrentDate(),
            timeEnd: GetDateAfter1Month(),
        });
    }, [gender]);

    const handleSelectTargetChange = (selectedTargetId) => {
        setFormAddUserTarget({ ...formAddUserTarget, targetId: selectedTargetId });
    };

    useEffect(() => {
        if (formAddUserTarget.timeStart && formAddUserTarget.timeEnd) {
            const timeStartValue = Common.convertStringToDate(formAddUserTarget.timeStart);
            const timeEndValue = Common.convertStringToDate(formAddUserTarget.timeEnd);
            const start = new Date(timeStartValue);
            const end = new Date(timeEndValue);

            const differenceInTime = end.getTime() - start.getTime();
            // check logic time about time start and time end
            if (differenceInTime < 0 || formAddUserTarget.timeStart.length < 10 || formAddUserTarget.timeEnd.length < 10 || formAddUserTarget.timeStart < getCurrentDate()) {
                setShowMessage(true);
                setMss(CONSTANTS.TIME_INVALID);
                setTimedone("không hợp lệ");
            } else {
                setShowMessage(false);
                const differenceInDays = differenceInTime / (1000 * 3600 * 24);
                setTimedone(differenceInDays);
            }
        }
        if (formAddUserTarget.timeStart.length < 10 || formAddUserTarget.timeEnd.length < 10) {
            setShowMessage(true);
            setMss(CONSTANTS.FILL_DATE_NOT_ENOUGH);
        }
    }, [formAddUserTarget.timeStart, formAddUserTarget.timeEnd]);

    useEffect(() => {
        if (formAddUserTarget.targetId !== 0) {
            setShowMessage(false);
        }
    }, [formAddUserTarget.targetId]);

    function formatInputDate(inputDate) {
        // setShowMessage(false);
        const valueInput = inputDate.replace(/[^0-9/]/g, "");

        if (valueInput.length <= startDate.length) {
            setStartDate(valueInput);
            return valueInput;
        }
        setStartDate(valueInput);

        const date = valueInput.split("/").join("");

        if (date.length <= 2) {
            const day = date.substring(0, 2);
            if (day.length < 2) {
                if (day >= 0 && day <= 31) {
                    return date.substring(0, 2);
                }
            } else {
                if (day > 31 || day < 1) {
                    return date.substring(0, 1);
                }
            }
        }

        if (date.length <= 4) {
            const month = date.substring(2, 4);
            console.log("moth", month);
            if (month.length < 2) {
                if (month >= 0 && month <= 12) {
                    return date.substring(0, 2) + "/" + date.substring(2);
                }
            } else {
                if (month > 12 || month < 1) {
                    return date.substring(0, 2) + "/";
                }
            }
        }

        if (date.length <= 8) {
            const year = date.substring(4, 8);

            if (year.length < 4) {
                if (year <= 2050) {
                    return date.substring(0, 2) + "/" + date.substring(2, 4) + "/" + date.substring(4);
                } else {
                    return date.substring(0, 2) + "/" + date.substring(2, 4) + "/";
                }
            } else {
                if (year < 1900 || year > 2050) {
                    return date.substring(0, 2) + "/" + date.substring(2, 4) + "/";
                } else {
                    return date.substring(0, 2) + "/" + date.substring(2, 4) + "/" + date.substring(4, 8);
                }
            }
        }

        if (date.length > 10) {
            return date.substring(0, 2) + "/" + date.substring(2, 4) + "/" + date.substring(4, 8);
        }

        return date.substring(0, 2) + "/" + date.substring(2, 4) + "/" + date.substring(4, 8);
    }
    const handleClickInitPlan = async () => {
        if (showMessage || formAddUserTarget.targetId === 0) {
            setShowMessage(true);
            setMss("Vui lòng chọn ít nhất 1 mục tiêu!");
        } else {
            const formAddUserTargetAPI = formAddUserTarget;
            formAddUserTargetAPI.timeStart = Common.convertStringToDate(formAddUserTargetAPI.timeStart);
            formAddUserTargetAPI.timeEnd = Common.convertStringToDate(formAddUserTargetAPI.timeEnd);
            const [err, data] = await TargetServices.CreateUpdateUserTarget(formAddUserTargetAPI);
            if (!err) {
                console.log(data);
                navigator("/plan");
            } else {
                console.log(err);
            }
        }
    };

    return (
        <>
            <DetailsWrapperParent>
                <DetailsWrapper>
                    <DetailsTitle>Mục tiêu</DetailsTitle>
                    <DetailsContentWrapper>
                        <DetailsForGender props={listTargetByGender} onSelectTargetChange={handleSelectTargetChange} />
                    </DetailsContentWrapper>

                    <TimeDoneWrapper>
                        <TimeDoneTitle>Thời gian hoàn thành</TimeDoneTitle>
                        <TimeDoneSubTitle>
                            Tổng số ngày <span>{timedone}</span>
                        </TimeDoneSubTitle>
                    </TimeDoneWrapper>
                    <TimeDoneItem>
                        <TimeDoneItemLeft>
                            <ItemLabel>Ngày bắt đầu</ItemLabel>
                            {/* <div contentEditable={true} value={startDate} onChange={handleStartDateChange}></div> */}
                            <Input
                                onChange={(e) => {
                                    const enteredDate = e.target.value;
                                    const formattedDate = formatInputDate(enteredDate);
                                    setFormAddUserTarget({ ...formAddUserTarget, timeStart: formattedDate });
                                }}
                                value={formAddUserTarget.timeStart}
                                type="text"
                                inputMode="numeric"
                                required="required"
                                placeholder="(dd/mm/yyyy)"
                            ></Input>
                        </TimeDoneItemLeft>
                        <TimeDoneItemRight>
                            <ItemLabel>Ngày kết thúc</ItemLabel>
                            <Input
                                onChange={(e) => {
                                    const enteredDate = e.target.value;
                                    const formattedDate = formatInputDate(enteredDate);
                                    setFormAddUserTarget({ ...formAddUserTarget, timeEnd: formattedDate });
                                }}
                                value={formAddUserTarget.timeEnd}
                                type="text"
                                inputMode="numeric"
                                required="required"
                                placeholder="(dd/mm/yyyy)"
                            ></Input>
                        </TimeDoneItemRight>
                    </TimeDoneItem>
                    <MessageValidation isShow={showMessage} message={mss} />
                </DetailsWrapper>
                <FooterWrapper>
                    <ButtonFooter onClick={handleClickInitPlan}>Lên kế hoạch luyện tập</ButtonFooter>
                </FooterWrapper>
            </DetailsWrapperParent>
        </>
    );
};

export default Details;

const DetailsForGender = ({ props, onSelectTargetChange }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const handleShowModal = (id) => {
        setShowModal(!showModal);
        setSelectedItemId(id);
    };
    const handleClickAction = (id) => {
        setSelectTarget(id);
        onSelectTargetChange(id);
    };

    const [selectTarget, setSelectTarget] = useState();
    return (
        <>
            <ItemWrapper>
                {props.map((item, index) => {
                    return (
                        <Item onClick={() => handleClickAction(item.id)} bgImage={item.thumbnail} key={index}>
                            <ItemModal className={(showModal && item.id === selectedItemId) || item.id === selectTarget ? "show" : ""}></ItemModal>

                            <ItemLeft>
                                <Label>{item.nameEN}</Label>
                                <Title>{item.name}</Title>
                            </ItemLeft>
                            <Action>
                                <ClickAction>
                                    {item.id === selectTarget ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <circle cx="8" cy="8" r="8" fill="#FF5C00" />
                                            <path d="M4.5 8L7.5 10.5L11.5 6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <circle cx="8" cy="8" r="7.5" fill="white" stroke="#939393" />
                                        </svg>
                                    )}
                                </ClickAction>
                                <HelpAction onClick={() => handleShowModal(item.id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <g clipPath="url(#clip0_14_66)">
                                            <path
                                                d="M8.00001 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 7.99999C14.6667 4.3181 11.6819 1.33333 8.00001 1.33333C4.31811 1.33333 1.33334 4.3181 1.33334 7.99999C1.33334 11.6819 4.31811 14.6667 8.00001 14.6667Z"
                                                stroke="white"
                                                strokeOpacity="0.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M6.06 6C6.21673 5.55444 6.5261 5.17874 6.9333 4.93942C7.3405 4.7001 7.81926 4.61262 8.28478 4.69247C8.7503 4.77232 9.17254 5.01435 9.47672 5.37568C9.78089 5.73702 9.94737 6.19435 9.94666 6.66667C9.94666 8 7.94666 8.66667 7.94666 8.66667V9.5"
                                                stroke="white"
                                                strokeOpacity="0.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path d="M8 11.3333H8.0075" stroke="white" strokeOpacity="0.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_14_66">
                                                <rect width="16" height="16" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </HelpAction>
                            </Action>
                        </Item>
                    );
                })}
            </ItemWrapper>

            <Modal show={showModal}>
                {selectedItemId !== null && (
                    <>
                        {props.map((item) => {
                            if (item.id == selectedItemId) {
                                return (
                                    <>
                                        <ModalInner>
                                            <ModalHeader>
                                                <ModalTitleWrapper>
                                                    <ModalTitle>{item.name}</ModalTitle>
                                                    <ModalSubTitle>{item.nameEN}</ModalSubTitle>
                                                </ModalTitleWrapper>

                                                <CancelIcon onClick={handleShowModal}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 50 50">
                                                        <path
                                                            fill="#FFFFFF"
                                                            d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"
                                                        ></path>
                                                    </svg>
                                                </CancelIcon>
                                            </ModalHeader>

                                            <ModalBody>
                                                <BodyTitle>{item.description}</BodyTitle>
                                                <BodySubTitle>
                                                    <SubHeader>Phù hợp:</SubHeader>
                                                    <SubItemWrapper>
                                                        {item.advantage
                                                            .split(".")
                                                            .filter((adv) => adv.trim() !== "")
                                                            .map((adv, index) => (
                                                                <SubItem key={index}>{adv}</SubItem>
                                                            ))}
                                                    </SubItemWrapper>
                                                </BodySubTitle>
                                            </ModalBody>
                                            <ButtonCancel onClick={handleShowModal}>Đóng</ButtonCancel>
                                        </ModalInner>
                                    </>
                                );
                            }
                        })}
                    </>
                )}
            </Modal>
        </>
    );
};
const DetailsWrapper = styled.div`
    height: 100%;
    width: 100vw;
    padding: 20px 15px;
    position: fixed;
`;

const DetailsTitle = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

const DetailsContentWrapper = styled.div`
    width: 100%;
`;

const ImageItem = styled.img``;
const Label = styled.h3`
    color: rgba(255, 255, 255, 0.5);
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.35);
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const Title = styled.h3`
    color: #fff;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.35);
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;
const Action = styled.div`
    display: flex;
    flex-direction: column;

    height: 100%;
    justify-content: space-between;
    align-items: start;
`;
const HelpAction = styled.div`
    padding: 8px;
`;
const ClickAction = styled.div`
    padding: 8px;
`;

const ItemWrapper = styled.div`
    width: 100%;
    height: 240px;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 16px;
`;
const Item = styled.div`
    background-image: url(${(props) => (props.bgImage ? props.bgImage : "https://www.w3schools.com/howto/img_avatar.png")});
    width: 46%;
    height: 112px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;

    background-size: 250px;
    background-position-x: 50%;
    background-position-y: 40%;
    background-repeat: no-repeat;
    border-radius: 16px;
    margin-right: 6px;

    position: relative;
`;
const ItemModal = styled.div`
    width: 100%;
    height: 100%;

    position: absolute;
    border-radius: 16px;
    border: 1px solid #484848;
    background: linear-gradient(0deg, rgba(253, 91, 0, 0.5) 0%, rgba(253, 91, 0, 0.5) 100%);
    box-shadow: -2px 4px 30px 0px rgba(0, 0, 0, 0.2);
    display: none;
    &.show {
        display: block;
    }
`;
const ItemLeft = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px;
    align-self: end;
`;

const TimeDoneWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 34px;
    margin-bottom: 16px;
`;
const TimeDoneTitle = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;
const TimeDoneSubTitle = styled.h3`
    color: #9a9a9a;
    text-align: right;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const TimeDoneItem = styled.div`
    display: flex;
    justify-content: space-between;
`;
const ItemLabel = styled.span`
    color: #9a9a9a;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    position: absolute;
    line-height: normal;
`;
const InputTime = styled.input`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 100%;

    background: #3a3a3a;
    outline: none;
    border: none;
    margin-top: 4px;
`;
const TimeDoneItemWrapper = styled.div`
    width: 45%;
    height: 65px;
    border-radius: 12px;
    background: #3a3a3a;
    flex-shrink: 0;
    position: relative;
    padding-top: 12px;
    padding-left: 12px;
    display: flex;
`;
const TimeDoneItemLeft = styled(TimeDoneItemWrapper)``;
const TimeDoneItemRight = styled(TimeDoneItemWrapper)``;

const ModalWrapper = styled.div`
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    animation: fade-in 0.5s;
    &.show {
        display: block;
    }

    &.show {
        display: flex;
    }
`;

const ModalInner = styled.div`
    display: flex;
    width: 303px;
    margin: auto;
    height: auto;
    padding: 16px;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 16px 148px;
    flex-wrap: wrap;
    border-radius: 16px;
    background: #272727;
    position: relative;

    .cancel {
    }
`;

const ModalHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;
const ModalTitleWrapper = styled.div``;
const ModalTitle = styled.h3`
    margin-bottom: 4px;
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;
const ModalSubTitle = styled.h3`
    color: #878787;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.35);
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const ModalBody = styled.div`
    color: #878787;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 271px;
    margin-top: 16px;
    margin-bottom: 16px;
    flex-shrink: 0;
`;
const BodyTitle = styled.h3`
    margin-bottom: 16px;
    font-size: 14px;
`;
const BodySubTitle = styled.div``;
const SubHeader = styled.h3`
    color: #ff5c00;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;
const SubItem = styled.li`
    color: #878787;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const SubItemWrapper = styled.ul`
    padding-left: 20px;
`;

const DetailsWrapperParent = styled.div`
    width: 100%;
    height: 100vh;
    background-color: var(--bg-color);
    position: fixed;
`;
const Input = styled.input`
    background: rgb(58, 58, 58);
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    color: #fff;
`;
