import styled from "styled-components";
import { IconCommon } from "../../based/IconShared";
import { Tick } from "../../based/ConfigIconSvg";
import ModalDetailsPlace from "./ModalDetailsPlace";
import ModalAddPlace from "./ModalAddPlace";
import { useState, useEffect } from "react";
import PlaceServices from "../../based/services/PlaceServices";

const ModalPlace = ({ isOpen, onClose, onSave }) => {
    const [listPlaceTemp, setListPlaceTemp] = useState();
    const [isModalDetailsPlace, setIsModalDetailsPlace] = useState(false);
    const [isOpenModalAddPlace, setIsOpenModalAddPlace] = useState(false);
    const [selectedPlace, setSelectedPlace] = useState(false);
    const [listPlace, setListPlace] = useState([]);
    const handleOpenModalAddPlace = () => {
        setIsOpenModalAddPlace(true);
    };
    const handleCloseModalAddPlace = () => {
        setIsOpenModalAddPlace(false);
    };

    const handleSaveModalAddPlace = () => {
        _handleGetAllPlaceOfUser();
        setIsOpenModalAddPlace(false);
    };

    const handleOpenModalDetailsPlace = () => {
        setIsModalDetailsPlace(true);
    };

    const handleCloseModalDetailsPlace = () => {
        setIsModalDetailsPlace(false);
    };

    const handleSaveModalDetailsPlace = () => {
        setIsModalDetailsPlace(false);
    };

    const _handleGetAllPlaceOfUser = async () => {
        const [err, data] = await PlaceServices.GetPlaceByUserName();
        if (!err) {
            setListPlace(data);
        }
    };
    useEffect(() => {
        _handleGetAllPlaceOfUser();
    }, []);

    const _handleRemoveItemById = async (id) => {
        const [err, data] = await PlaceServices.RemovePlaceById(id);
        if (!err) {
            _handleGetAllPlaceOfUser();
        }
    };

    return (
        <>
            <ModalPlaceWrapper style={{ display: isOpen ? "flex" : "none" }}>
                <ModalAddPlace isOpen={isOpenModalAddPlace} onClose={handleCloseModalAddPlace} onSave={handleSaveModalAddPlace}></ModalAddPlace>
                <ModalWrapperInner>
                    <ModalHeader>
                        <HeaderTitle>Nơi tập</HeaderTitle>
                        <HeaderAction src={IconCommon.deleteicon} onClick={onClose}></HeaderAction>
                    </ModalHeader>
                    <ActionTop>
                        <YourPlaceWrapper onClick={() => setIsOpenModalAddPlace(true)}>
                            <IconAdd src={IconCommon.add}></IconAdd>
                            <Title>Nơi tập của bạn</Title>
                        </YourPlaceWrapper>
                        <GymPlaceWrapper onClick={handleOpenModalDetailsPlace}>
                            <IconAdd src={IconCommon.add}></IconAdd>
                            <Title>Phòng Gym</Title>
                        </GymPlaceWrapper>
                    </ActionTop>
                    <ModalBody>
                        <ModalScrollBody>
                            {listPlace &&
                                listPlace.map((item, index) => (
                                    <>
                                        <ItemWrapperScroll
                                            onClick={() => {
                                                setSelectedPlace(item);
                                                onSave(item);
                                            }}
                                            key={index}
                                        >
                                            <ItemLeft>
                                                <ActionChoose>
                                                    <Tick active={selectedPlace === item}></Tick>
                                                </ActionChoose>
                                                <ImageWrapper>
                                                    <ImagePlace isActive={selectedPlace === item} bgImage={item.thumbnail}></ImagePlace>
                                                </ImageWrapper>
                                                <TitlePlaceWrapper>
                                                    <TitlePlace color={selectedPlace === item ? "#0352FE" : ""}>{item.name}</TitlePlace>
                                                    <ScoutDevice>34 Thiết bị tập</ScoutDevice>
                                                </TitlePlaceWrapper>
                                            </ItemLeft>
                                            <ItemRight
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    _handleRemoveItemById(item.id);
                                                }}
                                                src={IconCommon.recycle}
                                            ></ItemRight>
                                        </ItemWrapperScroll>
                                    </>
                                ))}
                        </ModalScrollBody>
                    </ModalBody>
                    <ModalDetailsPlace isOpen={isModalDetailsPlace} onClose={handleCloseModalDetailsPlace} onSave={handleSaveModalDetailsPlace}></ModalDetailsPlace>
                </ModalWrapperInner>
            </ModalPlaceWrapper>
        </>
    );
};

export default ModalPlace;

const ModalPlaceWrapper = styled.div`
    width: 100%;
    height: 100vh;
    bottom: 0;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: flex-end;
    z-index: 2;
    background: rgba(0, 0, 0, 0.8);
`;

const ModalWrapperInner = styled.div`
    width: 100%;
    margin: 0 auto;
    height: 70%;

    background: #272727;
    position: relative;
    /* border-radius: 16px 16px 0px 0px; */

    border-radius: 16px;
`;

const ModalHeader = styled.div`
    background: rgba(39, 39, 39, 0.6);

    height: 8%;
    backdrop-filter: blur(4px);
    display: flex;
    position: relative;
    align-items: center;

    justify-content: center;

    background: rgba(39, 39, 39, 0.6);
    box-shadow: 0px 1px 0px 0px #3f3f3f;
    backdrop-filter: blur(4px);
`;

const HeaderTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

const ModalBody = styled.div`
    height: 67%;
    width: 100%;
    padding: 10px;
`;

const HeaderAction = styled.img`
    position: absolute;
    right: 10px;
    margin-bottom: 0.5rem;
    z-index: 2;
`;

const Button = styled.button`
    width: 40%;
    height: 42px;
    flex-shrink: 0;
    border-radius: 50px;
    border: 1px solid #484848;
    background: #3a3a3a;
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;
const YourPlaceWrapper = styled.div`
    display: flex;
    width: 180px;
    padding: 11px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: #3a3a3a;
`;
const IconAdd = styled.img``;

const Title = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const GymPlaceWrapper = styled.div`
    display: flex;
    border-radius: 12px;
    background: #3a3a3a;
    width: 149px;
    padding: 11px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
`;

const ActionTop = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 12px;
`;
const ModalScrollBody = styled.div`
    height: 100%;
    overflow-y: scroll;
`;

const ItemWrapperScroll = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 15%;
    background: ${(props) => props.bg};
`;

const ItemLeft = styled.div`
    display: flex;
    align-items: center;
`;

const ActionChoose = styled.div``;

const ImagePlace = styled.div`
    width: 70px;
    height: 42px;

    background-size: cover;

    ${(props) =>
        props.isActive
            ? `
            background:  linear-gradient(0deg, rgba(3, 82, 254, 0.50) 0%, rgba(3, 82, 254, 0.50) 100%), url(${props.bgImage}) lightgray 50% / cover no-repeat;
        `
            : `
        background: url(${props.bgImage}) lightgray 50% / cover no-repeat;
        `}

    background-size: cover;
    margin: 0px 12px;
    flex-shrink: 0;
`;

const TitlePlaceWrapper = styled.div``;
const TitlePlace = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    color: ${(props) => props.color};
    font-weight: 500;
    line-height: normal;
`;
const ScoutDevice = styled.h3`
    color: #939393;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const ItemRight = styled.img`
    width: 16.515px;
    height: 19.6px;
    flex-shrink: 0;
`;
const ImageWrapper = styled.div`
    /* background-color: red; */
`;
