import styled from "styled-components";
import { IconCommon, Arrow } from "../../based/IconShared";
import { useEffect, useState } from "react";
import { listPlace } from "../../based/FakeApi";
import { UploadIcon } from "../../based/ConfigIconSvg";
import { DeviceItemScroll } from "./CommonPlace";
import Request from "../../based/Request";
import PlaceServices from "../../based/services/PlaceServices";
import PlaceDeviceServices from "../../based/services/PlaceDeviceServices";
import DeviceServices from "../../based/services/DeviceServices";

const DeviceFake = [
    {
        id: 1,
        name: "Thiết bị 1",
        count: 50,
    },
    {
        id: 2,
        name: "Thiết bị 2",
        count: 50,
    },
    {
        id: 3,
        name: "Thiết bị 3",
        count: 50,
    },
    {
        id: 4,
        name: "Thiết bị 4",
        count: 50,
    },
];

const ModalAddPlace = ({ isOpen, onClose, onSave }) => {
    const [listPlaceTemp, setListPlaceTemp] = useState(listPlace);
    const [isOpenModalDetailsGymRoom, setIsOpenModalDetailsGymRoom] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState(1);
    const [thumbnail, setThumbnail] = useState("");

    const [formAddPlace, setFormAddPlace] = useState({
        name: "",
        location: "string",
        placeTypeId: 1,
        provinceCode: "string",
        districtCode: "string",
        wardCode: "string",
        thumbnail: "",
    });
    const [fromAddDevicePlace, setFormAddDevicePlace] = useState({
        placeId: "",
        deviceId: [],
    });

    const handleFileChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                Request.UploadStockPhoto(file)
                    .then((res) => {
                        if (res && res.data) {
                            setFormAddPlace({
                                ...formAddPlace,
                                thumbnail: res.data.imageUrl,
                            });
                            setThumbnail(res.data.imageUrl);
                        } else {
                            console.log("Upload failed");
                        }
                    })
                    .catch((err) => {});
            };

            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {}, []);

    const getHeaderBodyProps = (menuNumber) => {
        return {
            bg: selectedMenu === menuNumber ? "" : "#2f2f2f",
            colorText: selectedMenu === menuNumber ? "#0352FE" : "",
        };
    };
    const _handleOnChanged = (item) => {
        console.log(item);
        const deviceId = item.map((i) => i.id);
        setFormAddDevicePlace({
            ...fromAddDevicePlace,
            deviceId: deviceId,
        });
    };

    const _handleSavePlace = async () => {
        const [err, data] = await PlaceServices.CreateUpdatePlace(formAddPlace);
        if (!err) {
            console.log(data);
            let formAddDevice = { ...fromAddDevicePlace };
            formAddDevice.placeId = data;

            const [err1, data1] = await PlaceDeviceServices.CreateUpdatePlaceDeviceDetailWithListDeviceId(formAddDevice);
            if (!err1) {
                console.log(data1);
                onSave();
            }
        }
    };

    return (
        <>
            <ModalAddPlaceWrapper style={{ display: isOpen ? "flex" : "none" }}>
                <ModalWrapperInner>
                    <ModalHeader>
                        <IconBack onClick={onClose} src={Arrow.chevronleft}></IconBack>
                        <HeaderTitle>Thêm phòng Gym</HeaderTitle>
                        <HeaderAction src={IconCommon.deleteicon} onClick={onClose}></HeaderAction>
                    </ModalHeader>
                    <ModalBody>
                        <HeaderBodyTop>
                            <HeaderBody onClick={() => setSelectedMenu(1)} {...getHeaderBodyProps(1)}>
                                <h3>Thông tin nơi tập</h3>
                            </HeaderBody>
                            <HeaderBody onClick={() => setSelectedMenu(2)} {...getHeaderBodyProps(2)}>
                                <h3>Thiết bị tập</h3>
                            </HeaderBody>
                        </HeaderBodyTop>
                        {selectedMenu === 1 ? (
                            <>
                                <BodySearchWrapper>
                                    <TitleInput>
                                        Tên nơi tập <span>*</span>
                                    </TitleInput>
                                    <input
                                        value={formAddPlace.name}
                                        onChange={(e) => {
                                            setFormAddPlace({
                                                ...formAddPlace,
                                                name: e.target.value,
                                            });
                                        }}
                                        placeholder="Nhập tên nơi tập"
                                    ></input>
                                </BodySearchWrapper>
                                <BodyImageWrapper>
                                    <TitleInput>
                                        Hình ảnh
                                        <span>*</span>
                                    </TitleInput>
                                    <InputImage>
                                        <label htmlFor="fileInputPlace">
                                            <p>
                                                {!thumbnail ? (
                                                    <>
                                                        <UploadIcon />
                                                        <span>Tải ảnh lên</span>
                                                    </>
                                                ) : (
                                                    <ImagePlace src={thumbnail} />
                                                )}
                                            </p>
                                        </label>

                                        <input type="file" id="fileInputPlace" accept="image/*" onChange={handleFileChange} />
                                    </InputImage>
                                </BodyImageWrapper>
                            </>
                        ) : (
                            <DeviceItemScroll onChanged={(item) => _handleOnChanged(item)} />
                        )}
                        <ModalFooter>
                            <FooterActionWrapper>
                                <FooterAction onClick={onClose}>Hủy</FooterAction>
                                <FooterActionConfirm onClick={_handleSavePlace}>Xác nhận</FooterActionConfirm>
                            </FooterActionWrapper>
                        </ModalFooter>
                    </ModalBody>
                </ModalWrapperInner>
            </ModalAddPlaceWrapper>
        </>
    );
};

export default ModalAddPlace;

const ModalAddPlaceWrapper = styled.div`
    width: 100%;
    height: 100vh;
    top: 0;
    display: flex;
    position: fixed;
    justify-content: center;
    z-index: 2;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(4px);
`;

const ModalWrapperInner = styled.div`
    width: 100%;
    margin: 0 auto;
    height: 100vh;

    background: #3a3a3a;
    position: fixed;
    top: 0;

    border-radius: 16px;
`;

const ModalHeader = styled.div`
    height: 7%;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    background: rgba(39, 39, 39, 0.6);
    border-radius: 16px 16px 0px 0px;
    background: #2f2f2f;
`;

const HeaderTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

const ModalBody = styled.div`
    width: 100%;
    height: 80%;

    overflow-y: scroll;
`;

const HeaderAction = styled.img`
    margin-right: 10px;
`;

const TitlePlaceWrapper = styled.div``;
const TitlePlace = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;
const ScoutDevice = styled.h3`
    color: #939393;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const ItemRight = styled.img`
    width: 16.515px;
    height: 19.6px;
    flex-shrink: 0;
`;

const IconBack = styled.img`
    margin-left: 12px;
`;

const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 18px 0px;
`;

const configActionLeftRight = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 182px;
    height: 42px;
    border-radius: 12px;
    background: #2f2f2f;
    flex-shrink: 0;
`;
const ActionLeft = styled(configActionLeftRight)``;
const ActionRight = styled(configActionLeftRight)``;

const FindWrapper = styled.div`
    width: 100%;
    height: 5%;
    display: flex;
    justify-content: center;
`;

const InputFind = styled.input`
    width: 100%;
    color: #fff;
    border-radius: 12px;
    background: #2f2f2f;
    border: none;
    padding: 0 19px;
    height: 100%;
`;

const TitleAction = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const IconAction = styled.div``;
const FindWrapper2 = styled.div`
    width: 90%;
    position: relative;
    margin: 0 auto;
`;

const IconSearch = styled.img`
    position: absolute;
    top: 8px;
    right: 10px;
`;
const ScrollItemWrapper = styled.div`
    width: 100%;
    height: 100%;
    /* justify-content:space-around; */
    display: flex;
    flex-wrap: wrap;
`;

const ModalFooter = styled.div`
    width: 100%;
    height: 10%;
    background: rgba(39, 39, 39, 0.8);
    box-shadow: 0px -1px 0px 0px #3f3f3f;
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    padding: 10% 0;
    bottom: 0;
`;
const FooterActionWrapper = styled.div`
    width: 80%;
    align-items: center;
    height: 100%;
    display: flex;
    justify-content: space-around;
`;
const Button = styled.button`
    width: 40%;
    height: 42px;
    flex-shrink: 0;
    border-radius: 50px;
    border: 1px solid #484848;
    background: #3a3a3a;
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;
const FooterAction = styled(Button)`
    /* width: 40%;
  height: 42px;
  flex-shrink: 0;
  border-radius: 50px;
  border: 1px solid #484848;
  background: #3a3a3a; */
`;

const FooterActionConfirm = styled(Button)`
    border-radius: 50px;
    border: 1px solid rgba(184, 229, 255, 0.2);
    background: linear-gradient(268deg, #0352fe 0.45%, #0060ff 97.59%);
    box-shadow: 0px 0px 15px 0px rgba(3, 82, 254, 0.4);
`;

const HeaderBody = styled.div`
    width: 50%;
    background-color: ${(props) => props.bg};
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
        color: ${(props) => (props.colorText ? props.colorText : "#878787")};
        text-align: center;
        font-family: "Be Vietnam Pro";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
`;
const HeaderRight = styled.div`
    width: 50%;
`;
const HeaderBodyTop = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
`;

const BodySearchWrapper = styled.div`
    width: 90%;
    margin: 0 auto;
    margin-top: 15px;

    input {
        border-radius: 12px;
        background: #2f2f2f;
        border: none;
        padding: 12px 16px;
        outline: none;
        color: #fff;
        width: 100%;
    }
`;
const TitleInput = styled.h3`
    color: #939393;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 12px;
    span {
        color: red;
    }
`;

const BodyImageWrapper = styled.div`
    width: 90%;
    margin: 0 auto;
    margin-top: 24px;
`;

const InputImage = styled.div`
    width: 100%;
    height: 191px;
    label {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-radius: 12px;
        background: #2f2f2f;
        height: 100%;
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
        }
        p {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }
        span {
            color: #939393;
            font-family: "Be Vietnam Pro";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 12px;
        }
    }
    input {
        border-radius: 12px;
        background: #2f2f2f;
        border: none;
        padding: 12px 16px;
        outline: none;
        color: #fff;
        display: none;
    }
`;
const DeviceTypeWrapper = styled.div`
    width: 90%;
    margin: 0 auto;
    margin-top: 6%;
    margin-bottom: 6%;
    display: flex;
    overflow-x: scroll;
`;
const DeviceType = styled.div`
    display: flex;
    width: 33%;
    margin-right: 2%;
    height: auto;
    max-height: 60px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid rgba(184, 229, 255, 0.2);
    background: rgba(3, 82, 254, 0.3);
    padding: 6px 16px;
`;
const CountDevice = styled.p`
    color: rgba(184, 229, 255, 0.5);
    font-family: "Be Vietnam Pro";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
`;
const IconDevice = styled.div`
    width: 30%;
`;
const DetailDevice = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
`;

const TitleDevice = styled.p`
    color: #b8e5ff;
    margin: 0;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;
const ListItemWrapperScroll = styled.div`
    width: 100vw;
    padding: 0 12px;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    height: 60%;
    overflow: scroll;

    justify-content: space-between;
`;
const ImagePlace = styled.div`
    background-image: url(${(props) => props.src});
    width: 100%;
    height: 100%;
    background-position: center;
    margin-top: 12px;
    background-size: cover;
    background-repeat: no-repeat;
`;
