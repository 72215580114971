import BaseServices from "./BaseServices";

const PlaceDeviceServices = {
    CreateUpdatePlaceDeviceDetail: async (model) => {
        return await BaseServices.Post(`/api/PlaceDeviceDetail/create-update-place-device`, model);
    },
    GetListDeviceByPlaceId: async (id) => {
        return await BaseServices.Get(`/api/PlaceDeviceDetail/get-list-device-by-place-id/${id}`);
    },
    CreateUpdatePlaceDeviceDetailWithListDeviceId: async (model) => {
        return await BaseServices.Post(`/api/PlaceDeviceDetail/create-update-place-device-with-list-device-id`, model);
    },
};

export default PlaceDeviceServices;
