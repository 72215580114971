import BaseServices from "./BaseServices";

const SportServices = {
    GetAllSport: async () => {
        return await BaseServices.Get(`/api/Sport/get-all-sport`);
    },
    CreateUpdateSport: async (model) => {
        return await BaseServices.Post(`/api/Sport/create-update-sport`, model);
    },

    RemoveSportById: async (id) => {
        return await BaseServices.Get(`/api/Sport/remove-sport-by-id/${id}`);
    }
};

export default SportServices;
