export const RequestDomain = {
    APIUrl: () => {
        return process.env.NODE_ENV === "production" ? "https://api.tapta.net" : "https://localhost:7093";
    },
    CDNUrl: () => {
        return process.env.NODE_ENV === "production" ? "https://api.tapta.net" : "https://localhost:7093";
    },
    HomeApiUrl: () => {
        return process.env.NODE_ENV === "production" ? "https://api.tapta.net" : "https://localhost:6003";
    },
    HomeUrl: () => {
        return process.env.NODE_ENV === "production" ? "https://tapta.net" : "https://localhost:5000";
    },
    CreatorUrl: () => {
        return process.env.NODE_ENV === "production" ? "https://api.tapta.net" : "https://localhost:7000";
    },
    AdminUrl: () => {
        return process.env.NODE_ENV === "production" ? "https://admin.tapta.net" : "https://localhost:7000";
    },
};
