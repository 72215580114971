import pic1 from "../assets/ImageMen/increasepower_picture.png";
import pic2 from "../assets/ImageMen/savemuscle_picture.png";
import pic3 from "../assets/ImageMen/saveweight_picture.png";
import pic4 from "../assets/ImageMen/gainweight_picture.png";
import pic5 from "../assets/ImageWomen/increasepower_picture.png";
import pic6 from "../assets/ImageWomen/savemuscle_picture.png";
import pic7 from "../assets/ImageWomen/saveweight_picture.png";
import pic8 from "../assets/ImageWomen/gainweight_picture.png";

import gymTamBinh from "../assets/ImagePlace/GymTanBinhFull.png";
import GiaDinhPark from "../assets/ImagePlace/GiaDinhPark.png";
import GymRoom from "../assets/ImagePlace/GymRoom.png";
import Home from "../assets/ImagePlace/Home.png";
import ParkByUser from "../assets/ImagePlace/ParkByUser.png";

export const userData = [
    {
        id: 1,
        uid: 1,
        name: "Nguyễn Đức Hoàng",
        username: "hoangbrato@gmail.com",
        password: "123456",
    },
    {
        id: 1,
        uid: 1,
        name: "Nguyễn Đức Gia Đăng ",
        username: "giadang@gmail.com",
        password: "123456",
    },
];
export const details_user = [
    {
        gender: 1,
        dob: "20/5/2002",
        tall: "175cm",
        height: "65kg",
        timeStart: "20/5/2021",
        timeEnd: "20/6/2022",
        target_id: 1,
        user_id: 1,
    },
];
export const Target = [
    {
        id: "1",
        label: "increase strength",
        text: "Tăng thể lực",
        forGender: 1,
        img: pic1,
    },
    {
        id: "2",
        label: "Maintaining",
        text: "Giữ cân, giữ cơ",
        forGender: 1,
        img: pic2,
    },
    {
        id: "4",
        label: "Diet",
        text: "Giữ cân, giữ cơ",
        forGender: 1,
        img: pic3,
    },
    {
        id: "3",
        label: "Cutting",
        text: "Tăng cân, tăng cơ",
        forGender: 1,
        img: pic4,
    },
    {
        id: "1",
        label: "Strength",
        text: "Tăng thể lực",
        forGender: 2,
        img: pic5,
    },
    {
        id: "2",
        label: "Maintaining",
        text: "Giữ cân, giữ cơ",
        forGender: 2,
        img: pic6,
    },
    {
        id: "4",
        label: "Diet",
        text: "Giữ cân, giữ cơ",
        forGender: 2,
        img: pic7,
    },
    {
        id: "3",
        label: "Cutting",
        text: "Tăng cân, tăng cơ",
        forGender: 2,
        img: pic8,
    },
];

export const plan = [
    {
        id: 1,
        title: "Kế hoạch tập cơ bụng",
        id_user: 1,
    },
    {
        id: 2,
        title: "Kế hoạch tập chân",

        id_user: 1,
    },
    {
        id: 3,
        title: "Kế hoạch tập tay",

        id_user: 1,
    },
    {
        id: 4,
        title: "Kế hoạch giảm mỡ trong 6 tháng",
        id_user: 1,
    },
    {
        id: 5,
        title: "Tăng cân tăng cơ thi đấu",
        id_user: 1,
    },
    // {
    //   id: 6,
    //   title: "Kế hoạch tăng body trong 6 tháng",
    //   id_user: 1,
    // },
];

export const dayDetails = [
    {
        id: 1,
        plan_id: 1,
        day_id: 1,
        noi_dung_tap_id: [1, 2, 5, 8],
        sport_id: 1,
    },
    {
        id: 2,

        plan_id: 1,
        day_id: 2,
        noi_dung_tap_id: [4, 5],
        sport_id: 1,
    },

    {
        id: 3,

        plan_id: 1,
        day_id: 3,
        noi_dung_tap_id: [1, 2],
        sport_id: 1,
    },

    {
        id: 4,

        plan_id: 1,
        day_id: 4,
        noi_dung_tap_id: [4, 5, 1],
        sport_id: 1,
    },

    {
        id: 5,

        plan_id: 1,
        day_id: 5,
        noi_dung_tap_id: [],
        sport_id: 1,
    },
    {
        id: 5,

        plan_id: 1,
        day_id: 6,
        noi_dung_tap_id: [2, 5],
        sport_id: 1,
    },
    {
        id: 5,

        plan_id: 1,
        day_id: 7,
        noi_dung_tap_id: [2, 5],
        sport_id: 1,
    },
    {
        id: 8,
        plan_id: 2,
        day_id: 1,
        noi_dung_tap_id: [1, 3, 5],
        sport_id: 2,
    },
    {
        id: 9,
        plan_id: 2,
        day_id: 2,
        noi_dung_tap_id: [2, 4],
        sport_id: 2,
    },
    {
        id: 10,
        plan_id: 2,
        day_id: 3,
        noi_dung_tap_id: [1, 2],
        sport_id: 2,
    },
    {
        id: 11,
        plan_id: 2,
        day_id: 4,
        noi_dung_tap_id: [4, 5],
        sport_id: 2,
    },
    {
        id: 12,
        plan_id: 2,
        day_id: 5,
        noi_dung_tap_id: [],
        sport_id: 2,
    },
    {
        id: 13,
        plan_id: 2,
        day_id: 6,
        noi_dung_tap_id: [2, 5],
        sport_id: 2,
    },
    {
        id: 14,
        plan_id: 2,
        day_id: 7,
        noi_dung_tap_id: [3, 4],
        sport_id: 2,
    },

    // Plan 3
    {
        id: 15,
        plan_id: 3,
        day_id: 1,
        noi_dung_tap_id: [1, 3, 2, 5],
        sport_id: 3,
    },
    {
        id: 16,
        plan_id: 3,
        day_id: 2,
        noi_dung_tap_id: [2, 4],
        sport_id: 3,
    },
    {
        id: 17,
        plan_id: 3,
        day_id: 3,
        noi_dung_tap_id: [1, 2],
        sport_id: 3,
    },
    {
        id: 18,
        plan_id: 3,
        day_id: 4,
        noi_dung_tap_id: [4, 2, 5],
        sport_id: 3,
    },
    {
        id: 19,
        plan_id: 3,
        day_id: 5,
        noi_dung_tap_id: [],
        sport_id: 3,
    },
    {
        id: 20,
        plan_id: 3,
        day_id: 6,
        noi_dung_tap_id: [1, 5],
        sport_id: 3,
    },
    {
        id: 21,
        plan_id: 3,
        day_id: 7,
        noi_dung_tap_id: [3, 4, 6],
        sport_id: 3,
    },

    {
        id: 22,
        plan_id: 4,
        day_id: 1,
        noi_dung_tap_id: [],
        sport_id: 4,
    },
    {
        id: 23,
        plan_id: 4,
        day_id: 2,
        noi_dung_tap_id: [],
        sport_id: 4,
    },
    {
        id: 24,
        plan_id: 4,
        day_id: 3,
        noi_dung_tap_id: [],
        sport_id: 4,
    },
    {
        id: 25,
        plan_id: 4,
        day_id: 4,
        noi_dung_tap_id: [2, 3],
        sport_id: 4,
    },
    {
        id: 26,
        plan_id: 4,
        day_id: 5,
        noi_dung_tap_id: [],
        sport_id: 4,
    },
    {
        id: 27,
        plan_id: 4,
        day_id: 6,
        noi_dung_tap_id: [],
        sport_id: 4,
    },
    {
        id: 28,
        plan_id: 4,
        day_id: 7,
        noi_dung_tap_id: [],
        sport_id: 4,
    },
    {
        id: 29,
        plan_id: 5,
        day_id: 1,
        noi_dung_tap_id: [],
        sport_id: 5,
    },
    {
        id: 30,
        plan_id: 5,
        day_id: 2,
        noi_dung_tap_id: [],
        sport_id: 5,
    },
    {
        id: 31,
        plan_id: 5,
        day_id: 3,
        noi_dung_tap_id: [],
        sport_id: 5,
    },
    {
        id: 32,
        plan_id: 5,
        day_id: 4,
        noi_dung_tap_id: [],
        sport_id: 5,
    },
    {
        id: 33,
        plan_id: 5,
        day_id: 5,
        noi_dung_tap_id: [],
        sport_id: 5,
    },
    {
        id: 34,
        plan_id: 5,
        day_id: 6,
        noi_dung_tap_id: [6, 8],
        sport_id: 5,
    },
    {
        id: 35,
        plan_id: 5,
        day_id: 7,
        noi_dung_tap_id: [],
        sport_id: 5,
    },
];

export const configPlace = {
    1: "Quận 1, TP.HCM",
    2: "Quận 2, TP.HCM",
    3: "Quận 3, TP.HCM",
    4: "Quận 4, TP.HCM",
    5: "Quận 5, TP.HCM",
    6: "Quận 6, TP.HCM",
    7: "Quận 7, TP.HCM",
    8: "Quận 8, TP.HCM",
    9: "Quận 9, TP.HCM",
    10: "Quận 10, TP.HCM",
    11: "Quận 11, TP.HCM",
    12: "Quận 12, TP.HCM",
    13: "Quận Thủ Đức, TP.HCM",
    14: "Quận Gò Vấp, TP.HCM",
    15: "Quận Bình Thạnh, TP.HCM",
    16: "Quận Tân Bình, TP.HCM",
    17: "Quận Tân Phú, TP.HCM",
    18: "Quận Phú Nhuận, TP.HCM",
    19: "Quận Bình Tân, TP.HCM",
    20: "Huyện Củ Chi, TP.HCM",
    21: "Huyện Hóc Môn, TP.HCM",
    22: "Huyện Bình Chánh, TP.HCM",
    23: "Huyện Nhà Bè, TP.HCM",
    24: "Huyện Cần Giờ, TP.HCM",
    25: "Huyện Củ Chi, TP.HCM",
};
export const city = {
    1: "TP.HCM",
    2: "Hà Nội",
};
export const listPlace = [
    {
        id: 1,
        name: "The New Gym",
        address: 1,
        countDevice: 13,
        image: gymTamBinh,
        city: 1,
        placeTypeId: 1,
    },
    {
        id: 2,
        name: "Công viên gia Định",
        address: 14,
        countDevice: 14,
        image: GiaDinhPark,
        city: 1,
        placeTypeId: 2,
    },
    {
        id: 3,
        name: "Phòng Gym",
        address: 15,
        countDevice: 15,
        image: GymRoom,
        city: 2,
        placeTypeId: 1,
    },
];
