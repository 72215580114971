import styled, { keyframes } from "styled-components";
import { useEffect, useState } from "react";
import { FooterWrapper, ButtonFooter } from "../../based/ButtonStyles";
import { days } from "../../based/ConfigDays";
import useStore from "../../based/store/useStore";
const Modalchange = ({ isOpen, onClose, onSave, listCurrentMuscle, listCurrentSport, selectedPlan, selectedDay }) => {
    const [selectedListMuscle, setSelectedListMuscle] = useState([]);
    const [selectedListSport, setSelectedListSport] = useState([]);
    const [state, dispatch] = useStore();
    const [selectedSport, setSelectedSport] = useState(0);

    useEffect(() => {
        if (listCurrentMuscle) {
            setSelectedListMuscle(listCurrentMuscle);
        }
        if (listCurrentSport) {
            setSelectedListSport(listCurrentSport);
        }
    }, [isOpen]);

    const handleSave = () => {
        onSave({
            selectedListMuscle,
            selectedListSport,
        });
        onClose();
    };

    return (
        <ModalChangeWrapper style={{ display: isOpen ? "block" : "none" }}>
            <ModalInner>
                <ModalChangeHeader>
                    <HeaderTitle>Điều chỉnh nhóm cơ Thứ 3</HeaderTitle>
                    <ModalChangeClose onClick={onClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M18 6L6 18" stroke="white" strokeWidth="2" strokeLinecap="round" />
                            <path d="M18 18L6 6" stroke="white" strokeWidth="2" strokeLinecap="round" />
                        </svg>
                    </ModalChangeClose>
                </ModalChangeHeader>
                <ModalChangeBody>
                    <BodyTop>
                        <TopTitle>
                            Nhóm cơ
                            <SubTitle>(Được chọn nhiều)</SubTitle>
                        </TopTitle>
                        <TopContent>
                            <ItemWrapper>
                                <>
                                    {state.muscleList.map((item, index) => (
                                        <>
                                            <ContentItem
                                                index={index}
                                                className={selectedListMuscle.length > 0 ? (selectedListMuscle.map((item2) => item2.muscleId).includes(item.id) ? "selected" : "") : ""}
                                                onClick={() => {
                                                    if (selectedListMuscle && selectedListMuscle.map((item2) => item2.muscleId).includes(item.id)) {
                                                        setSelectedListMuscle(selectedListMuscle.filter((item2) => item2.muscleId !== item.id));
                                                    } else {
                                                        setSelectedListMuscle([
                                                            ...selectedListMuscle,
                                                            {
                                                                planId: selectedPlan.id,
                                                                daysOfWeek: days[selectedDay],
                                                                muscleId: item.id,
                                                            },
                                                        ]);
                                                    }
                                                }}
                                            >
                                                <>
                                                    <ItemIcon>
                                                        <img src={item.thumbnail} alt={item.name} />
                                                    </ItemIcon>
                                                    <ItemTitle key={index}>{item.name}</ItemTitle>
                                                </>
                                            </ContentItem>
                                        </>
                                    ))}
                                </>
                            </ItemWrapper>
                        </TopContent>
                    </BodyTop>

                    <BodyBottom>
                        <TopTitle>
                            Môn thể thao
                            <SubTitle>(Được chọn 1)</SubTitle>
                        </TopTitle>
                        <TopContent>
                            <ItemWrapper>
                                {state.sportList.map((item, index) => (
                                    <>
                                        <ContentItem
                                            index={index}
                                            className={selectedListSport.length > 0 ? (selectedListSport.map((item2) => item2.sportId).includes(item.id) ? "selected" : "") : ""}
                                            onClick={() => {
                                                if (selectedListSport && selectedListSport.map((item2) => item2.sportId).includes(item.id)) {
                                                    setSelectedListSport(selectedListSport.filter((item2) => item2.sportId !== item.id));
                                                } else {
                                                    setSelectedListSport([
                                                        {
                                                            planId: selectedPlan.id,
                                                            daysOfWeek: days[selectedDay],
                                                            sportId: item.id,
                                                        },
                                                    ]);
                                                }
                                            }}
                                        >
                                            <>
                                                <ItemIcon>
                                                    <img src={item.thumbnail} alt={item.name} />
                                                </ItemIcon>
                                                <ItemTitle key={index}>{item.name}</ItemTitle>
                                            </>
                                        </ContentItem>
                                    </>
                                ))}
                            </ItemWrapper>
                        </TopContent>
                    </BodyBottom>
                </ModalChangeBody>

                <FooterWrapper>
                    <ButtonFooter onClick={handleSave}>Lưu lại </ButtonFooter>
                </FooterWrapper>
            </ModalInner>
        </ModalChangeWrapper>
    );
};
const slideInFromTop = keyframes`
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
`;
const ModalChangeWrapper = styled.div`
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    bottom: 0;
    z-index: 2;
`;
const ModalInner = styled.div`
    width: 100%;

    height: 100%;

    justify-content: center;

    flex-wrap: wrap;
    background: #272727;
    position: relative;
    animation: ${slideInFromTop} 0.2s ease;
`;
const ModalChangeHeader = styled.div`
    width: 100%;
    height: auto;
    flex-shrink: 0;
    display: flex;
    padding: 16px;

    background: rgba(39, 39, 39, 0.6);
    box-shadow: 0px 1px 0px 0px #3f3f3f;
    backdrop-filter: blur(4px);
`;
const HeaderTitle = styled.h3`
    width: 100%;

    height: auto;
    display: flex;
    justify-content: center;
    color: var(--color-text);
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;
const ModalChangeClose = styled.div``;

const ModalChangeBody = styled.div`
    padding: 16px 20px;
`;

const BodyShared = styled.div``;
const TitleShared = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;
const SubTitleShared = styled.span`
    color: #727272;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 8px;
`;
const ContentItemShared = styled.div`
    width: 22%;
    height: 88px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid #484848;
    background: #3a3a3a;
    padding: 8px;
    background: ${(props) => (props.isSelected ? "linear-gradient(268deg, #0352FE 0.45%, #0060FF 97.59%)" : "#3a3a3a")};
    &.selected {
        background: linear-gradient(268deg, #0352fe 0.45%, #0060ff 97.59%);
    }
    margin: ${(props) => (props.index !== 3 ? "0px 10px 10px 0px  " : "")};
`;

const BodyTop = styled.div``;
const TopTitle = styled(TitleShared)``;
const SubTitle = styled(SubTitleShared)``;
const TopContent = styled.div``;
const ContentItem = styled(ContentItemShared)``;
const ItemIcon = styled.div`
    text-align: center;
`;
const BodyBottom = styled.div``;
const ItemTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const ItemWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export default Modalchange;
