import BaseServices from "./BaseServices";

const ExerciseServices = {
    GetAllExerciseType: async () => {
        return await BaseServices.Get(`/api/Exercise/get-all-exercise-type`);
    },

    CreateUpdateExerciseType: async (model) => {
        return await BaseServices.Post(`/api/Exercise/create-update-exercise-type`, model);
    },

    RemoveExerciseTypeById: async (id) => {
        return await BaseServices.Post(`/api/Exercise/remove-exercise-type-by-id/${id}`);
    },
    GetAllExercise: async () => {
        return await BaseServices.Get(`/api/Exercise/get-all-exercise`);
    },
    CreteUpdateExercise: async (model) => {
        return await BaseServices.Post(`/api/Exercise/create-update-exercise`, model);
    },
    RemoveExerciseById: async (id) => {
        return await BaseServices.Post(`/api/Exercise/remove-exercise-by-id/${id}`);
    },
    CreateUpdateMuscleExerciseDetail: async (model) => {
        return await BaseServices.Post(`/api/Exercise/create-update-muscle-exercise-detail`, model);
    },
    GetExerciseByName: async (name) => {
        return await BaseServices.Get(`/api/Exercise/get-exercise-by-name?name=${name}`);
    },
    GetAllMuscleExercise: async () => {
        return await BaseServices.Get(`api/Exercise/get-all-muscle-exercise-detail`);
    },

    // chi lay duoc trong bang muscle exercise detail
    GetAllExerciseByMuscleId: async (id) => {
        return await BaseServices.Get(`api/Exercise/get-list-exercise-by-muscle-id/${id}`);
    },

    GetListExerciseByVector: async (data, id) => {
        return await BaseServices.Post(`api/Exercise/get-list-exercise-with-vector/${id}`, data);
    },
    // lay duoc cu the cac bai tap luon
    GetExerciseByPagingMuscle: async (model) => {
        return await BaseServices.Post(`api/Exercise/get-exercise-paging-by-muscle`, model);
    },

    //
    GetUnitForOneExercise: async (id) => {
        return await BaseServices.Post(`api/Exercise/get-unit-for-one-exercise/${id}`);
    },
    SwitchExerciseByOrderNumber: async (model) => {
        return await BaseServices.Post(`api/Exercise/switch-exercise-by-order-number`, model);
    },
    SearchExerciseSuggestions: async (model) => {
        return await BaseServices.Post(`api/Exercise/search-exercise-suggestion`, model);
    },
};

export default ExerciseServices;
