import React from "react";

const Terms = () => {
    return (
        <div>
            <iframe id="embed-iframe" width="100%" src="https://ranus.vn/OperationRegulation/OperationRegulation.html" style={{ border: "none", height: "16491px", overflow: "visible" }} />
        </div>
    );
};

export default Terms;
