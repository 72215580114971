import styled from "styled-components";

import { IconCommon, Tick } from "../based/IconShared";
import { useEffect, useState } from "react";
import { GymImageShared } from "../based/GymImageShared";
import { GymExerciseImage } from "../based/GymExerciseShared";
import { Arrow } from "../based/IconShared";
import ModalAddSet from "../mainpage/ModalPickSet/ModalAddSet";
import { useParams } from "react-router-dom";
import PlanServices from "../based/services/PLanServices";
import { CheckProcess } from "../based/ConfigIconSvg";
import { useNavigate, useLocation } from "react-router-dom";
import ModalConfirm from "../based/ModalConfirm";
const configTypeExercise = {
    1: "Isolation",
    2: "Compound",
    3: "Superset",
};

const PracticePage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { planId } = useParams();
    const location = useLocation();
    const state = location.state;
    const [selectedItems, setSelectedItems] = useState();
    const [isOpenModalAddSet, setIsOpenModalAddSet] = useState(false);
    const [isOpenModalDetailPractice, setIsOpenModalDetailPractice] = useState(false);
    const [listExercise, setListExercise] = useState([]);
    const [listPlanHistoryExercise, setListPlanHistoryExercise] = useState([]);
    const [countIsDone, setCountIsDone] = useState([]);
    const [propsDetail, setPropsDetail] = useState({
        planHistory: "",
        exercise: "",
    });
    const [content, setContent] = useState("");
    const [type, setType] = useState("");
    const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);
    useEffect(() => {
        GetListExerciseByPlanHistoryId(id);
    }, [id]);

    const GetListExerciseByPlanHistoryId = async (id) => {
        const [err, data] = await PlanServices.GetListExerciseByPlanHistoryId(id);
        if (!err) {
            setListPlanHistoryExercise(data.result);
            setListExercise(data.listExercise);
        } else {
            console.log(err);
        }
    };
    console.log(listPlanHistoryExercise, listExercise);

    const handleOpenModalDetailsPractical = (foundItem, itemExercise) => {
        setPropsDetail({
            planHistory: itemExercise,
            exercise: foundItem,
        });
        setIsOpenModalDetailPractice(true);
    };

    const handleCloseModalDetailsPractical = () => {
        setIsOpenModalDetailPractice(false);
    };

    const handleSaveDataDetailsPractical = (data) => {
        setIsOpenModalDetailPractice(false);
    };

    const handleOpenModalAddSet = () => {
        setIsOpenModalAddSet(true);
    };

    const handleCloseModalAddSet = () => {
        setIsOpenModalAddSet(false);
    };

    const handleSaveDataAddSet = async (data) => {
        setIsOpenModalAddSet(false);

        const newData = [...listExercise, ...data];
        const newPlanHistoryData = data.map((item) => ({
            exerciseId: item.id,
            planHistoryId: id,
            isDone: false,
        }));
        const updatedPlanHistoryExercise = [...listPlanHistoryExercise, ...newPlanHistoryData];
        setListPlanHistoryExercise(updatedPlanHistoryExercise);
        setListExercise(newData);
        const newExercise = data.filter((item) => !listExercise.find((item2) => item2.id === item.id)).map((item) => item.id);
        console.log(newExercise);
        let formAddPlanHistoryExercise = {
            planHistoryId: id,
            listExerciseId: newExercise,
        };
        const [err2, data2] = await PlanServices.CreateUpdatePlanHistoryExerciseWithListExercise(formAddPlanHistoryExercise);
        if (!err2) {
            console.log("success");
        } else {
            console.log(err2);
        }
        window.location.reload();
    };

    //  const listExerciseId = listExercisePicker.map((item) => item.id);
    //         const listMuscle = props.muscleForDay.map((item) => item.id);

    //         const [err, data] = await PlanServices.CreateUpdatePlanHistory(formAddPlanHistory);

    //         if (!err) {
    //             let formAddPlanHistoryExercise = {
    //                 planHistoryId: data,
    //                 listExerciseId: listExerciseId,
    //             };
    //             let model = {
    //                 planHistoryId: data,
    //                 muscleId: listMuscle,
    //             };
    //             const [err2, data2] = await PlanServices.CreateUpdatePlanHistoryExerciseWithListExercise(formAddPlanHistoryExercise);
    //             const [err3, data3] = await PlanHistoryExtendServices.CreateUpdatePlanHistoryExtendListMuscle(model);
    //             if (!err2 && !err3) {
    //                 navigate(`/practice/${planId}/${data}`);
    //             } else {
    //                 console.log(err2);
    //             }
    //         } else {
    //             console.log(err);
    //         }

    const handleOpenModalConfirm = () => {
        setIsOpenModalConfirm(true);
    };

    const handleCloseModalConfirm = () => {
        setIsOpenModalConfirm(false);
    };

    const handleSaveDataConfirm = (data) => {
        setIsOpenModalConfirm(false);
        navigate("/home/" + planId);
    };
    const handleOut = () => {
        setContent("Bạn có chắc chắn muốn thoát buổi tập?");
        handleOpenModalConfirm();
    };
    const handleSave = () => {
        setContent("Bạn có chắc chắn muốn kết thúc buổi tập?");
        handleOpenModalConfirm();
    };

    return (
        <>
            <PracticeWrapper>
                <ModalConfirm isOpen={isOpenModalConfirm} onClose={handleCloseModalConfirm} onSave={handleSaveDataConfirm} content={content}></ModalConfirm>
                <ModalAddSet isOpen={isOpenModalAddSet} onClose={handleCloseModalAddSet} onSave={handleSaveDataAddSet} listExerciseCurrent={listExercise}></ModalAddSet>
                <PracticeInner>
                    <PracticeHeader>
                        <PracticeHeaderTop>
                            <IconBack onClick={() => handleOut()} src={Arrow.chevronleft}></IconBack>
                            <PracticeHeaderTitle>Tạo buổi tập mới</PracticeHeaderTitle>
                            <PracticeHeaderRightWrapper onClick={() => handleSave()}>
                                <TitleClose>Kết thúc</TitleClose>
                            </PracticeHeaderRightWrapper>
                        </PracticeHeaderTop>
                    </PracticeHeader>
                    <PracticeBody>
                        <BodyTitleHeaderWrapper>
                            <IconDone src={Tick.tickdone_green}></IconDone>
                            <BodyTitleHeader>
                                Đã hoàn thành {listPlanHistoryExercise.filter((item) => item.isDone === true).length}/{listExercise.length} bài tập
                            </BodyTitleHeader>
                        </BodyTitleHeaderWrapper>
                        <ScrollBarWrapper>
                            {listPlanHistoryExercise &&
                                listPlanHistoryExercise.map((itemExercise, indexExercise) => {
                                    const foundItem = listExercise.find((item) => item.id === itemExercise.exerciseId);
                                    if (foundItem) {
                                        return (
                                            <BodyScrollBarItem
                                                onClick={() => navigate(`/practice-detail/${id}/${itemExercise.id}`)}
                                                key={indexExercise}
                                                bg={
                                                    itemExercise.isDone === true ? "linear-gradient(90deg, rgba(0, 248, 158, 0.20) 0%, rgba(0, 248, 158, 0.00) 100%);" : "linear-gradient(90deg, rgba(255, 92, 0, 0.20) 0%, rgba(255, 92, 0, 0.00) 100%);"
                                                }
                                            >
                                                <SBItemLeft>
                                                    <SBItemLeftWrapper src={foundItem.thumbnail}>
                                                        <LeftTitleWrapper
                                                            bg={
                                                                foundItem.exerciseTypeId === 1
                                                                    ? "linear-gradient(0deg, #FF5C00 0%, rgba(255, 92, 0, 0.00) 100%)"
                                                                    : foundItem.exerciseTypeId === 2
                                                                    ? "linear-gradient(0deg, #0352FE 0%, rgba(3, 82, 254, 0.00) 100%)"
                                                                    : "linear-gradient(0deg, #F5002C 0%, rgba(245, 0, 44, 0.00) 100%)"
                                                            }
                                                        >
                                                            <LeftTitle>{configTypeExercise[foundItem.exerciseTypeId]}</LeftTitle>
                                                        </LeftTitleWrapper>
                                                    </SBItemLeftWrapper>
                                                    <SBItemCenter>
                                                        <SBItemCenterTitle>
                                                            {foundItem.name}{" "}
                                                            <span>
                                                                <CheckProcess active={itemExercise.isDone} />
                                                            </span>{" "}
                                                        </SBItemCenterTitle>
                                                        <SBItemCenterSubTitle>{foundItem.nameEN}</SBItemCenterSubTitle>
                                                        <SBItemCenterSubTitle>Thời gian tập: {foundItem.timeSpan}s/6 reps</SBItemCenterSubTitle>
                                                        <SBItemCenterSubTitle>Calo tiêu thụ: {foundItem.calories} calories</SBItemCenterSubTitle>
                                                    </SBItemCenter>
                                                </SBItemLeft>
                                                <SBItemRight>
                                                    <SBIcon src={Arrow.chevronright}></SBIcon>
                                                </SBItemRight>
                                            </BodyScrollBarItem>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                        </ScrollBarWrapper>
                    </PracticeBody>
                    <CreateButtonWrapper>
                        <CreateButton onClick={handleOpenModalAddSet}>
                            <ButtonIcon src={IconCommon.add}></ButtonIcon>
                            <ButtonTitle>Thêm bài tập</ButtonTitle>
                        </CreateButton>
                    </CreateButtonWrapper>
                </PracticeInner>
            </PracticeWrapper>
        </>
    );
};

export default PracticePage;

const PracticeWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    position: fixed;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
    background: #272727;
    padding-bottom: 38%;
`;

const PracticeInner = styled.div`
    width: 100vh;
    height: 80;
    background-color: #3a3a3a;
    position: relative;
    background: #272727;
`;

const PracticeHeader = styled.div`
    width: 100%;
    height: 7%;
    background: rgba(39, 39, 39, 0.6);
    box-shadow: 0px 1px 0px 0px #3f3f3f;
    backdrop-filter: blur(4px);
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
`;

const PracticeHeaderTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

const PracticeHeaderTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    width: 100%;

    flex-shrink: 0;
`;
const IconBack = styled.img``;

const PracticeBody = styled.div`
    width: 100%;
    height: 100%;
`;

const CreateButton = styled.button`
    display: flex;
    height: 42px;
    padding: 14px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 30px;
    border: 1px solid #484848;

    background: #3a3a3a;
`;

const ButtonIcon = styled.img``;
const ButtonTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const PracticeHeaderRightWrapper = styled.div``;

const IconClose = styled.img``;

const TitleClose = styled.h3`
    color: #ff5c00;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

const BodyTitleHeader = styled.h3`
    color: #00f89e;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

const BodyTitleHeaderWrapper = styled.div`
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    padding-left: 12px;
    margin-top: 19px;
`;

const IconDone = styled.img`
    margin-right: 10px;
`;

const BodyScrollBarItemWrapper = styled.div`
    width: 100%;

    height: 80%;
    overflow-y: scroll;
`;

const BodyScrollBarItem = styled.div`
    display: flex;
    z-index: 1;
    width: 100%;
    position: relative;
    justify-content: space-between;
    border-bottom: 1px solid #3f3f3f;
    border-top: 1px solid #3f3f3f;
    padding: 12px 0px;
    position: relative;
    margin-top: ${(props) => (props.mt ? props.mt : "")};
    background: ${(props) => (props.bg ? props.bg : "")};
`;

const WrapperBodyScrollBarItem = styled.div`
    width: 100%;
    height: 100%;
    border: 1px solid #3f3f3f;
    opacity: 0.5;
    position: absolute;
    background: ${(props) => (props.bg ? props.bg : "")};
    top: 0;
`;

const SBItemLeft = styled.div`
    width: 80%;
    height: 84px;
    padding-left: 5%;
    display: flex;
    flex-shrink: 0;
`;

const SBIconLeftWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
`;
const SBIconLeft = styled.img`
    width: 18px;
    height: 18px;

    margin-right: 5px;
`;

const SBItemLeftWrapper = styled.div`
    width: 85px;

    position: relative;
    height: 84px;

    flex-shrink: 0;
    background-image: ${(props) => `url(${props.src})`};
    background-repeat: no-repeat;
`;

const LeftTitleWrapper = styled.div`
    width: 100%;
    height: 45px;
    position: absolute;
    bottom: 0;
    flex-shrink: 0;
    border-radius: 12px;
    background: ${(props) => (props.bg ? props.bg : "")};
    display: flex;
    align-items: flex-end;
    justify-content: center;
`;

const LeftTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    margin-bottom: 4px;
    font-weight: 500;
    line-height: normal;
`;

const SBItemCenterTitle = styled.h2`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    span {
        margin-left: 7px;
    }
`;

const SubTitleConfig = styled.h3`
    color: #939393;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const SBItemRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-right: 10px;
`;
const SBIcon = styled.img``;

const SBItemCenter = styled.div`
    margin-left: 10px;
`;

const SBItemCenterSubTitle = styled(SubTitleConfig)`
    // Add your styles here
`;

const CreateButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    position: fixed;
    justify-content: center;
    align-items: center;
    bottom: 20px;
`;
const ScrollBarWrapper = styled.div`
    width: 100%;
    height: 85%;
    position: relative;
    overflow-y: scroll;
`;
