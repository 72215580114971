import Context from "./Context";
import { useEffect, useReducer, useState } from "react";
import reducer from "./reducer";
import { initState } from "./reducer";
import MuscleService from "../services/MuscleService";
import SportService from "../services/SportServices";
import { jwtDecode } from "jwt-decode";
import Common from "../Common";
import AccountServices from "../services/AccountServices";
import cookies from "react-cookies";
function Provider({ children }) {
    const [redirect, setRedirect] = useState(false);
    const [stateChange, setStateChange] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            await HandleGetListMuscle();
            await HandleGetListSport();
        };
        fetchData();
    }, []);

    const HandleGetListMuscle = async () => {
        const [err, data] = await MuscleService.GetAllMuscle();
        if (!err && data) {
            initState.muscleList = data;
        } else {
            console.log(err);
        }
    };

    const HandleGetListSport = async () => {
        const [err, data] = await SportService.GetAllSport();
        if (!err && data) {
            initState.sportList = data;
        } else {
            console.log(err);
        }
    };

    const [state, dispatch] = useReducer(reducer, initState);

    return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
}

export default Provider;
