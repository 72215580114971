import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { FooterWrapper, ButtonFooter } from "../../based/ButtonStyles";
import { BodyContent, BodyContentWrapper, ContentLeft, IconLeft, TitleLeftMonday, ContentRight, MatchedIcon } from "../Planpage";
import { days, daysName } from "../../based/ConfigDays";
import Modalchange from "../modalchange/Modalchange";
import Common from "../../based/Common";
import PlanServices from "../../based/services/PLanServices";
import cookies from "react-cookies";
const ModalChangePlan = ({ isOpen, onClose, onSave, selectedPlan, listSport, listMuscle, listMuscleDetails, listSportDetails, planWithIndex }) => {
    const [newData, setNewData] = useState("");
    const [isModalOpenChange, setIsModalOpenChange] = useState(false);
    const [selectedDay, setSelectedDay] = useState(0);
    const [currentListMuscle, setCurrentListMuscle] = useState([]);
    const [currentListSport, setCurrentListSport] = useState([]);
    const [listMuscleDetailsJson, setListMuscleDetailsJson] = useState([]);
    const [listSportDetailsJson, setListSportDetailsJson] = useState([]);

    useEffect(() => {
        if (planWithIndex) setNewData(planWithIndex.name);
    }, []);

    useEffect(() => {
        setListMuscleDetailsJson(JSON.parse(JSON.stringify(listMuscleDetails)));
        setListSportDetailsJson(JSON.parse(JSON.stringify(listSportDetails)));
        if (selectedPlan) {
            setNewData(selectedPlan.name);
        }
    }, [isOpen]);
    const handleSave = async () => {
        const formCreateUpdate = {
            id: planWithIndex.id,
            name: newData,
            forTarget: 0,
        };

        const [err, data] = await PlanServices.CreateUpdatePlan(formCreateUpdate);

        if (!err && data) {
            const [err3, data3] = await PlanServices.CreateUpdateListPlanExerciseDetail(listMuscleDetailsJson);
            if (!err3 && data3) {
                console.log(data3);
            } else {
                console.log(err3);
            }
            for (const item of listSportDetailsJson) {
                const [err4, data4] = await PlanServices.CreateUpdatePlanSportDetail({
                    id: item.id,
                    planId: planWithIndex.id,
                    sportId: item.sportId,
                    daysOfWeek: item.daysOfWeek,
                });
            }
        }

        onSave(data);
    };
    console.log(listMuscleDetailsJson);

    const handleOpenModalChange = (item2) => {
        setSelectedDay(item2);

        const result = listMuscleDetailsJson.filter((item) => item.daysOfWeek === parseInt(days[item2]) && item.planId === parseInt(selectedPlan.id));
        const resultSport = listSportDetailsJson.filter((item) => item.daysOfWeek === parseInt(days[item2]) && item.planId === parseInt(selectedPlan.id));

        setCurrentListMuscle(result);
        setCurrentListSport(resultSport);

        setIsModalOpenChange(true);
    };
    const handleCloseModalChange = () => {
        setIsModalOpenChange(false);
    };

    const handleSaveDataChange = (data) => {
        const updatedListMuscle = [...listMuscleDetailsJson];

        const filteredList = updatedListMuscle.filter((item) => item.daysOfWeek !== days[selectedDay]);

        data.selectedListMuscle.forEach((item) => {
            filteredList.push(item);
        });

        setListMuscleDetailsJson(filteredList);

        const updatedListSport = [...listSportDetailsJson];

        const filteredListSport = updatedListSport.filter((item) => item.daysOfWeek !== days[selectedDay]);

        data.selectedListSport.forEach((item) => {
            filteredListSport.push(item);
        });

        setListSportDetailsJson(filteredListSport);
    };

    return (
        <>
            <ModalWrapper style={{ display: isOpen ? "block" : "none" }}>
                <ModalInner>
                    <ModalHeader>
                        <HeaderTitle>Chỉnh sửa bài tập</HeaderTitle>
                        <ModalClose onClick={onClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M18 6L6 18" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                <path d="M18 18L6 6" stroke="white" strokeWidth="2" strokeLinecap="round" />
                            </svg>
                        </ModalClose>
                    </ModalHeader>
                    <Title>
                        Đặt tên kế hoạch <span>*</span>
                    </Title>
                    <InputWrapper>
                        {" "}
                        <Input type="text" value={newData} onChange={(e) => setNewData(e.target.value)} placeholder="Nhập tên"></Input>
                    </InputWrapper>

                    <BodyContent>
                        {Object.keys(days).map((item2, index) => (
                            <>
                                <BodyContentWrapper>
                                    <ContentLeft index={index} onClick={() => handleOpenModalChange(item2)}>
                                        <IconLeft>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
                                                <path d="M1 1H13" stroke="#484848" strokeWidth="2" strokeLinecap="round" />
                                                <path d="M1 6.82353H13" stroke="#484848" strokeWidth="2" strokeLinecap="round" />
                                                <path d="M1 12H13" stroke="#484848" strokeWidth="2" strokeLinecap="round" />
                                            </svg>
                                        </IconLeft>

                                        <TitleLeftMonday key={index}>{daysName[item2]}</TitleLeftMonday>
                                    </ContentLeft>
                                    <ContentRight index={index}>
                                        <ContentWrapper>
                                            {listMuscleDetailsJson &&
                                                listMuscleDetailsJson.map((item, index) => (
                                                    <>
                                                        {item.daysOfWeek === parseInt(days[item2]) ? (
                                                            <>
                                                                {listMuscle
                                                                    .filter((x) => x.id === item.muscleId)
                                                                    .map((muscleItem) => (
                                                                        <>
                                                                            <MatchedIcon src={muscleItem.thumbnail}></MatchedIcon>
                                                                        </>
                                                                    ))}
                                                            </>
                                                        ) : (
                                                            <> </>
                                                        )}
                                                    </>
                                                ))}

                                            {listSportDetailsJson &&
                                                listSportDetailsJson.map((item, index) => (
                                                    <>
                                                        {item.daysOfWeek === parseInt(days[item2]) ? (
                                                            <>
                                                                {listSport
                                                                    .filter((x) => x.id === item.sportId)
                                                                    .map((sportItem) => (
                                                                        <>
                                                                            <MatchedIcon src={sportItem.thumbnail}></MatchedIcon>
                                                                        </>
                                                                    ))}
                                                            </>
                                                        ) : (
                                                            <> </>
                                                        )}
                                                    </>
                                                ))}
                                        </ContentWrapper>
                                    </ContentRight>
                                </BodyContentWrapper>
                            </>
                        ))}
                        <SubDescription>Ấn vào một ngày cụ thể để chỉnh sửa *</SubDescription>
                    </BodyContent>
                    <FooterWrapper>
                        <ButtonFooter onClick={handleSave}>Lưu lại </ButtonFooter>
                    </FooterWrapper>
                </ModalInner>
            </ModalWrapper>
            <Modalchange isOpen={isModalOpenChange} onClose={handleCloseModalChange} onSave={handleSaveDataChange} listCurrentMuscle={currentListMuscle} listCurrentSport={currentListSport} selectedPlan={selectedPlan} selectedDay={selectedDay} />
        </>
    );
};

export default ModalChangePlan;
const slideInFromTop = keyframes`
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
`;
const ModalWrapper = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    bottom: 0;
    z-index: 2;
`;

const ModalInner = styled.div`
    width: 100%;
    padding: 10px;
    height: 100%;
    justify-content: center;

    flex-wrap: wrap;
    border-radius: 16px;
    background: #272727;
    position: fixed;
    animation: ${slideInFromTop} 0.2s ease;

    .cancel {
    }
`;

const Title = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 16px 0px 12px 6px;
    span {
        color: #f5002c;
        font-family: "Be Vietnam Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;

const InputWrapper = styled.div`
    width: 100%;
    margin: 0 auto;
`;

const Input = styled.input`
    width: 100%;
    height: 42px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #2f2f2f;
    border: none;
    color: #fff;
    padding: 12px 16px;
    margin-bottom: 26px;
`;

const ChooseDayWrapper = styled.div``;
const ChooseDayTitle = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 12px;
`;
const Days = styled.div``;

const DaysButton = styled.button`
    display: inline-flex;
    width: 11%;
    height: 38px;
    padding: 14px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    margin-right: 11px;
    border-radius: 10px;
    border: 1px solid #484848;
    background: #3a3a3a;
    color: #fff;
    &.selected {
        background-color: #0352fe;
    }
    cursor: pointer;
`;
const ModalHeader = styled.div`
    width: 100%;
    height: auto;
    flex-shrink: 0;
    display: flex;
    padding: 16px;

    background: rgba(39, 39, 39, 0.6);
    box-shadow: 0px 1px 0px 0px #3f3f3f;
    backdrop-filter: blur(4px);
`;

const HeaderTitle = styled.div`
    width: 100%;
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    height: auto;
    display: flex;
    justify-content: center;
`;

const ModalClose = styled.div``;

const ContentWrapper = styled.div`
    width: 100%;
`;

const ContentHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 26px;
    margin-bottom: 13px;
`;
const ContentTitle = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const ContentAction = styled.button`
    color: #0352fe;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: #272727;
    border: none;
`;

const ContentBody = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
const ContentBodyWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
    width: 21%;
    height: 88px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid #484848;
    background: ${(props) => (props.isSelected ? "blue" : "#3A3A3A")};
    margin: ${(props) => {
        if (props.index !== 3) return "0px 16px 12px 0px";
    }};
    &.selected {
        background-color: blue;
    }
`;
const ContentBodyIcon = styled.div`
    margin-top: 4px;
`;
const ContentBodyTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const SubDescription = styled.div`
    color: #727272;
    margin-top: 12px;
    margin-left: 8px;

    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
