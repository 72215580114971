import styled, { keyframes } from "styled-components";
import { muscleIcons, IconCommon, Arrow } from "../../../based/IconShared";
import { useState, useEffect } from "react";
import { Folder } from "../../../based/ConfigIconSvg";
import ModalCreateFolder from "./ModalCreateFolder";
import cookies from "react-cookies";
import FolderPlanExerciseServices from "../../../based/services/FolderPlanExerciseServices";

const ModalSelectFolder = ({ isOpen, onClose, onSave }) => {
    const [selectedFolder, setSelectedFolder] = useState();
    const [isOpenModalCreateFolder, setIsOpenModalCreateFolder] = useState(false);
    const [listFolder, setListFolder] = useState([]);

    useEffect(() => {
        GetListFolderByUserName();
    }, [isOpen]);

    const GetListFolderByUserName = async () => {
        const [err, data] = await FolderPlanExerciseServices.GetListFolderByUserName();
        if (!err) {
            setListFolder(data);
        } else {
        }
    };

    const handleOpenModalCreateFolder = () => {
        setIsOpenModalCreateFolder(true);
    };

    const handleCloseModalCreateFolder = () => {
        setIsOpenModalCreateFolder(false);
    };

    const handleSaveModalCreateFolder = (data) => {
        // setListFolder([...listFolder, { id: listFolder.length + 1, name: data }]);
        setIsOpenModalCreateFolder(false);
        GetListFolderByUserName();
    };

    return (
        <>
            <ModalChangeWrapper style={{ display: isOpen ? "flex" : "none" }}>
                <ModalCreateFolder isOpen={isOpenModalCreateFolder} onClose={handleCloseModalCreateFolder} onSave={handleSaveModalCreateFolder}></ModalCreateFolder>
                <ModalWrapperInner>
                    <ModalHeader>
                        <HeaderTitle>Chọn Folder</HeaderTitle>
                        <HeaderAction src={IconCommon.deleteicon} onClick={onClose}></HeaderAction>
                    </ModalHeader>
                    <ModalBody>
                        {listFolder.length > 0 ? (
                            <>
                                {listFolder.map((item, index) => (
                                    <ItemWrapper
                                        onClick={() => {
                                            onSave({ id: item.id, name: item.name });
                                            setSelectedFolder(item.id);
                                        }}
                                        bg={selectedFolder === item.id ? "rgba(3, 82, 254, .1)" : ""}
                                    >
                                        <Folder active={selectedFolder === item.id ? true : false} />
                                        <ItemTitle>{item.name}</ItemTitle>
                                    </ItemWrapper>
                                ))}
                            </>
                        ) : (
                            <>
                                <Notification>Bạn chưa có folder nào</Notification>
                            </>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <FooterActionWrapper>
                            <ItemWrapper onClick={handleOpenModalCreateFolder}>
                                <IconAdd src={IconCommon.add} />
                                <ItemTitle>Thêm folder</ItemTitle>
                            </ItemWrapper>
                        </FooterActionWrapper>
                    </ModalFooter>
                </ModalWrapperInner>
            </ModalChangeWrapper>
        </>
    );
};

export default ModalSelectFolder;

const slideInFromTop = keyframes`
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateY(0);
    }
`;
const ModalChangeWrapper = styled.div`
    width: 100%;
    height: 100vh;
    top: 0;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    z-index: 2;
    background: rgba(0, 0, 0, 0.8);
`;

const ModalWrapperInner = styled.div`
    width: 90%;
    margin: 0 auto;
    height: 33%;
    padding: 10px 16px;

    background: #272727;
    position: relative;
    animation: ${slideInFromTop} 0.3s ease forwards;
    /* border-radius: 16px 16px 0px 0px; */

    border-radius: 16px;
`;

const ModalHeader = styled.div`
    background: rgba(39, 39, 39, 0.6);

    height: 13%;
    backdrop-filter: blur(4px);
    display: flex;
    position: relative;
    align-items: center;
`;

const HeaderTitle = styled.h3`
    color: #fff;

    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

const ModalBody = styled.div`
    height: 65%;
    width: 100%;
    overflow-y: scroll;
    margin-top: 3%;
`;

const BodyHeader = styled.div`
    /* Add your styles here */
`;

const BodyItem = styled.div`
    display: flex;

    flex-wrap: wrap;
`;

const ItemWrapper = styled.div`
    display: flex;
    width: 303px;
    height: 42px;
    flex-shrink: 0;
    background: ${(props) => props.bg};

    align-items: center;
`;

const ItemIcon = styled.div`
    width: 54px;
    height: 54px;
    flex-shrink: 0;
`;

const ItemTitle = styled.div`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 12px;
`;

const HeaderAction = styled.img`
    position: absolute;
    right: 10px;
    margin-bottom: 0.5rem;
    z-index: 2;
`;

const SubTitle = styled.span`
    margin-left: 10px;
    color: #727272;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const ModalFooter = styled.div`
    width: 100%;
    height: 15%;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const Button = styled.button`
    width: 40%;
    height: 42px;
    flex-shrink: 0;
    border-radius: 50px;
    border: 1px solid #484848;
    background: #3a3a3a;
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;
const FooterAction = styled(Button)`
    /* width: 40%;
  height: 42px;
  flex-shrink: 0;
  border-radius: 50px;
  border: 1px solid #484848;
  background: #3a3a3a; */
`;

const FooterActionWrapper = styled.div`
    align-items: center;
    height: 100%;
    display: flex;
`;

const FooterActionConfirm = styled(Button)`
    border-radius: 50px;
    border: 1px solid rgba(184, 229, 255, 0.2);
    background: linear-gradient(268deg, #0352fe 0.45%, #0060ff 97.59%);
    box-shadow: 0px 0px 15px 0px rgba(3, 82, 254, 0.4);
`;
const NoteInput = styled.input`
    width: 90%;
    color: white;
    height: auto;
    margin-top: 7px;
    margin-left: 7px;

    border-radius: 12px;
    background: #2f2f2f;
    border: none;

    overflow-wrap: break-word;
    outline: none;
`;

const NoteWrapper = styled.div`
    width: 100%;
    height: 214px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #2f2f2f;
    border: none;
`;

const BodyItemTop = styled.div`
    margin-bottom: 3%;
`;

const Title = styled.h3`
    color: #878787;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
`;
const Input = styled.input`
    width: 100%;
    height: 42px;
    border-radius: 12px;
    padding-left: 10px;
    color: #fff;
    background: #2f2f2f;
    flex-shrink: 0;
    border: none;
`;

const BodyItemBot = styled.div``;

const ButtonSelect = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
    border-radius: 12px;
    background: #2f2f2f;
    height: 42px;
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const Icon = styled.img`
    position: absolute;
    right: 10px;
    top: 6px;
`;

const ButtonSelectWrapper = styled.div`
    position: relative;
`;

const ItemFolder = styled.div``;

const IconAdd = styled.img``;

const Notification = styled.h3`
    color: #939393;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
