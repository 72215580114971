import barbell from '../assets/ImageExercices/Barbell.png' 
import pushups from '../assets/ImageExercices/pushups.png'
import dumbbell from '../assets/ImageExercices/dumbbell.png'

// rectangle
import barbell_rectangle from '../assets/ImageExercices/barbell_rectangle.png'
import barbell_rectangleHowToDo from '../assets/ImageRectangleHowToDo/barbell.png'
import nguc_tay_sau from '../assets/ForMuscle/nguc_taysau.png'
import plank from '../assets/ImageRectangleHowToDo/plank.png'

// nhung hinh anh gym size vuong
export const GymExerciseImage = {
    barbell: barbell,
    pushups: pushups,
    dumbbell: dumbbell,
    barbell_rectangle: barbell_rectangle,
}

// nhung hinh anh  gym size hinh chu nhat cua page Practicle how to do 
export const GymExerciseImageRectangleHowToDo = {
    barbell: barbell_rectangleHowToDo,
    plank: plank
}

// nhung hinh anh tong quan cua cac nhom co

export const ImageForMuscle = {
    nguc_tay_sau : nguc_tay_sau,

}