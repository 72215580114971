import BaseServices from "./BaseServices";

const TargetServices = {
    GetListTargetByGender: async (id) => {
        return await BaseServices.Get(`/api/Target/get-list-target-by-gender?gender=${id}`);
    },
    CreateUpdateUserTarget: async (model) => {
        return await BaseServices.Post(`api/Target/create-update-user-target`, model);
    },
    GetUserTargetByUserId: async () => {
        return await BaseServices.Get(`api/Target/get-user-target-by-user-id`);
    },
    RemoveUserTargetByUserId: async () => {
        return await BaseServices.Get(`api/Target/remove-user-target-by-user-id`);
    },
    GetTargetByTargetId: async (id) => {
        return await BaseServices.Get(`api/Target/get-target-by-target-id/${id}`);
    },
};

export default TargetServices;
