import BaseServices from "./BaseServices";

const PlanHistoryExtendServices = {
    CreateUpdatePlanHistoryExtend: async (model) => {
        return await BaseServices.Post(`/api/PlanHistoryExtend/create-update-plan-history-extend`, model);
    },
    CreateUpdatePlanHistoryExtendListMuscle: async (model) => {
        return await BaseServices.Post(`/api/PlanHistoryExtend/create-update-plan-history-extend-with-list-muscle`, model);
    },
};

export default PlanHistoryExtendServices;
