import React from "react";

const Policy = () => {
    return (
        <div>
            <iframe id="embed-iframe" width="100%" src="https://ranus.vn/OperationRegulation/CustomerProtection.html" style={{ border: "none", height: "2063px", overflow: "visible" }} />
        </div>
    );
};

export default Policy;
