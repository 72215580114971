import { useState, useEffect } from "react";
import { FooterWrapper, ButtonFooter } from "../based/ButtonStyles";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import ModalNewPlan from "./modalplan/Modalplan";
import Modalchange from "./modalchange/Modalchange";
import ModalPlanOfMe from "./modalplanofme/Modalplanofme";
import ModalCreatePlan from "./ModalcreatePlan/Modalcreateplan";
import ModalChangePlan from "./modalchangeplan/ModalChangePlan";
import PLanService from "../based/services/PLanServices";
import MuscleService from "../based/services/MuscleService";
import SportServices from "../based/services/SportServices";
import { Arrow, muscleIcons, IconCommon, sportIcon } from "../based/IconShared";
import { days, daysName } from "../based/ConfigDays";
import { plan, dayDetails } from "../based/FakeApi";
import UserServices from "../based/services/UserServices";
import MessageValidation from "../based/MessageValidation";
const Plan = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenChange, setIsModalOpenChange] = useState(false);
    const [isModalOpenPlanOfMe, setIsModalOpenPlanOfMe] = useState(false);
    const [isOpenModalCreatePlan, setIsOpenModalCreatePlan] = useState(false);
    const [isOpenModalChangePlan, setIsOpenModalChangePlan] = useState(false);
    const [userNameAdmin, setUserNameAdmin] = useState("admin@ranus.vn");
    const [listPlanOfAdmin, setListPlanOfAdmin] = useState([]);
    const [listMuscle, setListMuscle] = useState([]);
    const [listMuscleDetails, setListMuscleDetails] = useState([]);
    const [listSportDetails, setListSportDetails] = useState([]);
    const [listSport, setListSport] = useState([]);
    const [listPlanOfMe, setListPlanOfMe] = useState([]);
    const [selectedPlanWithIndex, setSelectedPlanWithIndex] = useState({
        name: "",
    });
    const [mss, setMss] = useState("");
    const [isShowMessage, setIsShowMessage] = useState(false);

    const [selectedPlan, setSelectedPlan] = useState(() => {
        const currentPlan = localStorage.getItem("currentPlan");
        return currentPlan ? JSON.parse(currentPlan) : { name: "" };
    });
    const [dataSelectedPlanOfMe, setDataSelectedPlanOfMe] = useState(() => {
        const currentPlan = localStorage.getItem("currentPlan");
        return currentPlan ? JSON.parse(currentPlan) : { name: "" };
    });

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSaveData = (data) => {
        window.location.reload();
        setIsModalOpen(false);
    };

    const handleOpenModalChange = () => {
        setIsModalOpenChange(true);
    };
    const handleCloseModalChange = () => {
        setIsModalOpenChange(false);
    };

    const handleSaveDataChange = (data) => {
        setIsModalOpenChange(false);
    };

    //modal plan of me

    const handleOpenModalPlanOfMe = () => {
        setIsModalOpenPlanOfMe(true);
    };

    const handleCloseModalPlanOfMe = () => {
        setIsModalOpenPlanOfMe(false);
    };

    const handleSaveDataPlanOfMe = (data) => {
        setDataSelectedPlanOfMe(data);
        setIsModalOpenPlanOfMe(false);
        HandleGetMuscleDetailsByPlanId(data.id);
        HandleGetSportDetailsByPlanId(data.id);
        setSelectedPlanWithIndex(data);
        setSelectedPlan(data);
        localStorage.setItem("currentPlan", JSON.stringify(data));
        plan[3].title = data.name;
    };

    const handleOpenModalCreatePlan = () => {
        setIsOpenModalCreatePlan(true);
    };

    const handleCloseModalCreatePlan = () => {
        setIsOpenModalCreatePlan(false);
    };

    const handleSaveDataCreatePlan = (data) => {
        setIsOpenModalCreatePlan(false);
        window.location.reload();
    };

    const handleOpenModalChangePlan = () => {
        setIsOpenModalChangePlan(true);
    };

    const handleCloseModalChangePlan = () => {
        setIsOpenModalChangePlan(false);
    };

    const handleSaveDataChangePlan = (data) => {
        setIsOpenModalChangePlan(false);
        window.location.reload();
    };

    const HandleGetPlanOfAdmin = async () => {
        const [err, data] = await PLanService.GetListPlanAdmin();
        if (!err && data.length > 0) {
            console.log(data[0]);
            if (selectedPlan.name === "") {
                setSelectedPlanWithIndex(data[0]);
                setSelectedPlan(data[0]);
                HandleGetMuscleDetailsByPlanId(data[0].id);
                HandleGetSportDetailsByPlanId(data[0].id);
            }
            setListPlanOfAdmin(data);
        } else {
        }
    };

    const HandleGetMuscleDetailsByPlanId = async (id) => {
        const [err, data] = await PLanService.GetListMuscleByPlanId(id);
        if (!err && data) {
            setListMuscleDetails(data);
        } else {
            console.log(err);
        }
    };

    const HandleGetListMuscle = async () => {
        const [err, data] = await MuscleService.GetAllMuscle();
        if (!err && data) {
            setListMuscle(data);
        } else {
            console.log(err);
        }
    };
    const HandleGetListSport = async () => {
        const [err, data] = await SportServices.GetAllSport();
        if (!err && data) {
            setListSport(data);
        } else {
            console.log(err);
        }
    };

    const HandleGetSportDetailsByPlanId = async (id) => {
        const [err, data] = await PLanService.GetListSportByPlanId(id);
        if (!err && data) {
            setListSportDetails(data);
        } else {
            console.log(err);
        }
    };

    const HandleGetListPlanOfMe = async () => {
        const [err, data] = await PLanService.GetPlanByUserName();
        if (!err && data) {
            setListPlanOfMe(data);
        } else {
            console.log(err);
        }
    };

    const handleOpenModalPlanOfMeWithItem = () => {
        handleOpenModalPlanOfMe();
    };

    useEffect(() => {
        const checkCurrentPlan = async () => {
            const [err, data] = await UserServices.GetUserDetailByUserId();
            if (!err && data.currentPlan !== 0) {
                navigate(`/home/${data.currentPlan}`);
            }
        };
        checkCurrentPlan();
    }, []);

    useEffect(() => {
        HandleGetPlanOfAdmin();
        HandleGetListMuscle();
        HandleGetListSport();
        HandleGetListPlanOfMe();
        HandleGetMuscleDetailsByPlanId(selectedPlan.id);
        HandleGetSportDetailsByPlanId(selectedPlan.id);
        setIsShowMessage(false);
    }, [selectedPlan]);
    const handleClickStart = async () => {
        if (selectedPlan.id === undefined) {
            setMss("Bạn hãy chọn 1 kế hoạch luyện tập để bắt đầu !");
            setIsShowMessage(true);
            return;
        } else if (selectedPlan.createdBy === userNameAdmin) {
            const [err, data] = await PLanService.CreatePlanForUserByPlanAdmin(selectedPlan);
            if (!err) {
                console.log(data);
                const [err1, data1] = await UserServices.CreateUpdateCurrentPlanUser(data.id);
                if (!err1) {
                    console.log(data1);
                } else {
                    console.log(err1);
                }

                navigate(`/home/${data}`);
            }
        } else {
            const [err1, data1] = await UserServices.CreateUpdateCurrentPlanUser(selectedPlan.id);
            if (!err1) {
                console.log(data1);
            } else {
                console.log(err1);
            }
            navigate(`/home/${selectedPlan.id}`);
        }
    };

    return (
        <>
            <PlanWrapper>
                <PlanInner>
                    <PlanHeader>
                        <HeaderTitle>Kế hoạch tập các nhóm cơ trong tuần</HeaderTitle>
                    </PlanHeader>

                    <PlanBodyWrapper>
                        <BodyHeader>
                            {listPlanOfAdmin &&
                                listPlanOfAdmin.slice(0, 4).map((item, index) => (
                                    <>
                                        {index + 1 != 4 ? (
                                            <>
                                                <ButtonPlan
                                                    isSelected={item.name === selectedPlan.name && item.createdBy === selectedPlan.createdBy ? "True" : "False"}
                                                    onClick={() => {
                                                        HandleGetMuscleDetailsByPlanId(item.id);
                                                        HandleGetSportDetailsByPlanId(item.id);
                                                        setSelectedPlanWithIndex(item);
                                                        setSelectedPlan(item);
                                                        localStorage.setItem("currentPlan", JSON.stringify(item));
                                                    }}
                                                >
                                                    {index + 1}
                                                </ButtonPlan>{" "}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                ))}

                            <ButtonPlanMe
                                isSelected={selectedPlan && selectedPlan.name !== "" && selectedPlan.createdBy !== userNameAdmin ? "True" : "False"}
                                onClick={() => {
                                    handleOpenModalPlanOfMeWithItem();
                                }}
                            >
                                <TitlePlanOfMe>{dataSelectedPlanOfMe.name !== "" ? dataSelectedPlanOfMe.name : "Của tôi"}</TitlePlanOfMe>
                                <span>
                                    <img src={Arrow.chevronright}></img>
                                </span>
                            </ButtonPlanMe>
                        </BodyHeader>
                        <TitleOfPlan>{selectedPlan && selectedPlan.name}</TitleOfPlan>
                        <BodyContent>
                            {Object.keys(days).map((item2, index) => (
                                <>
                                    <BodyContentWrapper>
                                        <ContentLeft index={index}>
                                            <TitleLeftMonday key={index}>{daysName[item2]}</TitleLeftMonday>
                                        </ContentLeft>
                                        <ContentRight index={index}>
                                            <ContentWrapper>
                                                {listMuscleDetails.map((item, index) => (
                                                    <>
                                                        {item.daysOfWeek === parseInt(days[item2]) ? (
                                                            <>
                                                                {listMuscle
                                                                    .filter((x) => x.id === item.muscleId)
                                                                    .map((muscleItem) => (
                                                                        <>
                                                                            <MatchedIcon src={muscleItem.thumbnail}></MatchedIcon>
                                                                        </>
                                                                    ))}
                                                            </>
                                                        ) : (
                                                            <> </>
                                                        )}
                                                    </>
                                                ))}

                                                {listSportDetails.map((item, index) => (
                                                    <>
                                                        {item.daysOfWeek === parseInt(days[item2]) ? (
                                                            <>
                                                                {listSport
                                                                    .filter((x) => x.id === item.sportId)
                                                                    .map((sportItem) => (
                                                                        <>
                                                                            <MatchedIcon2 src={sportItem.thumbnail}></MatchedIcon2>
                                                                        </>
                                                                    ))}
                                                            </>
                                                        ) : (
                                                            <> </>
                                                        )}
                                                    </>
                                                ))}
                                            </ContentWrapper>
                                        </ContentRight>
                                    </BodyContentWrapper>
                                </>
                            ))}
                            <SubDescription>Chọn công cụ để thao tác hoặc chỉnh sửa *</SubDescription>
                            <MessageValidation isShow={isShowMessage} message={mss} />
                            <Description>
                                <LeftDes>
                                    <ActionWrapper onClick={handleOpenModal}>
                                        <IconDes src={IconCommon.copy}></IconDes>
                                        <DescriptionTitle>Copy & Chỉnh sửa</DescriptionTitle>
                                    </ActionWrapper>
                                    <ActionWrapper onClick={handleOpenModalCreatePlan}>
                                        <IconDes src={IconCommon.create}></IconDes>
                                        <DescriptionTitle>Tạo mới</DescriptionTitle>
                                    </ActionWrapper>
                                </LeftDes>
                                <RightDes>
                                    {selectedPlanWithIndex && selectedPlanWithIndex.createdBy !== userNameAdmin && (
                                        <ActionWrapper>
                                            <IconDes src={IconCommon.edit}></IconDes>
                                            <DescriptionTitle onClick={handleOpenModalChangePlan}>Chỉnh sửa</DescriptionTitle>
                                        </ActionWrapper>
                                    )}
                                </RightDes>
                            </Description>
                        </BodyContent>
                    </PlanBodyWrapper>

                    <FooterWrapper>
                        <ButtonFooter onClick={() => handleClickStart()}>Bắt đầu </ButtonFooter>
                    </FooterWrapper>
                </PlanInner>
                <Modalchange isOpen={isModalOpenChange} onClose={handleCloseModalChange} onSave={handleSaveDataChange} />
                <ModalPlanOfMe isOpen={isModalOpenPlanOfMe} onClose={handleCloseModalPlanOfMe} onSave={handleSaveDataPlanOfMe}>
                    {" "}
                </ModalPlanOfMe>
                <ModalCreatePlan isOpen={isOpenModalCreatePlan} onClose={handleCloseModalCreatePlan} onSave={handleSaveDataCreatePlan} listSport={listSport} listMuscle={listMuscle} />
                <ModalChangePlan
                    isOpen={isOpenModalChangePlan}
                    onClose={handleCloseModalChangePlan}
                    onSave={handleSaveDataChangePlan}
                    selectedPlan={selectedPlan}
                    listSport={listSport}
                    listMuscle={listMuscle}
                    listMuscleDetails={listMuscleDetails}
                    listSportDetails={listSportDetails}
                    planWithIndex={selectedPlanWithIndex}
                ></ModalChangePlan>

                <ModalNewPlan
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    onSave={handleSaveData}
                    selectedPlan={selectedPlan}
                    listSport={listSport}
                    listMuscle={listMuscle}
                    listMuscleDetails={listMuscleDetails}
                    listSportDetails={listSportDetails}
                    selectedPlanWithIndex={selectedPlanWithIndex}
                />
            </PlanWrapper>
        </>
    );
};

export default Plan;

const PlanWrapper = styled.div`
    height: 100vh;
    background-color: var(--bg-color);
    position: fixed;
    width: 100vw;
`;

const PlanInner = styled.div`
    height: 100vh;
    background-color: var(--bg-color);
    position: fixed;
    padding: 10px;
    width: 100vw;
`;

const PlanHeader = styled.div`
    margin-bottom: 30px;
`;
const HeaderTitle = styled.h3`
    color: #fff;
    text-align: center;
    padding-top: 12px;
    font-family: "Be Vietnam Pro";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;
const PlanBodyWrapper = styled.div``;
const ButtonPlan = styled.button`
    display: inline-flex;

    height: 38px;
    padding: 14px 22px;
    margin-right: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #484848;
    background: ${(props) => (props.isSelected === "True" ? "#0352FE" : "#3A3A3A")};
    color: #fff;
`;
const ButtonPlanMe = styled(ButtonPlan)`
    flex: 1;
    display: flex;
    background: ${(props) => (props.isSelected === "True" ? "#0352FE" : "#3A3A3A")};
    font-size: 14px;
`;

export const BodyContent = styled.div`
    display: flex;
    flex-direction: column;
`;
const BodyHeader = styled.div`
    margin-bottom: 16px;
    display: flex;
`;

export const ContentLeft = styled.div`
    width: 25%;
    height: 48px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    border: 1px solid #484848;
    background: #3a3a3a;
    border-radius: ${(props) => {
        if (props.index === 0) return "16px 0px 0px 0px";

        if (props.index === 6) return "0px 0px 0px 16px";
        return "";
    }};
`;
export const ContentRight = styled.div`
    width: 75%;
    height: 48px;
    flex-shrink: 0;

    border: 1px solid #484848;
    background: #3a3a3a;
    border-radius: ${(props) => {
        if (props.index === 0) return "0px 16px 0px 0px";

        if (props.index === 6) return "0px 0px 16px 0px";
        return "";
    }};
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
`;

export const IconLeft = styled.div`
    width: 12px;
    height: 11px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin: 18px 12px;
`;
const TitleLeft = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin: 15px 1px;
    line-height: normal;
`;
export const ContentWrapper = styled.ul`
    padding: 0px;
    display: flex;
    position: relative;
    /* justify-content: space-between; */
    align-items: center;
    width: 100%;
    margin-top: 10px;
`;
const ContentDetails = styled.li`
    list-style-type: none;
    margin: 12px 11px;
`;
export const BodyContentWrapper = styled.div`
    display: flex;
`;
export const TitleLeftMonday = styled(TitleLeft)``;

const ContentRightMonday = styled(ContentRight)`
    border-radius: 0px 16px 0px 0px;
`;
const ContentRightSunDay = styled(ContentRight)``;

const Description = styled.div`
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    margin-left: 5px;
`;
const SubDescription = styled.h3`
    color: #727272;
    margin-top: 12px;
    margin-left: 8px;

    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const TitlePlanOfMe = styled.h3`
    display: -webkit-box;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex-shrink: 0;
    overflow: hidden;
    color: #fff;
    text-overflow: ellipsis;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const MatchedIcon = styled.img`
    width: 12%;
    height: 24px;
    flex-shrink: 0;
`;

const LeftDes = styled.div``;
const IconDes = styled.img``;
const RightDes = styled.div``;
const ActionWrapper = styled.div`
    display: flex;
    margin-bottom: 12px;
`;
const DescriptionTitle = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 8px;
    padding-top: 5px;
`;

const TitleOfPlan = styled.p`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

const MatchedIcon2 = styled.img`
    width: 12%;
    height: 24px;
    position: absolute;
    right: 10px;
    top: 0px;
`;
