import BaseServices from "./BaseServices";

const ProcessPlanHistoryExerciseServices = {
    CreateUpdateProcessPlanHistoryExercise: async function (model) {
        return await BaseServices.Post(`/api/ProcessPlanHistoryExercise/create-update-process-plan-history-exercise`, model);
    },
    GetProcessPlanHistoryExerciseByProcessPlanHistoryId: async function (id) {
        return await BaseServices.Get(`/api/ProcessPlanHistoryExercise/get-process-plan-history-exercise-by-plan-history-exercise-id/${id}`);
    },
    UpdateIsDoneProcessPlanHistoryExercise: async function (id, isDone) {
        return await BaseServices.Post(`/api/ProcessPlanHistoryExercise/udpate-is-done-process/${id}?isDone=${isDone}`);
    },
    RemoveProcessPlanHistoryExercise: async function (id) {
        return await BaseServices.Post(`api/ProcessPlanHistoryExercise/remove-process-plan-history-exercise-by-id/${id}`);
    },
};

export default ProcessPlanHistoryExerciseServices;
