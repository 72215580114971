import styled from "styled-components";
import { Arrow } from "../based/IconShared";
import { useEffect, useState, useMemo } from "react";
import ModalResultTrain from "./ModalResultTrain";
import { format, startOfWeek, endOfMonth, addDays, startOfMonth, addMonths, isSameMonth, endOfWeek, isSameDay, set } from "date-fns";
import { da, vi } from "date-fns/locale";
import PlanServices from "../based/services/PLanServices";
import Common from "../based/Common";
import { BackArrow, NextBackArrow, CalendarParameter, Calendar } from "../based/ConfigIconSvg";
import { IconCommon } from "../based/IconShared";
import { muscleIcons } from "../based/IconShared";
import CalendarSymbol from "../../src/assets/ImagePlace/CalendarSymBol.png";

function MonthCalendar() {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [isOpenModalResultTrain, setIsOpenModalResultTrain] = useState(false);
    const startDay = startOfWeek(startOfMonth(currentDate), { weekStartsOn: 1 });
    const endDay = endOfWeek(endOfMonth(currentDate), { weekStartsOn: 1 });
    const [listPlanHistory, setListPlanHistory] = useState([]);
    const [itemSelect, setItemSelect] = useState(null);
    const [dayPicker, setDayPicker] = useState(null);

    useEffect(() => {
        GetPlanHistoryByMonth();
    }, [currentDate]);

    const [currentTime, setCurrentTime] = useState(() => {
        const nextMonth = new Date();
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        return nextMonth;
    });

    let days = [];
    let day = startDay;

    while (day <= endDay) {
        days.push(day);
        day = addDays(day, 1);
    }

    while (days.length % 7 !== 0) {
        days.push(null);
    }

    const weeks = [];
    for (let i = 0; i < days.length; i += 7) {
        weeks.push(days.slice(i, i + 7));
    }

    const prevMonth = () => {
        setCurrentDate(addMonths(currentDate, -1));
    };

    const nextMonth = () => {
        setCurrentDate(addMonths(currentDate, 1));
    };

    const onDayClick = (day) => {
        if (day) {
            console.log(format(day, "yyyy-MM-dd"));
        }
    };

    const GetPlanHistoryByMonth = async () => {
        const DateTimeJson = Common.formatDate(currentDate, "jsondate");
        const [err, data] = await PlanServices.GetListPlanHistoryByMonth(DateTimeJson);
        if (!err) {
            const dataHandle = data.map((item) => {
                return {
                    ...item,
                    workingDate: format(new Date(item.workingDate), "yyyy-MM-dd"),
                };
            });
            setListPlanHistory(dataHandle);
        } else {
            console.log(data);
        }
    };

    const handleOpenModalResultTrain = (data) => {
        console.log(data);
        setIsOpenModalResultTrain(true);
    };
    const handleCloseModalResultTrain = () => {
        setIsOpenModalResultTrain(false);
    };
    const handleSaveDataResultTrain = (data) => {
        setIsOpenModalResultTrain(false);
    };

    const _checkSameDay = (day) => {
        return listPlanHistory.some((item) => isSameDay(new Date(item.workingDate), day));
    };

    const _checkIsDone = (day) => {
        return listPlanHistory.some((item) => isSameDay(new Date(item.workingDate), day) && item.isDone);
    };

    return (
        <CalendarContainer>
            <Header>
                <MonthNavigationButton onClick={prevMonth}>
                    <NextBackArrow active={true} />
                </MonthNavigationButton>
                <CalendarBodyTitle>{format(currentDate, "MMMM yyyy", { locale: vi })}</CalendarBodyTitle>
                <MonthNavigationButton onClick={nextMonth}>
                    <NextBackArrow active={false} />
                </MonthNavigationButton>
            </Header>
            <WeekWrapper>
                {weeks.map((week, weekIdx) => (
                    <WeekRow key={weekIdx}>
                        {week.map((day, dayIdx) => (
                            <ItemWrapper
                                bg={
                                    _checkSameDay(day) && _checkIsDone(day)
                                        ? "linear-gradient(180deg, rgba(0, 248, 158, 0.5) 0%, rgba(0, 248, 158, 0.00) 100%)"
                                        : _checkSameDay(day) && isSameDay(day, new Date())
                                        ? "linear-gradient(180deg, rgba(255, 92, 0, 0.5) 0%, rgba(255, 92, 0, 0.00) 100%)"
                                        : ""
                                }
                                onClick={() => {
                                    let data = listPlanHistory.find((item) => isSameDay(new Date(item.workingDate), day));
                                    setItemSelect(data);
                                    setDayPicker(day);
                                    handleOpenModalResultTrain(data);
                                }}
                            >
                                <ImageGym bgImg={(_checkSameDay(day) && _checkIsDone(day)) || isSameDay(day, new Date()) ? CalendarSymbol : ""}></ImageGym>
                                <ItemTitle color={isSameDay(day, new Date()) ? "FF5C00" : ""} key={dayIdx} isCurrentMonth={day ? isSameMonth(day, currentDate) : false} isDay={!!day}>
                                    {day ? format(day, "d", { locale: vi }) : ""}
                                </ItemTitle>

                                <ItemDescription>
                                    {_checkSameDay(day) && _checkIsDone(day) ? (
                                        <>
                                            <RiceWrapper>
                                                <CalendarParameter type="start" />
                                                <StarContent>3.5/4</StarContent>
                                            </RiceWrapper>
                                            <RiceWrapper>
                                                <CalendarParameter type="rice" />
                                                <RiceContent>1000</RiceContent>
                                            </RiceWrapper>
                                            <FireWrapper>
                                                <CalendarParameter type="fire" />
                                                <FireContent>1000</FireContent>
                                            </FireWrapper>
                                            <UserWrapper>
                                                <CalendarParameter type="person" />
                                                <UserContent>1000</UserContent>
                                            </UserWrapper>
                                        </>
                                    ) : _checkSameDay(day) ? (
                                        listPlanHistory
                                            .filter((item) => isSameDay(new Date(item.workingDate), day))
                                            .map((item, index) => (
                                                <MuscleWrapper key={index}>
                                                    {item.listMuscle.slice(0, 4).map((muscle, muscleIndex) => (
                                                        <MuscleItem key={muscleIndex} src={muscle.thumbnail} />
                                                    ))}
                                                    {item.listMuscle.length > 3 && <span>+ {item.listMuscle.length - 4}</span>}
                                                </MuscleWrapper>
                                            ))
                                    ) : (
                                        // listPlanHistory
                                        //     .filter((item) => isSameDay(new Date(item.workingDate), day))
                                        //     .map((item, index) => (
                                        //         <MuscleWrapper key={index}>
                                        //             {item.listMuscle.map((muscle, muscleIndex) => (
                                        //                 <MuscleItem key={muscleIndex} src={muscle.thumbnail} />
                                        //             ))}
                                        //         </MuscleWrapper>
                                        //     ))

                                        //    {listMuscle
                                        //             .filter((item1) => item.noi_dung_tap_id.includes(item1.id))
                                        //             .slice(0, 4)
                                        //             .map((item2, index) => (
                                        //                 <ItemIcon key={index} opacity={0.5} src={item2.thumbnail}></ItemIcon>
                                        //             ))}
                                        //         {listMuscle.filter((item1) => item.noi_dung_tap_id.includes(item1.id)).length > 4 && <span>+ {listMuscle.length - 4}
                                        ""
                                    )}
                                </ItemDescription>
                            </ItemWrapper>
                        ))}
                    </WeekRow>
                ))}
            </WeekWrapper>
            <ModalResultTrain isOpen={isOpenModalResultTrain} onClose={handleCloseModalResultTrain} onSave={handleSaveDataResultTrain} itemSelect={itemSelect} daysPicker={dayPicker}></ModalResultTrain>
        </CalendarContainer>
    );
}

const MuscleItem = styled.img`
    width: 40%;
    padding: 2px;
    opacity: 0.6;
`;

const ModalCalendar = ({ isOpen, onClose, onSave }) => {
    return (
        <>
            <CalendarWrapper style={{ display: isOpen ? "flex" : "none" }}>
                <CalendarInner>
                    <CalendarHeader>
                        <CalendarHeaderTop>
                            <IconBack src={Arrow.chevronleft} onClick={onClose}></IconBack>
                            <CalendarHeaderTitle>Năm 2024</CalendarHeaderTitle>
                            <CalendarHeaderRight>Hôm nay</CalendarHeaderRight>
                        </CalendarHeaderTop>
                        <CalendarHeaderBottomWrapper>
                            {Array.from(Array(7).keys()).map((item, index) => {
                                return (
                                    <>
                                        <DayTitle>{index === 0 ? "T2" : index === 6 ? "CN" : <>T{index + 2}</>}</DayTitle>
                                    </>
                                );
                            })}
                        </CalendarHeaderBottomWrapper>
                    </CalendarHeader>
                    {/* <CalendarBody>
                        <CalendarBodyWrapper>
                            <CalendarBodyTitle>Tháng {currentTime.getMonth() + 1}</CalendarBodyTitle>
                            <CalendarItemWrapper>
                                {Array.from(Array(40).keys()).map((item, index) => (
                                    <ItemWrapper onClick={handleOpenModalResultTrain}>
                                        <ItemTitle
                                            bg={
                                                index + 1 === currentTime.getDate()
                                                    ? "linear-gradient(0deg, rgba(253, 0, 0, 0) 0%, rgba(253, 91, 0, 0.6) 100%);"
                                                    : index === 1
                                                    ? "linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 248, 158, 0.3) 100%)"
                                                    : ""
                                            }
                                        >
                                            {index !== currentTime.getDate()
                                                ? index + 1 > currentTime.getDate()
                                                    ? currentTime.getDate() + (index + 1 - currentTime.getDate())
                                                    : currentTime.getDate() - (currentTime.getDate() - (index + 1))
                                                : currentTime.getDate()}

                                            <ItemDescription>
                                                <RiceWrapper>
                                                    <StarIcon src={IconCommon.start}></StarIcon>
                                                    <StarContent>3.5/4</StarContent>
                                                </RiceWrapper>
                                                <RiceWrapper>
                                                    <RiceIcon src={IconCommon.rice}></RiceIcon>
                                                    <RiceContent>1000</RiceContent>
                                                </RiceWrapper>
                                                <FireWrapper>
                                                    <FireIcon src={IconCommon.fire_red}></FireIcon>
                                                    <FireContent>1000</FireContent>
                                                </FireWrapper>
                                                <UserWrapper>
                                                    <UserIcon src={IconCommon.user_2}></UserIcon>
                                                    <UserContent>1000</UserContent>
                                                </UserWrapper>
                                            </ItemDescription>
                                        </ItemTitle>
                                    </ItemWrapper>
                                ))}
                            </CalendarItemWrapper>
                        </CalendarBodyWrapper>
                    </CalendarBody> */}
                    <MonthCalendar />
                </CalendarInner>
            </CalendarWrapper>
        </>
    );
};

export default ModalCalendar;

const CalendarWrapper = styled.div`
    width: 100%;
    height: 100vh;
    top: 0;
    display: flex;
    position: fixed;
    flex-direction: column-reverse;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
`;

const CalendarInner = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #3a3a3a;
    position: fixed;
    border-radius: 16px 16px 0px 0px;
    background: #272727;
`;

const CalendarHeader = styled.div`
    width: 100%;
    height: 10%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

const CalendarHeaderTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;
const CalendarHeaderBottom = styled.div``;
const CalendarHeaderTop = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
    width: 100%;

    flex-shrink: 0;
`;
const IconBack = styled.img`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
`;

const CalendarHeaderRight = styled.h3`
    color: #ff5c00;
    text-align: right;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const DayTitle = styled.h3`
    color: var(--Dark-Primary, #fff);
    text-align: center;
    width: calc(100% / 7);
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background-color: ${(props) => props.bg};
`;
const CalendarHeaderBottomWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
`;

const CalendarBody = styled.div`
    width: 100%;
    height: 70vh;
`;

const CalendarBodyWrapper = styled.div`
    width: 100%;
    height: 70vh;
`;

const CalendarBodyTitle = styled.h3`
    color: #fff;

    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px 0;
`;

const CalendarItemWrapper = styled.div`
    width: 100%;
    height: 80vh;
    display: flex;
    flex-wrap: wrap;
`;

const ItemWrapper = styled.div`
    width: calc(100% / 7);
    border: 1px solid #3f3f3f;

    background: ${(props) => props.bg};
    position: relative;
    z-index: 2;
`;

const ItemTitle = styled.h3`
    color: #939393;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: ${(props) => props.bg};

    color: ${(props) => (props.color ? `#${props.color}` : "#939393")};
    margin-bottom: 10px;
`;
const ItemDescription = styled.div`
    width: 100%;
`;

const ItemDescriptionWrapperStyleConfig = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 5%;
    height: auto;
    margin-bottom: 5px;

    align-items: center;
`;

const StarWrapper = styled(ItemDescriptionWrapperStyleConfig)``;
const RiceWrapper = styled(ItemDescriptionWrapperStyleConfig)``;

const FireWrapper = styled(ItemDescriptionWrapperStyleConfig)``;
const UserWrapper = styled(ItemDescriptionWrapperStyleConfig)``;

const IconStyleConfig = styled.div`
    width: 10px;
    height: 9.51px;
    margin-right: 3px;
    flex-shrink: 0;
`;
const StarIcon = styled(IconStyleConfig)``;
const RiceIcon = styled(IconStyleConfig)``;
const FireIcon = styled(IconStyleConfig)``;
const UserIcon = styled(IconStyleConfig)``;

const StarContent = styled.div`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const RiceContent = styled.h3`
    color: #03a4fe;
    font-family: "Be Vietnam Pro";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const FireContent = styled.h3`
    color: #f5002c;
    font-family: "Be Vietnam Pro";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const UserContent = styled.h3`
    color: #03a4fe;
    font-family: "Be Vietnam Pro";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const CalendarContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Header = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
`;

const MonthNavigationButton = styled.div`
    color: #fff;
    display: flex;
    align-items: center;
`;

const MonthLabel = styled.h2`
    margin: 0 20px;
`;

const WeekRow = styled.div`
    display: flex;
    justify-content: space-around;
    height: calc(100% / 6);
`;

const DayBox = styled.div`
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin: 0 2px;
    background-color: ${({ isCurrentMonth }) => (isCurrentMonth ? "lightblue" : "lightgray")};
    color: ${({ isDay }) => (isDay ? "black" : "none")};
    cursor: ${({ isDay }) => (isDay ? "pointer" : "default")};
`;
const WeekWrapper = styled.div`
    width: 100vw;
    height: 80vh;
`;
const MuscleWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    span {
        color: #939393;
        text-align: center;
        font-family: "Be Vietnam Pro";
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
`;
const ImageGym = styled.img`
    background-image: url(${(props) => props.bgImg});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
    width: 100%;
    opacity: 0.2;
    position: absolute;
    top: 0;
    height: 100%;
`;
