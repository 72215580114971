import styled from "styled-components";
import { IconCommon, muscleIcons } from "../based/IconShared";
import NavigationBase from "../based/NavigationBase";
import { useEffect, useState } from "react";
import ModalChangeMuscle from "./ModalChangeMuscle";
import ModalNote from "./ModalNote";
import ModalCalendar from "./ModalCalendar";
import ModalPickSet from "./ModalPickSet/ModalPickSet";
import ModalPlace from "./modalPlace/Modalplace";
import { useParams, useNavigate } from "react-router-dom";
import PLanService from "../based/services/PLanServices";
import useStore from "../based/store/useStore";
import TargetServices from "../based/services/TargetServices";
import { History, FileText, Reload } from "../based/ConfigIconSvg";
import { format, getDay } from "date-fns";
import { NextBackArrow } from "../based/ConfigIconSvg";
import Select from "react-select";
import ModalCommon from "../based/ModalCommon";
import UserServices from "../based/services/UserServices";

const configTimeWork = {
    1: "30'",
    2: "60'",
    3: "90'",
    4: "120'",
};

const configPlace = {
    1: "Phòng Gym",
    2: "Nhà",
    3: "Công viên",
};

const configTarget = {
    "Tăng thể lực": 1,
    "Giữ cân, giữ cơ": 2,
    "Giảm cân, giữ cơ": 3,
    "Tăng cân, tăng cơ": 4,
};

const MainPage = () => {
    const { planId } = useParams();
    const [state, dispatch] = useStore();
    const navigate = useNavigate();
    const [currentTime, setCurrentTime] = useState(new Date());
    const [timeWork, setTimeWork] = useState(1);
    const [placeWork, setPlaceWork] = useState(1);
    const [selectedItem, setSelectedItem] = useState({});
    const [planTemp, setPlanTemp] = useState([]);
    const [listMuscleDetails, setListMuscleDetails] = useState([]);
    const [listSportDetails, setListSportDetails] = useState([]);
    const listMuscle = state.muscleList;
    const [listSport, setListSport] = useState([]);
    const [isOpenModalCommon, setIsOpenModalCommon] = useState(false);
    const [isModalOpenChange, setIsModalOpenChange] = useState(false);
    const [isModalOpenNote, setIsModalOpenNote] = useState(false);
    const [isModalOpenCalendar, setIsModalOpenCalendar] = useState(false);
    const [isOpenModalHistory, setIsOpenModalHistory] = useState(false);
    const [isModalOpenPickSet, setIsModalOpenPickSet] = useState(false);
    const [isModalOpenPlace, setIsModalOpenPlace] = useState(false);
    const [muscleForDay, setMuscleForDay] = useState([]);
    const [daysPicker, setDaysPicker] = useState(currentTime.getDay());
    const [userDetail, setUserDetail] = useState({
        target: {
            name: "",
            thumbnail: "",
        },
        timeEnd: "",
    });
    const [practiceId, setPracticeId] = useState(0);
    const [dateWorking, setDateWorking] = useState(new Date());
    const [err, showErr] = useState(false);

    const handleSelectedItem = (item) => {
        setSelectedItem(item);
        const musclePicker = listMuscleDetails.filter((item1) => item1.daysOfWeek === item.day_id).map((item2) => item2.muscleId);
        const muscleDetails = listMuscle.filter((item) => musclePicker.includes(item.id));
        setMuscleForDay(muscleDetails);

        setDaysPicker(item.day_id);
    };

    const handleOpenModalChange = () => {
        setIsModalOpenChange(true);
    };
    useEffect(() => {
        setSelectedItem({
            id: planId,
            day_id: currentTime.getDay() === 0 ? 7 : currentTime.getDay(),
        });
        async function fetchData() {
            const [err, data] = await TargetServices.GetUserTargetByUserId();
            if (!err && data) {
                let targetId = data.targetId;
                const [err2, data2] = await TargetServices.GetTargetByTargetId(targetId);
                if (!err2 && data2) {
                    setUserDetail((prevUserDetail) => ({
                        ...prevUserDetail,
                        target: data2,
                        timeEnd: data.timeEnd,
                    }));
                }
            } else {
                console.log(err);
            }
        }
        fetchData();
    }, []);

    const handleCloseModalChange = () => {
        setIsModalOpenChange(false);
    };

    const handleSaveDataChange = (data) => {
        setIsModalOpenChange(false);
        fetchData();
        setMuscleForDay(data);
    };

    const handleOpenModalNote = () => {
        setIsModalOpenNote(true);
    };

    const handleCloseModalNote = () => {
        setIsModalOpenNote(false);
    };

    const handleSaveDataNote = (data) => {
        setIsModalOpenNote(false);
    };

    const handleOpenModalCalendar = () => {
        setIsModalOpenCalendar(true);
    };

    const handleCloseModalCalendar = () => {
        setIsModalOpenCalendar(false);
    };

    const handleSaveDataCalendar = (data) => {
        setIsModalOpenCalendar(false);
    };

    const handleOpenModalPickSet = () => {
        setIsModalOpenPickSet(true);
    };

    const handleCloseModalPickSet = () => {
        setIsModalOpenPickSet(false);
    };

    const handleSaveDataPickSet = (data) => {
        setIsModalOpenPickSet(false);
    };

    const handleOpenModalPlace = () => {
        setIsModalOpenPlace(true);
    };

    const handleCloseModalPlace = () => {
        setIsModalOpenPlace(false);
    };

    const handleSaveDataPlace = (data) => {
        console.log(data);
        setPlaceWork(data.placeTypeId);

        setIsModalOpenPlace(false);
    };

    const handleOpenModalCommon = async () => {
        if (muscleForDay.length === 0) {
            showErr(true);
        } else {
            const [err, data] = await PLanService.GetPlanHistoryCurrentDay();
            if (!err) {
                if (data != null) {
                    setPracticeId(data.id);
                    setIsOpenModalCommon(true);
                } else {
                    handleOpenModalPickSet();
                    setIsOpenModalCommon(false);
                }
            }
        }
    };

    const handleCloseModalCommon = () => {
        setIsOpenModalCommon(false);
    };

    const handleSaveModalCommon = (data) => {
        if (data == "success") {
            navigate(`/practice/${planId}/${practiceId}`);
            setIsOpenModalCommon(false);
        } else {
            handleOpenModalPickSet();
            setIsOpenModalCommon(false);
        }
    };

    const HandleGetMuscleDetailsByPlanId = async (id) => {
        const [err, data] = await PLanService.GetListMuscleByPlanId(id);
        if (!err && data) {
            setListMuscleDetails(data);
        } else {
            console.log(err);
        }
    };

    const HandleGetSportDetailsByPlanId = async (id) => {
        const [err, data] = await PLanService.GetListSportByPlanId(id);
        if (!err && data) {
            setListSportDetails(data);
        } else {
            console.log(err);
        }
    };

    const fetchData = async () => {
        await HandleGetSportDetailsByPlanId(planId);
        await HandleGetMuscleDetailsByPlanId(planId);
        CalcMuscleCurrent();
    };

    useEffect(() => {
        fetchData();
        async function fetchCurrentPlan() {
            const [err, data] = await PLanService.GetPlanHistoryCurrentDay();
            if (!err && data) {
                console.log(data);
                setTimeWork(data.timeSpan);
                setPlaceWork(data.placeId);
            }
        }
        fetchCurrentPlan();
    }, [planId]);

    useEffect(() => {
        converPlanTemp();
        CalcMuscleCurrent();
    }, [listMuscle, listMuscleDetails]);

    const converPlanTemp = async () => {
        const tempItem = [];
        Array.from(Array(7).keys()).map((item, index) => {
            const currentDay = item + 1;

            const noiDungTapId = listMuscleDetails.filter((muscleDetail) => muscleDetail.daysOfWeek === currentDay).map((muscleDetail) => muscleDetail.muscleId);
            tempItem.push({
                id: planId,
                day_id: currentDay,
                noi_dung_tap_id: noiDungTapId,
                sport_id: 1,
            });
        });

        setPlanTemp(tempItem);
    };
    useEffect(() => {
        showErr(false);
    }, [selectedItem, muscleForDay]);

    const CalcMuscleCurrent = async () => {
        if (listMuscleDetails.length > 0) {
            var currentTimeTemp = currentTime.getDay() === 0 ? 7 : currentTime.getDay();

            const currentDayPlan = listMuscleDetails.filter((item) => item.daysOfWeek === currentTimeTemp);
            const muscleIds = currentDayPlan.map((item) => item.muscleId);
            const muscleDetails = listMuscle.filter((item) => muscleIds.includes(item.id));
            setMuscleForDay(muscleDetails);
        }
    };

    const _handleChangePlan = async () => {
        // localStorage.removeItem("currentPlan");

        const [err, data] = await UserServices.CreateUpdateCurrentPlanUser(0);
        if (!err && data) {
            navigate("/plan");
        }
    };
    const _handleChangeTarget = async () => {
        const [err, data] = await TargetServices.RemoveUserTargetByUserId();
        if (!err && data) {
            localStorage.removeItem("currentPlan");
            const [err, data] = await UserServices.CreateUpdateCurrentPlanUser(0);
            if (!err && data) {
                navigate("/plan");
            }
            navigate("/");
        } else {
            console.log(err);
        }
    };

    return (
        <MainPageWrapper>
            <ModalCommon
                nameButton1={"Chi tiết buổi tập"}
                nameButton2={"Tiếp tục tập"}
                isOpen={isOpenModalCommon}
                onClose={handleCloseModalCommon}
                onSave={handleSaveModalCommon}
                title="Hiện bạn đang có buổi tập chưa hoàn thành, bạn có muốn tập tiếp chứ?"
            ></ModalCommon>

            <MainPageInner>
                <MainPageHeader bg={userDetail.target.thumbnail}>
                    <MainPageHeaderPicture></MainPageHeaderPicture>
                    <LeftContent>
                        <LeftSubTitle>Mục tiêu đến {userDetail.timeEnd && format(userDetail.timeEnd, "yyyy-MM-dd")}</LeftSubTitle>
                        <LeftTitle onClick={() => _handleChangeTarget()}>
                            {userDetail.target.name}
                            <NextBackArrow active={false} />
                        </LeftTitle>
                    </LeftContent>
                    <RightContentWrapper>
                        <RightContent onClick={handleOpenModalCalendar}>
                            <RightTitle>Tháng {currentTime.getMonth() + 1}</RightTitle>
                            <RightIcon src={IconCommon.calendar}></RightIcon>
                        </RightContent>
                        <p onClick={() => _handleChangePlan()}>Đổi kế hoạch</p>
                    </RightContentWrapper>
                    <ModalWrapperHeader
                        bg={() => {
                            const temp = configTarget[userDetail.target.name];
                            switch (temp) {
                                case 1:
                                    return "linear-gradient(0deg, rgba(253, 0, 0, 0.2) 0%, rgba(253, 91, 0, 0.8) 100%);";
                                case 2:
                                    return "linear-gradient(209deg, rgba(0, 248, 158, 0.8) 17.33%, rgba(39, 39, 39, 0.2) 71.83%);";
                                case 3:
                                    return "linear-gradient(209deg, rgba(3, 82, 254, 0.8) 17.33%, rgba(39, 39, 39, 0.2) 71.83%);";
                                case 4:
                                    return "linear-gradient(209deg, rgba(245, 0, 44, 0.8) 17.33%, rgba(39, 39, 39, 0.2) 71.83%);";
                                default:
                                    return "linear-gradient(0deg, rgba(253, 0, 0, 0.2) 0%, rgba(253, 91, 0, 0.8) 100%);";
                            }
                        }}
                    >
                        {" "}
                    </ModalWrapperHeader>
                </MainPageHeader>
                <MainPageBody>
                    <BodyHeader>
                        <BodyItemWrapper>
                            <SubWrapperItem></SubWrapperItem>

                            {planTemp.map((item, index) => {
                                return (
                                    <>
                                        <Item
                                            bg={index + 1 === currentTime.getDay() || index + 1 - 7 === currentTime.getDay() ? "rgba(255, 92, 0, 0.20)" : ""}
                                            scale={selectedItem === item ? "scale(1.1)" : ""}
                                            borderRadius={selectedItem === item ? "10px" : "0"}
                                            key={index}
                                            onClick={() => {
                                                handleSelectedItem(item);
                                                const date = new Date();
                                                date.setDate(date.getDate() + index);
                                                setDateWorking(date);
                                            }}
                                        >
                                            {index === 0 ? <ItemTitle>T2</ItemTitle> : index !== 6 ? <ItemTitle>T{index + 2}</ItemTitle> : <ItemTitle> CN</ItemTitle>}

                                            <ItemTitle>
                                                {index + 1 !== currentTime.getDay()
                                                    ? index + 1 > currentTime.getDay()
                                                        ? currentTime.getDate() + (index + 1 - currentTime.getDay())
                                                        : currentTime.getDate() - (currentTime.getDay() - (index + 1))
                                                    : currentTime.getDate()}
                                            </ItemTitle>
                                            <ItemWrapper>
                                                {listMuscle
                                                    .filter((item1) => item.noi_dung_tap_id.includes(item1.id))
                                                    .slice(0, 4)
                                                    .map((item2, index) => (
                                                        <ItemIcon key={index} opacity={selectedItem === item ? "" : "0.5"} src={item2.thumbnail}></ItemIcon>
                                                    ))}
                                                {listMuscle.filter((item1) => item.noi_dung_tap_id.includes(item1.id)).length > 4 && <span>+ {listMuscle.length - 4}</span>}
                                            </ItemWrapper>
                                        </Item>
                                    </>
                                );
                            })}

                            <SubWrapperItem></SubWrapperItem>
                        </BodyItemWrapper>

                        <BodyContentWrapper bg={daysPicker === currentTime.getDay() || daysPicker - 7 === currentTime.getDay() ? "linear-gradient(180deg, rgba(255, 92, 0, 0.2) 17.33%, rgba(39, 39, 39, 0.1) 42.83%)" : ""}>
                            <WrapperFinal>
                                <ContentHeader>
                                    <ContentHeaderLeft>
                                        <ItemTitle1>{daysPicker === currentTime.getDay() || daysPicker - 7 === currentTime.getDay() ? "Nhóm cơ hôm nay" : daysPicker === 7 ? "Nhóm cơ chủ nhật" : `Nhóm cơ ngày thứ ${daysPicker + 1}`} </ItemTitle1>
                                        <ListIconWrapper>{muscleForDay.length > 0 ? muscleForDay.map((item, index) => <ItemIcon1 key={index} src={item.thumbnail}></ItemIcon1>) : <p>Hiện tại bạn chưa có nhóm cơ nào</p>}</ListIconWrapper>
                                        {err && <span>Bạn chưa chọn nhóm cơ nào, hãy chọn nhóm cơ để tập nhé!</span>}
                                    </ContentHeaderLeft>
                                    <ContentHeaderRight>
                                        <IconTopWrapper onClick={handleOpenModalNote}>
                                            <NoteIcon>
                                                <FileText></FileText>
                                            </NoteIcon>
                                            <NoteIcon mt="5px" onClick={handleOpenModalChange}>
                                                <Reload />
                                            </NoteIcon>
                                        </IconTopWrapper>
                                        <Icon2Wrapper>
                                            {/* <NoteIcon onClick={handleOpenModalHistory}>
                                                <History />
                                            </NoteIcon> */}
                                        </Icon2Wrapper>
                                    </ContentHeaderRight>
                                </ContentHeader>
                                <ContentBody>
                                    <BodyTitle>Thời gian tập *</BodyTitle>
                                    <ActionTimeWrapper>
                                        {Object.keys(configTimeWork).map((item, index) => (
                                            <>
                                                <ActionTime
                                                    bg={index + 1 === timeWork ? "#0352FE" : ""}
                                                    onClick={() => {
                                                        setTimeWork(index + 1);
                                                    }}
                                                >
                                                    {configTimeWork[item]}
                                                </ActionTime>
                                                ;
                                            </>
                                        ))}
                                    </ActionTimeWrapper>
                                    <BodyTitleWrapper>
                                        <BodyTitleLeft>Nơi tập *</BodyTitleLeft>
                                        <BodyTitleRight onClick={handleOpenModalPlace}>Xem thêm</BodyTitleRight>
                                    </BodyTitleWrapper>
                                    <ActionTimeWrapper>
                                        {Object.keys(configPlace).map((item, index) => (
                                            <>
                                                <ActionTime bg={index + 1 === placeWork ? "#0352FE" : ""} onClick={() => setPlaceWork(index + 1)}>
                                                    {configPlace[item]}{" "}
                                                </ActionTime>
                                            </>
                                        ))}
                                    </ActionTimeWrapper>
                                </ContentBody>
                                <ContentActionWrapper>
                                    <ContentAction
                                        bg={selectedItem.day_id === currentTime.getDay() || selectedItem.day_id === 7 ? "#0352fe" : "rgb(47, 47, 47)"}
                                        disabled={selectedItem.day_id !== currentTime.getDay() && selectedItem.day_id !== 7}
                                        onClick={handleOpenModalCommon}
                                    >
                                        Chi tiết buổi tập
                                    </ContentAction>
                                </ContentActionWrapper>
                            </WrapperFinal>
                        </BodyContentWrapper>
                    </BodyHeader>

                    <NavigationBase />
                </MainPageBody>
            </MainPageInner>

            <ModalChangeMuscle isOpen={isModalOpenChange} onClose={handleCloseModalChange} onSave={handleSaveDataChange} muscleForDay={muscleForDay} item={selectedItem}></ModalChangeMuscle>
            <ModalNote isOpen={isModalOpenNote} onClose={handleCloseModalNote} onSave={handleSaveDataNote}></ModalNote>
            <ModalCalendar isOpen={isModalOpenCalendar} onClose={handleCloseModalCalendar} onSave={handleCloseModalCalendar}></ModalCalendar>
            <ModalPickSet
                isOpen={isModalOpenPickSet}
                onClose={handleCloseModalPickSet}
                onSave={handleSaveDataPickSet}
                props={{
                    timeWork: timeWork,
                    muscleForDay: muscleForDay,
                    placeWork: placeWork,
                }}
            ></ModalPickSet>
            <ModalPlace isOpen={isModalOpenPlace} onClose={handleCloseModalPlace} onSave={handleSaveDataPlace}></ModalPlace>
        </MainPageWrapper>
    );
};

export default MainPage;

const MainPageWrapper = styled.div`
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #272727;
`;

const MainPageHeader = styled.div`
    width: 100%;
    height: 115px;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background-image: url(${(props) => props.bg});
    background-size: cover;
    background-position-y: 50%;
    background-position-x: -85px;
`;
const MainPageHeaderPicture = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(209deg, #ff5c00 17.33%, #272727 81.83%);
    z-index: -1;
`;

const LeftContent = styled.div`
    margin-left: 16px;
    z-index: 2;
`;

const LeftSubTitle = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const LeftTitle = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    z-index: 2;
    line-height: normal;
`;

const RightContent = styled.div`
    width: 113px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    flex-shrink: 0;
    z-index: 1;
    border-radius: 8px;
    background: rgba(47, 47, 47, 0.4);
`;

const RightTitle = styled.h3`
    margin-right: 10px;
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const RightIcon = styled.img`
    width: 24px;
    height: 24px;
`;

const MainPageBody = styled.div`
    width: 100%;
    height: 100%;
`;

const BodyHeader = styled.div`
    width: 100%;
    height: 100%;
`;

const BodyItemWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 15%;
`;

const ItemSubTitle = styled.h3`
    color: rgba(255, 255, 255, 0.4);
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const ItemTitle = styled.div`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    margin-top: 3px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

const ItemIcon = styled.img`
    width: 50%;
    height: 18px;
    margin-bottom: 8px;
    opacity: ${(props) => props.opacity};
    flex-shrink: 0;
`;
const Item = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% / 7);
    height: auto;
    transform: ${(props) => props.scale};
    min-height: 110px;
    flex-shrink: 0;
    border: 1px solid #3a3a3a;
    color: #fff;
    border-radius: ${(props) => props.borderRadius};
    background: ${(props) => (props.bg ? props.bg : "#2F2F2F")};
    transition: transform 0.3s;
    fill: rgba(255, 92, 0, 0.3);
    stroke-width: 1px;
    stroke: rgba(255, 92, 0, 0.2);
    backdrop-filter: blur(3.5px);
`;

const SubWrapperItem = styled.div`
    /* width: 16px;
  height: 96px;
  flex-shrink: 0;
  border-bottom: 1px solid #3a3a3a;
  background: #272727; */
`;

const MainPageFooter = styled.div`
    width: 94%;
`;

const BodyContentWrapper = styled.div`
    display: flex;

    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 90%;
    margin: 0 auto;

    border-radius: 16px;
    margin-top: 20px;
    border: 1px solid #484848;

    background: #2f2f2f;
    height: auto;
    background: ${(props) => props.bg};
`;

const ContentHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 20%;
    flex-shrink: 0;
    border-bottom: 1px solid #484848;
`;
const ContentHeaderLeft = styled.div`
    display: flex;
    flex-direction: column;
    span {
        color: red;
        font-family: "Be Vietnam Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 5px;
    }
`;
const ContentHeaderRight = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 2;
`;

const ContentBody = styled.div``;

const ContentActionWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 5%;
    z-index: 1;
    margin-bottom: 16px;
`;
const ContentAction = styled.button`
    width: 178px;
    z-index: 1;
    height: 42px;
    flex-shrink: 0;
    border-radius: 50px;
    border: 1px solid #575757;
    background: ${(props) => props.bg};
    color: #fff;
`;

const ItemTitle1 = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

const ItemIcon1 = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 10px;
    flex-shrink: 0;
    opacity: 0.5;
`;
const ItemIcon2 = styled.img`
    margin-bottom: 12px;
    z-index: 1;
`;

const ModalWrapper = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    border-radius: 16px 16px 0px 0px;
    opacity: 0.2;
    background: ${(props) => props.bg};
`;

const ListIconWrapper = styled.div`
    margin-top: 8px;
    p {
        color: rgb(135, 135, 135);
        font-family: "Be Vietnam Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;

const BodyTitle = styled.h3`
    margin-top: 12px;
    margin-bottom: 12px;
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;
const ActionTimeWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const ActionTime = styled.button`
    display: inline-flex;

    z-index: 1;
    margin-right: 12px;
    width: auto;
    height: 38px;
    padding: 14px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #484848;
    color: #fff;
    background: ${(props) => (props.bg ? props.bg : "#3a3a3a;")};
    margin-top: 12px;
`;

const ActionTimePlace = styled(ActionTime)``;

const ModalWrapperHeader = styled.div`
    width: 100vw;
    height: 100%;
    position: absolute;
    background: ${(props) => props.bg};
`;

const WrapperFinal = styled.div`
    width: 100%;
    padding: 12px;
    height: 100%;
`;

const ItemWrapper = styled.div`
    display: flex;
    margin-top: 8px;
    height: auto;
    flex-wrap: wrap;
    span {
        color: rgba(255, 255, 255, 0.4);
        text-align: center;
        font-family: "Be Vietnam Pro";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        width: 85%;
        line-height: normal;
    }
`;
const BodyTitleLeft = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;
const BodyTitleRight = styled.h3`
    color: #0352fe;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

const BodyTitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
`;
const MainPageInner = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;
const Icon2Wrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
`;
const NoteIcon = styled.div`
    margin-top: ${(props) => props.mt};
`;
const IconTopWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-bottom: 5px;
`;
const RightContentWrapper = styled.div`
    z-index: 2;
    p {
        margin: 0;
        padding: 0;
        color: #fff;
        text-align: center;
        font-family: "Be Vietnam Pro";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 12px;
    }
`;
