import styled from "styled-components";
import { Arrow } from "../based/IconShared";
import { IconCommon } from "../based/IconShared";
import { useEffect, useState } from "react";
import { GymImageShared } from "../based/GymImageShared";
import { muscleIcons } from "../based/IconShared";
import { GymExerciseImage } from "../based/GymExerciseShared";
import PlanServices from "../based/services/PLanServices";
import { format } from "date-fns";
import { CheckHistory } from "../based/ConfigIconSvg";
// import { CheckHistory } from "../based/ConfigIconSvg";
const configTypeExercise = {
    1: "Compound",
    2: "Isolation",
};

const DetailResult = ({ isDone }) => {
    if (isDone) {
        return (
            <InfoBody>
                <InfoCalo>
                    <Icon src={IconCommon.rice}></Icon>
                    <ResultCaloTotal>1500</ResultCaloTotal>
                    <Title>Calo in</Title>
                </InfoCalo>
                <InfoCaloOut>
                    <Icon src={IconCommon.fire_red}></Icon>
                    <ResultCaloOutTotal>2000</ResultCaloOutTotal>
                    <Title>Calo out</Title>
                </InfoCaloOut>
                <InfoWeight>
                    <Icon src={IconCommon.user_2}></Icon>
                    <ResultWeightTotal>+0.5kg</ResultWeightTotal>
                    <Title>Cân nặng</Title>
                </InfoWeight>
                <InfoWalk>
                    <Icon src={IconCommon.walk}></Icon>
                    <ResultWalkTotal>15030</ResultWalkTotal>
                    <Title>Đi bộ</Title>
                </InfoWalk>
                <InfoHeart>
                    <Icon src={IconCommon.heart_2}></Icon>
                    <ResultHeartTotal>108bpm</ResultHeartTotal>
                    <Title>Nhịp tim</Title>
                </InfoHeart>
            </InfoBody>
        );
    } else {
        return (
            <InfoBody>
                <InfoCalo>
                    <Icon src={IconCommon.rice}></Icon>
                    <ResultCaloTotal>-</ResultCaloTotal>
                    <Title>Calo in</Title>
                </InfoCalo>
                <InfoCaloOut>
                    <Icon src={IconCommon.fire_red}></Icon>
                    <ResultCaloOutTotal>-</ResultCaloOutTotal>
                    <Title>Calo out</Title>
                </InfoCaloOut>
                <InfoWeight>
                    <Icon src={IconCommon.user_2}></Icon>
                    <ResultWeightTotal>-</ResultWeightTotal>
                    <Title>Cân nặng</Title>
                </InfoWeight>
                <InfoWalk>
                    <Icon src={IconCommon.walk}></Icon>
                    <ResultWalkTotal>-</ResultWalkTotal>
                    <Title>Đi bộ</Title>
                </InfoWalk>
                <InfoHeart>
                    <Icon src={IconCommon.heart_2}></Icon>
                    <ResultHeartTotal>-</ResultHeartTotal>
                    <Title>Nhịp tim</Title>
                </InfoHeart>
            </InfoBody>
        );
    }
};

const ModalResultTrain = ({ isOpen, onClose, onSave, itemSelect, daysPicker }) => {
    const [stateExercise, setStateExercise] = useState({
        listExercise: [],
        isDone: false,
        workingDate: 0,
        listMuscle: [],
    });
    const [resultTrain, setResultTrain] = useState([]);

    useEffect(() => {
        if (isOpen && itemSelect) {
            const fetchExerciseDetails = async () => {
                const [err, data] = await PlanServices.GetListExerciseByPlanHistoryId(itemSelect.planHistoryId);
                if (!err) {
                    setResultTrain(data.result);
                    const dataTemp = [...data.listExercise];
                    console.log("data temp", dataTemp);
                    console.log("result", data.result);

                    const filterExercise = dataTemp.map((item) => {
                        const isDone = data.result.find((itemResult) => itemResult.exerciseId === item.id);
                        console.log("is done", isDone);
                        return {
                            ...item,
                            isDone: isDone.isDone,
                        };
                    });
                    setStateExercise((prevState) => ({
                        ...prevState,
                        listExercise: filterExercise,
                        workingDate: itemSelect.workingDate,
                        listMuscle: itemSelect.listMuscle,
                        isDone: itemSelect.isDone,
                    }));
                } else {
                    console.log("error", err);
                }
            };
            fetchExerciseDetails();
        } else {
            setStateExercise({
                listExercise: [],
                isDone: false,
                workingDate: 0,
                listMuscle: [],
            });
        }
    }, [isOpen, itemSelect]);

    console.log("state", stateExercise.listExercise);

    return (
        <>
            <ModalResultTrainWrapper style={{ display: isOpen ? "flex" : "none" }}>
                <ResultTrainInner>
                    <ResultTrainHeader>
                        <ResultTrainHeaderTop>
                            <IconBack src={Arrow.chevronleft} onClick={onClose}></IconBack>
                            <ResultTrainHeaderTitle>Kết quả luyện tập</ResultTrainHeaderTitle>
                            <ResultTrainHeaderRight src={IconCommon.deleteicon} onClick={onClose}></ResultTrainHeaderRight>
                        </ResultTrainHeaderTop>
                    </ResultTrainHeader>
                    <ResultTrainBody>
                        <BodyHeaderWrapper>
                            <BodyHeaderTopWrapper>
                                <ItemLeft>Ngày {daysPicker && format(daysPicker, "dd-MM-yyyy")}</ItemLeft>
                                <ItemRight>
                                    {Array.from(Array(4).keys()).map((item, index) => (
                                        <IconStar>
                                            <StarIcon src={IconCommon.start}></StarIcon>
                                        </IconStar>
                                    ))}
                                </ItemRight>
                            </BodyHeaderTopWrapper>
                            <BodyHeaderBottomWrapper>
                                <ItemLeft2>
                                    <GymImage src={GymImageShared.gymTanBinh}></GymImage>
                                </ItemLeft2>
                                <ItemRight2>
                                    <GymName>-</GymName>
                                    <GymAddress>-</GymAddress>
                                </ItemRight2>
                            </BodyHeaderBottomWrapper>
                        </BodyHeaderWrapper>
                        <BodyInfoWrapper>
                            <InfoMuscle>
                                <InfoMuscleTitle>Nhóm cơ</InfoMuscleTitle>
                                <ListMuscle>{itemSelect && itemSelect.listMuscle.map((item, index) => <Muscle src={item.thumbnail}></Muscle>)}</ListMuscle>
                            </InfoMuscle>
                        </BodyInfoWrapper>
                        <DetailResult isDone={stateExercise.isDone}></DetailResult>
                        {stateExercise.listExercise && stateExercise.listExercise.length > 0 ? (
                            <ItemNotify>
                                Bài tập <span style={{ color: "#20c997" }}> hoàn thành</span>: {resultTrain && resultTrain.filter((item) => item.isDone === true).length} bài tập.
                                <p>
                                    Bài tập <span style={{ color: "#dc3545" }}>chưa hoàn thành</span>: {resultTrain && resultTrain.filter((item) => item.isDone === false).length} bài tập.
                                </p>
                            </ItemNotify>
                        ) : (
                            <ItemLeft>Hiện chưa có lịch sử tập cho ngày này.</ItemLeft>
                        )}
                        <BodyScrollBarItemWrapper>
                            {stateExercise.listExercise.length > 0 ? (
                                stateExercise.listExercise.map((item, index) => (
                                    <BodyScrollBarItem bg={item.isDone ? "linear-gradient(90deg, rgba(0, 248, 158, 0.2) 0%, rgba(0, 248, 158, 0) 100%)" : "linear-gradient(90deg, rgba(255, 92, 0, 0.2) 0%, rgba(255, 92, 0, 0) 100%)"}>
                                        <SBItemLeft>
                                            <SBItemLeftWrapper src={item.thumbnail}>
                                                <LeftTitleWrapper bg={item.exerciseTypeId === 1 ? "linear-gradient(0deg, #FF5C00 0%, rgba(255, 92, 0, 0.00) 100%)" : "linear-gradient(0deg, #0352fe 0%, rgba(3, 82, 254, 0) 100%);"}>
                                                    <LeftTitle>{configTypeExercise[item.exerciseTypeId]}</LeftTitle>
                                                </LeftTitleWrapper>
                                            </SBItemLeftWrapper>
                                            <SBItemCenter>
                                                <SBItemCenterTitle>
                                                    <span>{item.name}</span>
                                                    <CheckHistory active={item.isDone}></CheckHistory>
                                                </SBItemCenterTitle>
                                                <SBItemCenterSubTitle>{item.nameEN}</SBItemCenterSubTitle>
                                                <SBItemCenterSubTitle>Thời gian: {item.timeSpan} phút</SBItemCenterSubTitle>
                                                <SBItemCenterSubTitle>Calories: {item.calories}</SBItemCenterSubTitle>
                                            </SBItemCenter>
                                        </SBItemLeft>

                                        <SBItemRight>
                                            <SBIcon src={Arrow.chevronright}></SBIcon>
                                        </SBItemRight>
                                    </BodyScrollBarItem>
                                ))
                            ) : (
                                <></>
                            )}
                        </BodyScrollBarItemWrapper>
                    </ResultTrainBody>
                    <ResultTrainFooter>
                        <ResultTrainButton onClick={onClose}>Đóng</ResultTrainButton>
                        {/* <ResultTrainButton2 onClick={onClose}>Tiếp tục tập</ResultTrainButton2> */}
                    </ResultTrainFooter>
                </ResultTrainInner>
            </ModalResultTrainWrapper>
        </>
    );
};

export default ModalResultTrain;

const ModalResultTrainWrapper = styled.div`
    width: 100%;
    height: 100vh;
    top: 0;
    display: flex;
    position: absolute;
    flex-direction: column-reverse;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
`;

const ResultTrainInner = styled.div`
    width: 100%;
    height: 100%;
    background-color: #3a3a3a;
    position: relative;
    border-radius: 16px 16px 0px 0px;
    background: #272727;
`;

const ResultTrainHeader = styled.div`
    width: 100%;
    height: 10%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

const ResultTrainHeaderTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

const ResultTrainHeaderTop = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
    width: 100%;

    flex-shrink: 0;
`;
const IconBack = styled.img`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
`;

const ResultTrainHeaderRight = styled.img`
    color: #ff5c00;
    text-align: right;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const ResultTrainBody = styled.div`
    width: 100%;
    height: 100vh;
    padding: 0 20px;
`;

const IconStyleConfig = styled.img`
    width: 10px;
    height: 9.51px;
    margin-right: 3px;
    flex-shrink: 0;
`;
const StarIcon = styled(IconStyleConfig)`
    width: 16px;
    height: 15.216px;
`;

const ItemLeft = styled.h3`
    color: #939393;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const ItemRight = styled.div`
    display: flex;
`;

const BodyHeaderTopWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const BodyHeaderWrapper = styled.div``;

const BodyHeaderBottomWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 32px;
    margin-bottom: 27px;
`;
const IconStar = styled.div``;

const ItemLeft2 = styled.div``;
const ItemRight2 = styled.div`
    margin-left: 12px;
`;
const GymImage = styled.img``;
const GymName = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;
const GymAddress = styled.h3`
    color: #939393;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const ConfigInfo = styled.div`
    width: calc(100% / 5);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`;
const BodyInfoWrapper = styled.div``;
const InfoMuscle = styled.div`
    margin-bottom: 19px;
`;
const InfoMuscleTitle = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 16px;
    line-height: normal;
`;
const ListMuscle = styled.div`
    display: flex;
`;
const Muscle = styled.img`
    width: 24px;
    margin-right: 5px;
    height: 24px;
    flex-shrink: 0;
    opacity: 0.7;
`;
const InfoBody = styled.div`
    width: 100%;
    display: flex;
    height: 94px;
    background-color: #2f2f2f;
    border: 1px solid;
    border-radius: 12px;
    flex-shrink: 0;
`;
const InfoCalo = styled(ConfigInfo)`
    border-right: 1px solid #3f3f3f;
`;

const Icon = styled.img`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
`;

const ResultTotalConfig = styled.h3`
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;
const ResultCaloTotal = styled(ResultTotalConfig)`
    color: #03a4fe;
`;

const Title = styled.h3`
    color: #939393;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const InfoCaloOut = styled(ConfigInfo)`
    border-right: 1px solid #3f3f3f;
`;
const ResultCaloOutTotal = styled(ResultTotalConfig)`
    color: #ff5c00;
`;
const InfoWeight = styled(ConfigInfo)`
    border-right: 1px solid #3f3f3f;
`;
const ResultWeightTotal = styled(ResultTotalConfig)`
    color: #00f89e;
`;
const InfoWalk = styled(ConfigInfo)`
    border-right: 1px solid #3f3f3f;
`;
const ResultWalkTotal = styled(ResultTotalConfig)`
    color: #ffc700;
`;
const InfoHeart = styled(ConfigInfo)``;
const ResultHeartTotal = styled(ResultTotalConfig)`
    color: #f5002c;
`;

const BodyScrollBarItemWrapper = styled.div`
    width: 100%;
    margin-top: 5%;
    height: 35%;
    overflow-y: scroll;
`;

const BodyScrollBarItem = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #3f3f3f;
    padding: 12px 0px;
    background: ${(props) => props.bg};
`;

const SBItemLeft = styled.div`
    width: 80%;
    height: 84px;
    display: flex;
    flex-shrink: 0;
`;

const SBItemCenter = styled.div`
    margin-left: 10px;
`;

const SBItemCenterTitle = styled.h2`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    span {
        margin-right: 12px;
    }
`;

const SubTitleConfig = styled.h3`
    color: #939393;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const SBItemCenterSubTitle = styled(SubTitleConfig)`
    // Add your styles here
`;

const SBItemRight = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const SBIcon = styled.img``;

const SBItemLeftWrapper = styled.div`
    width: 85px;
    position: relative;
    height: 84px;

    flex-shrink: 0;
    background-image: ${(props) => `url(${props.src})`};
    background-repeat: no-repeat;
`;
const LeftImage = styled.img``;

const LeftTitleWrapper = styled.div`
    width: 100%;
    height: 45px;
    position: absolute;
    bottom: 0;
    flex-shrink: 0;
    border-radius: 12px;
    background: ${(props) => props.bg};
    display: flex;
    align-items: flex-end;
    justify-content: center;
`;

const LeftTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    margin-bottom: 4px;
    font-weight: 500;
    line-height: normal;
`;

const ResultTrainFooter = styled.div`
    width: 100%;

    position: fixed;
    bottom: 0;
    height: 11%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: rgba(39, 39, 39, 0.8);
    box-shadow: 0px -1px 0px 0px #3f3f3f;
    backdrop-filter: blur(4px);
`;
const ResultTrainButton = styled.button`
    width: 130px;
    height: 42px;
    flex-shrink: 0;
    border-radius: 50px;
    border: none;
    color: #fff;
    background: #3a3a3a;
`;
const ResultTrainButton2 = styled(ResultTrainButton)`
    border-radius: 50px;
    border: 1px solid #ff7a2f;
    background: linear-gradient(268deg, #ff5c00 0.45%, #ff7a2f 97.59%);
    box-shadow: 0px 0px 15px 0px rgba(255, 110, 29, 0.4);
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

const ItemNotify = styled(ItemLeft)`
    margin-top: 2%;
    font-size: 12px;
`;
