import BaseServices from "./BaseServices";

const MuscleService = {
    GetAllMuscle: async () => {
        return await BaseServices.Get(`/api/Muscle/get-all-muscle`);
    },

    CreateUpdateMuscle: async (model) => {
        return await BaseServices.Post(`/api/Muscle/create-update-muscle`, model);
    },

    RemoveMuscleById: async (id) => {
        return await BaseServices.Get(`/api/Muscle/remove-muscle-dto-by-id/${id}`);
    }
    
};

export default MuscleService;
