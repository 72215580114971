import { useState } from "react";
import styled from "styled-components";
import { Calendar, Coach, Exercise, Nutrition, User } from "./ConfigIconSvg";

const NavigationBase = () => {
    const [activeItem, setActiveItem] = useState(0);

    const handleItemClick = (index) => {
        setActiveItem(index);
    };

    return (
        <>
            <NavigationWrapper>
                <NavigationItem onClick={() => handleItemClick(0)} active={activeItem === 0}>
                    <NavigationIcon>
                        <Calendar active={activeItem === 0} />
                    </NavigationIcon>
                    <NavigationTitle>Kế hoạch</NavigationTitle>
                </NavigationItem>

                <NavigationItem onClick={() => handleItemClick(1)} active={activeItem === 1}>
                    <NavigationIcon>
                        <Coach active={activeItem === 1} />
                    </NavigationIcon>
                    <NavigationTitle>Huấn luyện viên</NavigationTitle>
                </NavigationItem>

                <NavigationItem onClick={() => handleItemClick(2)} active={activeItem === 2}>
                    <NavigationIcon>
                        <Exercise active={activeItem === 2} />
                    </NavigationIcon>
                    <NavigationTitle>Bài tập</NavigationTitle>
                </NavigationItem>

                <NavigationItem onClick={() => handleItemClick(3)} active={activeItem === 3}>
                    <NavigationIcon>
                        <Nutrition active={activeItem === 3} />
                    </NavigationIcon>
                    <NavigationTitle>Dinh dưỡng</NavigationTitle>
                </NavigationItem>

                <NavigationItem onClick={() => handleItemClick(4)} active={activeItem === 4}>
                    <NavigationIcon>
                        <User active={activeItem === 4} />
                    </NavigationIcon>
                    <NavigationTitle>Tài khoản</NavigationTitle>
                </NavigationItem>
            </NavigationWrapper>
        </>
    );
};
export default NavigationBase;

const NavigationWrapper = styled.div`
    display: flex;
    position: fixed;
    bottom: 0;
    justify-content: space-between;
    width: 100vw;
    overflow-x: hidden;
    padding-left: 10px;
    padding-right: 10px;
    height: 64px;
    background: rgba(39, 39, 39);
    box-shadow: 0px -1px 0px 0px #3f3f3f;
    backdrop-filter: blur(4px);
`;
const NavigationItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
`;

const NavigationIcon = styled.div`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
`;
const NavigationTitle = styled.h3`
    color: #878787;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
