import styled from "styled-components";
import { Arrow } from "../../based/IconShared";
import { IconCommon } from "../../based/IconShared";
import { useEffect, useState } from "react";
import { plan, dayDetails, Target } from "../../based/FakeApi";
import { Form, Link, useNavigate, useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import ExerciseServices from "../../based/services/ExerciseServices";
import TargetServices from "../../based/services/TargetServices";
import Common from "../../based/Common";
import ModalChangeSet from "./ModalChangeSet";
import ModalCreateSet from "./ModalCreateSet";
import ModalCopyAndCreateSet from "./ModalCopyAndCreateSet";
import ModalFolderOfUser from "../modalplanofme/ModalFolderOfUser";
import PlanServices from "../../based/services/PLanServices";
import PlanHistoryExtendServices from "../../based/services/PlanHistoryExtendServices";
const configTypeExercise = {
    1: "Compound",
    2: "Isolation",
    3: "Superset",
};

const ModalPickSet = ({ isOpen, onClose, onSave, props }) => {
    const navigate = useNavigate();
    const { planId } = useParams();
    console.log("props ne", props);
    const [isOpenModalPlanOfMe, setIsModalOpenPlanOfMe] = useState(false);
    const [isOpenModalChangeSet, setIsModalOpenChangeSet] = useState(false);
    const [isOpenModalCreateSet, setIsModalOpenCreateSet] = useState(false);
    const [isOpenModalCopyAndCreateSet, setIsModalOpenCopyAndCreateSet] = useState(false);
    const [isOpenModalFolderOfUser, setIsOpenModalFolderOfUser] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(0);
    const [listExerciseRecommend, setListExerciseRecommend] = useState([]);
    const [vectorTargetLongTime, setVectorTargetLongTime] = useState([]);
    const [vectorTargetShortTime, setVectorTargetShortTime] = useState([1, 1, 1]);
    const [planExercisePicker, setPlanExercisePicker] = useState({});
    const [listExercisePicker, setListExercisePicker] = useState([]);
    const [listExerciseFirst, setListExerciseFirst] = useState([]);
    const [listExerciseSecond, setListExerciseSecond] = useState([]);
    const [listExerciseThird, setListExerciseThird] = useState([]);
    const [fullListExercise, setFullListExercise] = useState([]);

    const [formDataGetExercise, setFormDataGetExercise] = useState({
        vectorUser: [],
        muscleId: 0,
    });
    const [dataSelectedPlanOfMe, setDataSelectedPlanOfMe] = useState({
        id: "",
        name: "",
    });
    const [formAddPlanHistory, setFormAddPlanHistory] = useState({
        timeSpan: 0,
        placeId: 0,
    });

    useEffect(() => {
        setSelectedPlan(0);
        getListExerciseByVector();
        if (props) {
            setFormAddPlanHistory({
                timeSpan: props.timeWork,
                placeId: props.placeWork,
            });
        }
    }, [isOpen]);

    useEffect(() => {}, [formDataGetExercise]);

    useEffect(() => {
        if (selectedPlan === 0) {
            setListExercisePicker(listExerciseFirst);
            setPlanExercisePicker({});
        }
        if (selectedPlan === 1) {
            setListExercisePicker(listExerciseSecond);
            setPlanExercisePicker({});
        }
        if (selectedPlan === 2) {
            setListExercisePicker(listExerciseThird);
            setPlanExercisePicker({});
        } else if (selectedPlan > 3) {
            const fetchData = async () => {
                const [err, data] = await PlanServices.GetListExerciseByPlanId(selectedPlan);
                if (!err) {
                    setListExercisePicker(data);
                }
            };

            fetchData();
        }
    }, [selectedPlan]);

    useEffect(() => {
        const updatedList = fullListExercise.filter((item) => {
            return !listExercisePicker.some((pickerItem) => pickerItem.id === item.id);
        });
        setListExerciseRecommend(updatedList);
    }, [listExercisePicker]);

    useEffect(() => {
        getUserInfo();
    }, [props.muscleForDay]);
    const getUserInfo = async () => {
        var token = Common.GetToken();

        if (token) {
            var decode = jwtDecode(token);
            const [err, data] = await TargetServices.GetUserTargetByUserId(decode.id);
            if (!err && data) {
                const [err1, data1] = await TargetServices.GetTargetByTargetId(data.targetId);
                if (!err1 && data1) {
                    const exerciseTypeId = data1.exerciseTypeId;
                    const exerciseCharacteristic = data1.exerciseCharacteristic;
                    const exerciseLevel = data1.exerciseLevel;
                    const vectorLongTime = [exerciseTypeId, exerciseCharacteristic, exerciseLevel];
                    setVectorTargetLongTime(vectorLongTime);
                    var result = Common.averageOfAllVectors(vectorLongTime, vectorTargetShortTime);
                    setFormDataGetExercise({
                        ...formDataGetExercise,
                        vectorUser: result,
                    });
                }
            }
        } else {
            window.location.href = "/login";
        }
    };

    const getListExerciseByVector = async () => {
        const listExerciseFirstTemp = [];
        const listExerciseSecondTemp = [];
        const listExerciseThirdTemp = [];
        const fullListExerciseTemp = [];
        // for (let i = 0; i < props.muscleForDay.length; i++) {
        const listMuscleForDay = props.muscleForDay.map((item) => item.id);
        const formData = {
            vectorUser: formDataGetExercise.vectorUser,
            muscleId: listMuscleForDay,
        };
        const [err, data] = await ExerciseServices.GetListExerciseByVector(formData, props.placeWork);
        if (!err && data) {
            const similarity = data.resultSimilarity;
            const listExercise = data.dataListExercise;
            listExercise.forEach((exercise, index) => {
                exercise.similarity = similarity[index];
            });

            const sortListExerciseBySimilarity = listExercise.sort((a, b) => b.similarity - a.similarity);
            const countMuscle = props.muscleForDay.length;
            sortListExerciseBySimilarity.map((item) => fullListExerciseTemp.push(item));
            if (countMuscle < 3) {
                sortListExerciseBySimilarity.slice(0, 3).map((item) => listExerciseFirstTemp.push(item));
                sortListExerciseBySimilarity.slice(3, 6).map((item) => listExerciseSecondTemp.push(item));
                sortListExerciseBySimilarity.slice(6, 9).map((item) => listExerciseThirdTemp.push(item));
            } else if (countMuscle < 5) {
                sortListExerciseBySimilarity.slice(0, 2).map((item) => listExerciseFirstTemp.push(item));
                sortListExerciseBySimilarity.slice(2, 4).map((item) => listExerciseSecondTemp.push(item));
                sortListExerciseBySimilarity.slice(4, 6).map((item) => listExerciseThirdTemp.push(item));
            } else {
                sortListExerciseBySimilarity.slice(0, 1).map((item) => listExerciseFirstTemp.push(item));

                sortListExerciseBySimilarity.slice(1, 2).map((item) => listExerciseSecondTemp.push(item));
                sortListExerciseBySimilarity.slice(2, 3).map((item) => listExerciseThirdTemp.push(item));
            }
        }

        setListExerciseFirst(listExerciseFirstTemp);
        setListExerciseSecond(listExerciseSecondTemp);
        setListExerciseThird(listExerciseThirdTemp);
        setListExercisePicker(listExerciseFirstTemp);
        setFullListExercise(fullListExerciseTemp);
    };

    const handleStartWorking = async () => {
        const listExerciseId = listExercisePicker.map((item) => item.id);
        const listMuscle = props.muscleForDay.map((item) => item.id);
        const [err, data] = await PlanServices.CreateUpdatePlanHistory(formAddPlanHistory);

        if (!err) {
            let formAddPlanHistoryExercise = {
                planHistoryId: data,
                listExerciseId: listExerciseId,
            };
            let model = {
                planHistoryId: data,
                muscleId: listMuscle,
            };
            const [err2, data2] = await PlanServices.CreateUpdatePlanHistoryExerciseWithListExercise(formAddPlanHistoryExercise);
            const [err3, data3] = await PlanHistoryExtendServices.CreateUpdatePlanHistoryExtendListMuscle(model);
            if (!err2 && !err3) {
                navigate(`/practice/${planId}/${data}`);
            } else {
                console.log(err2);
            }
        } else {
            console.log(err);
        }
    };

    const handleOpenModalPlanOfMe = () => {
        setIsModalOpenPlanOfMe(true);
    };

    const handleCloseModalPlanOfMe = () => {
        setIsModalOpenPlanOfMe(false);
    };

    const handleSaveDataPlanOfMe = (data) => {
        setDataSelectedPlanOfMe(data);
        setSelectedPlan(data.id);
        setIsModalOpenPlanOfMe(false);
        plan[3].name = data.name;
    };

    const handleOpenModalChangeSet = () => {
        setIsModalOpenChangeSet(true);
    };

    const handleCloseModalChangeSet = () => {
        setIsModalOpenChangeSet(false);
    };

    const handleSaveDataChangeSet = (data) => {
        setIsModalOpenChangeSet(false);
        setListExercisePicker(data);
    };

    const handleOpenModalCreateSet = () => {
        setIsModalOpenCreateSet(true);
    };

    const handleCloseModalCreateSet = () => {
        setIsModalOpenCreateSet(false);
    };

    const handleSaveDataCreateSet = (data) => {
        setIsModalOpenCreateSet(false);
    };

    const handleOpenModalCopyAndCreateSet = () => {
        setIsModalOpenCopyAndCreateSet(true);
    };

    const handleCloseModalCopyAndCreateSet = () => {
        setIsModalOpenCopyAndCreateSet(false);
    };

    const handleSaveDataCopyAndCreateSet = (data) => {
        setIsModalOpenCopyAndCreateSet(false);
    };

    const handleOpenModalFolderOfUser = () => {
        setIsOpenModalFolderOfUser(true);
    };

    const handleCloseModalFolderOfUser = () => {
        setIsOpenModalFolderOfUser(false);
    };

    const handleSaveDataFolderOfUser = (data) => {
        setSelectedPlan(data.id);
        setDataSelectedPlanOfMe(data);
        setPlanExercisePicker(data);
        setIsOpenModalFolderOfUser(false);
        plan[3].name = data.name;
    };

    return (
        <>
            <ModalPickSetWrapper style={{ display: isOpen ? "flex" : "none" }}>
                <ModalChangeSet
                    isOpen={isOpenModalChangeSet}
                    onClose={handleCloseModalChangeSet}
                    onSave={handleSaveDataChangeSet}
                    listExercise={listExercisePicker}
                    PlanExercisePicker={planExercisePicker}
                    fullListExercise={fullListExercise}
                ></ModalChangeSet>
                <ModalCreateSet isOpen={isOpenModalCreateSet} onClose={handleCloseModalCreateSet} onSave={handleSaveDataCreateSet}></ModalCreateSet>
                <ModalCopyAndCreateSet isOpen={isOpenModalCopyAndCreateSet} onClose={handleCloseModalCopyAndCreateSet} onSave={handleSaveDataCopyAndCreateSet} listExercise={listExercisePicker}></ModalCopyAndCreateSet>
                <ModalFolderOfUser isOpen={isOpenModalFolderOfUser} onClose={handleCloseModalFolderOfUser} onSave={handleSaveDataFolderOfUser}></ModalFolderOfUser>
                <PickSetInner>
                    <PickSetHeader>
                        <PickSetHeaderTop>
                            <IconBack src={Arrow.chevronleft} onClick={onClose}></IconBack>
                            <PickSetHeaderTitle>Chọn Set buổi tập</PickSetHeaderTitle>
                            <PickSetHeaderRightWrapper onClick={handleOpenModalCreateSet}>
                                <PickSetHeaderRight src={IconCommon.create_new}></PickSetHeaderRight>
                                <PickSetHeaderRightTitle>Tạo mới</PickSetHeaderRightTitle>
                            </PickSetHeaderRightWrapper>
                        </PickSetHeaderTop>
                    </PickSetHeader>
                    <PickSetBody>
                        <p>
                            <BodyHeader>
                                {Array.from({ length: 3 }).map((_, index) => (
                                    <>
                                        <ButtonPlan isSelected={index === selectedPlan ? "True" : "False"} onClick={() => setSelectedPlan(index)}>
                                            {index + 1}
                                        </ButtonPlan>{" "}
                                    </>
                                ))}
                                <ButtonPlanMe isSelected={selectedPlan > 3 ? "True" : "False"} onClick={handleOpenModalFolderOfUser}>
                                    <TitlePlanOfMe>{dataSelectedPlanOfMe.name != "" ? dataSelectedPlanOfMe.name : "Của tôi"}</TitlePlanOfMe>
                                    <span>
                                        <img src={Arrow.chevronright}></img>
                                    </span>
                                </ButtonPlanMe>{" "}
                            </BodyHeader>
                            <BodyActionWrapper>
                                <ActionLeft onClick={() => handleOpenModalCopyAndCreateSet()}>
                                    <AIcon src={IconCommon.copy}></AIcon>
                                    <TitleLeft>Copy & Chỉnh sửa</TitleLeft>
                                </ActionLeft>
                                <ActionRight display={selectedPlan > 3 ? true : false}>
                                    <AIcon src={IconCommon.edit}></AIcon>
                                    <TitleLeft onClick={handleOpenModalChangeSet}>Chỉnh sửa</TitleLeft>
                                </ActionRight>
                            </BodyActionWrapper>
                            <BodyScrollBarItemWrapper>
                                {listExercisePicker &&
                                    listExercisePicker.map((item, index) => (
                                        <BodyScrollBarItem key={index}>
                                            <SBItemLeft>
                                                <SBItemLeftWrapper src={item.thumbnail}>
                                                    <LeftTitleWrapper
                                                        bg={
                                                            item.exerciseTypeId === 1
                                                                ? "linear-gradient(0deg, #FF5C00 0%, rgba(255, 92, 0, 0.00) 100%)"
                                                                : item.exerciseTypeId === 2
                                                                ? "linear-gradient(0deg, #0352FE 0%, rgba(3, 82, 254, 0.00) 100%)"
                                                                : "linear-gradient(0deg, #F5002C 0%, rgba(245, 0, 44, 0.00) 100%)"
                                                        }
                                                    >
                                                        <LeftTitle>{configTypeExercise[item.exerciseTypeId]}</LeftTitle>
                                                    </LeftTitleWrapper>
                                                </SBItemLeftWrapper>
                                                <SBItemCenter>
                                                    <SBItemCenterTitle>{item.name}</SBItemCenterTitle>
                                                    <SBItemCenterSubTitle>{item.nameEN}</SBItemCenterSubTitle>
                                                    <SBItemCenterSubTitle>Thời gian tập: {item.timeSpan}s/6 reps</SBItemCenterSubTitle>
                                                    <SBItemCenterSubTitle>Calo tiêu thụ: {item.calories} calories</SBItemCenterSubTitle>
                                                </SBItemCenter>
                                            </SBItemLeft>
                                            <SBItemRight>
                                                <SBIcon src={Arrow.chevronright}></SBIcon>
                                            </SBItemRight>
                                        </BodyScrollBarItem>
                                    ))}
                            </BodyScrollBarItemWrapper>
                        </p>
                    </PickSetBody>
                    <PickSetFooter>
                        <PickSetButton onClick={() => handleStartWorking()}>Bắt đầu luyện tập</PickSetButton>
                    </PickSetFooter>
                </PickSetInner>
            </ModalPickSetWrapper>
        </>
    );
};

export default ModalPickSet;

const ModalPickSetWrapper = styled.div`
    width: 100%;
    height: 100vh;
    top: 0;
    display: flex;
    position: fixed;
    flex-direction: column-reverse;
    z-index: 2;
    background: rgb(39, 39, 39);
`;

const PickSetInner = styled.div`
    width: 100%;
    height: 100%;
    background-color: #3a3a3a;
    position: relative;
    border-radius: 16px 16px 0px 0px;
    background: #272727;
`;

const PickSetHeader = styled.div`
    width: 100%;
    height: 7%;
    background: rgba(39, 39, 39, 0.6);
    box-shadow: 0px 1px 0px 0px #3f3f3f;
    backdrop-filter: blur(4px);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

const PickSetHeaderTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

const PickSetHeaderTop = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
    width: 100%;

    flex-shrink: 0;
`;
const IconBack = styled.img`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
`;

const PickSetHeaderRight = styled.img`
    color: #ff5c00;
    text-align: right;
    font-family: "Be Vietnam Pro";
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const PickSetBody = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    p {
        padding: 0 12px;
        height: 100%;
    }
`;

const IconStyleConfig = styled.img`
    width: 10px;
    height: 9.51px;
    margin-right: 3px;
    flex-shrink: 0;
`;
const StarIcon = styled(IconStyleConfig)`
    width: 16px;
    height: 15.216px;
`;

const ItemLeft = styled.h3`
    color: #939393;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const ItemRight = styled.div`
    display: flex;
`;

const BodyHeaderTopWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const BodyHeaderWrapper = styled.div``;

const BodyHeaderBottomWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 32px;
    margin-bottom: 27px;
`;
const IconStar = styled.div``;

const ItemLeft2 = styled.div``;
const ItemRight2 = styled.div`
    margin-left: 12px;
`;
const GymImage = styled.img``;
const GymName = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;
const GymAddress = styled.h3`
    color: #939393;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const ConfigInfo = styled.div`
    width: calc(100% / 5);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`;
const BodyActionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;
const InfoMuscle = styled.div`
    margin-bottom: 19px;
`;
const InfoMuscleTitle = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 16px;
    line-height: normal;
`;
const ListMuscle = styled.div`
    display: flex;
`;
const Muscle = styled.img`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    opacity: 0.7;
`;
const InfoBody = styled.div`
    width: 100%;
    display: flex;
    height: 94px;
    background-color: #2f2f2f;
    border: 1px solid;
    border-radius: 12px;
    flex-shrink: 0;
`;
const InfoCalo = styled(ConfigInfo)`
    border-right: 1px solid #3f3f3f;
`;

const Icon = styled.img`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
`;

const ResultTotalConfig = styled.h3`
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;
const ResultCaloTotal = styled(ResultTotalConfig)`
    color: #03a4fe;
`;

const Title = styled.h3`
    color: #939393;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const InfoCaloOut = styled(ConfigInfo)`
    border-right: 1px solid #3f3f3f;
`;
const ResultCaloOutTotal = styled(ResultTotalConfig)`
    color: #ff5c00;
`;
const InfoWeight = styled(ConfigInfo)`
    border-right: 1px solid #3f3f3f;
`;
const ResultWeightTotal = styled(ResultTotalConfig)`
    color: #00f89e;
`;
const InfoWalk = styled(ConfigInfo)`
    border-right: 1px solid #3f3f3f;
`;
const ResultWalkTotal = styled(ResultTotalConfig)`
    color: #ffc700;
`;
const InfoHeart = styled(ConfigInfo)``;
const ResultHeartTotal = styled(ResultTotalConfig)`
    color: #f5002c;
`;

const BodyScrollBarItemWrapper = styled.div`
    width: 100%;
    margin-top: 5%;
    height: 70%;
    overflow: scroll;
`;

const BodyScrollBarItem = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #3f3f3f;
    padding: 12px 0px;
`;

const SBItemLeft = styled.div`
    width: 80%;
    height: 84px;
    display: flex;
    flex-shrink: 0;
`;

const SBItemCenter = styled.div`
    margin-left: 10px;
`;

const SBItemCenterTitle = styled.h2`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    line-height: normal;
`;

const SubTitleConfig = styled.h3`
    color: #939393;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const SBItemCenterSubTitle = styled(SubTitleConfig)`
    // Add your styles here
`;

const SBItemRight = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const SBIcon = styled.img``;

const SBItemLeftWrapper = styled.div`
    width: 85px;
    position: relative;
    height: 84px;

    flex-shrink: 0;
    background-image: ${(props) => `url(${props.src})`};
    background-repeat: no-repeat;
    border-radius: 14px;
`;
const LeftImage = styled.img``;

const LeftTitleWrapper = styled.div`
    width: 100%;
    height: 45px;
    position: absolute;
    bottom: 0;
    flex-shrink: 0;
    border-radius: 12px;
    background: ${(props) => (props.bg ? props.bg : "")};
    display: flex;
    align-items: flex-end;
    justify-content: center;
`;

const LeftTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    margin-bottom: 4px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5px;
`;

const PickSetFooter = styled.div`
    width: 100%;
    position: fixed;
    height: 11%;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(39, 39, 39, 0.8);
    box-shadow: 0px -1px 0px 0px #3f3f3f;
    backdrop-filter: blur(4px);
`;
const PickSetButton = styled.button`
    width: 176px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 50px;
    border: 1px solid #ff7a2f;
    background: linear-gradient(268deg, #ff5c00 0.45%, #ff7a2f 97.59%);
    box-shadow: 0px 0px 15px 0px rgba(255, 110, 29, 0.4);
    color: #fff;

    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const PickSetHeaderRightWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const PickSetHeaderRightTitle = styled.h3`
    color: #ff5c00;
    text-align: right;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const BodyHeader = styled.div`
    margin-bottom: 7%;
    margin-top: 12px;

    display: flex;
`;

const ButtonPlan = styled.button`
    display: inline-flex;

    height: 38px;
    padding: 14px 22px;
    margin-right: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #484848;
    background: ${(props) => (props.isSelected === "True" ? "#0352FE" : "#3A3A3A")};
    color: #fff;
`;

const ButtonPlanMe = styled(ButtonPlan)`
    flex: 1;
    display: flex;
    background: ${(props) => (props.isSelected === "True" ? "#0352FE" : "#3A3A3A")};
    font-size: 14px;
    margin-right: 0px;
`;

const TitlePlanOfMe = styled.h3`
    display: -webkit-box;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex-shrink: 0;
    overflow: hidden;
    color: #fff;
    text-overflow: ellipsis;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const ActionLeft = styled.div`
    display: flex;
`;
const ActionRight = styled.div`
    display: ${(props) => (props.display ? "flex" : "none")};
`;
const AIcon = styled.img`
    margin-right: 12px;
`;
const TitleLeft = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;
