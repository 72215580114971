import styled, { keyframes } from "styled-components";
import { Arrow } from "../../based/IconShared";
import { IconCommon, Tick } from "../../based/IconShared";
import { useEffect, useState } from "react";
import NavigationPickMuscle from "../../based/NavigationPickMuscle";
import ExerciseServices from "../../based/services/ExerciseServices";
import MuscleService from "../../based/services/MuscleService";
import useDebounce from "../../based/hooks/useDebounce";
import Common from "../../based/Common";

const configTypeExercise = {
    1: "Compound",
    2: "Isolation",
    3: "Superset",
};
const ModalAddSet = ({ isOpen, onClose, onSave, listExerciseCurrent }) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedMenuTopic, setSelectedMenuTopic] = useState(null);
    const [listExerciseForAdd, setListExerciseForAdd] = useState([]);
    const [nameExercise, setNameExercise] = useState("");
    const [muscleList, setMuscleList] = useState([]);
    const [results, setResults] = useState([]);
    const [currentIndex, setCurrentIndex] = useState();

    const [isSearching, setIsSearching] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    useEffect(() => {
        const fetchData = async () => {
            await handleGetListExerciseByMuscleId(selectedMenuTopic);
        };
        fetchData();
    }, [selectedMenuTopic]);

    useEffect(() => {
        const fetchData = async () => {
            const [err, data] = await ExerciseServices.SearchExerciseSuggestions({
                ...Common.Paging,
                keyword: debouncedSearchTerm,
            });
            if (!err) {
                setResults(data);
            }
        };
        if (debouncedSearchTerm) {
            setIsSearching(true);
            fetchData();
        } else {
            setIsSearching(false);
            setResults([]);
        }
    }, [debouncedSearchTerm]);

    useEffect(() => {
        if (isOpen) {
            const fetchData = async () => {
                const [err, data] = await MuscleService.GetAllMuscle();
                if (!err && data.length > 0) {
                    setMuscleList(data);
                    setSelectedMenuTopic(data[0].id);
                    await handleGetListExerciseByMuscleId(data[0].id);
                }
            };
            fetchData();
        }
    }, [isOpen]);

    const handleGetListExerciseByMuscleId = async (id) => {
        const [err, data] = await ExerciseServices.GetExerciseByPagingMuscle({
            ...Common.Paging,
            keyword: `${id}`,
        });
        if (!err) {
            if (listExerciseCurrent) {
                const listForAdd = data.filter((item) => listExerciseCurrent.findIndex((item2) => item2.id === item.id) === -1);
                setListExerciseForAdd(listForAdd);
            }
        } else {
            console.log(err);
        }
    };

    const handleOnSave = () => {
        onSave(selectedItems);
        setSelectedItems([]);
    };

    const _handleSearchKeyWord = async () => {
        const [err, data] = await ExerciseServices.SearchExerciseSuggestions({
            ...Common.Paging,
            keyword: debouncedSearchTerm,
        });
        if (!err) {
            setSearchTerm("");
            setIsSearching(false);
            setListExerciseForAdd(data);
        }
    };

    return (
        <>
            <ModalAddSetWrapper style={{ display: isOpen ? "flex" : "none" }}>
                <AddSetInner>
                    <AddSetHeader>
                        <AddSetHeaderTop>
                            <IconBack></IconBack>
                            <AddSetHeaderTitle>Thêm bài tập</AddSetHeaderTitle>
                            <AddSetHeaderRightWrapper>
                                <IconClose onClick={onClose} src={IconCommon.deleteicon}></IconClose>
                            </AddSetHeaderRightWrapper>
                        </AddSetHeaderTop>
                    </AddSetHeader>
                    <AddSetContent>
                        <ContentLeftWrapper>
                            <Input value={searchTerm} placeholder="Tìm kiếm bài tập" onChange={(e) => setSearchTerm(e.target.value)}></Input>

                            <IconSearch onClick={() => _handleSearchKeyWord()} src={IconCommon.search}></IconSearch>

                            {isSearching ? (
                                <>
                                    <IconCancel
                                        onClick={() => {
                                            setSearchTerm("");
                                            setIsSearching(false);
                                        }}
                                    >
                                        <img src={IconCommon.deleteicon} alt="cancel" />
                                    </IconCancel>
                                    <ResultsContainer>
                                        {results.map((result, index) => {
                                            const isNotInCurrent = listExerciseCurrent.findIndex((item) => item.id === result.id) === -1;

                                            if (isNotInCurrent) {
                                                return (
                                                    <p
                                                        key={index}
                                                        onClick={() => {
                                                            // if (!selectedItems.some((item) => item.id === result.id)) {
                                                            //     setSelectedItems([...selectedItems, result]);
                                                            // }
                                                            setListExerciseForAdd([result]);
                                                            let musclePicker = muscleList.find((item) => item.orderNumber === result.orderNumber);
                                                            // setSelectedMenuTopic(musclePicker.id);
                                                            setCurrentIndex(musclePicker.orderNumber);
                                                            setNameExercise(musclePicker.name);

                                                            // setSelectedMenuTopic(muscleList.find((item) => item.orderNumber === result.orderNumber).id);

                                                            setSearchTerm("");
                                                            setIsSearching(false);
                                                        }}
                                                    >
                                                        <img alt="#" src={result.thumbnail}></img>
                                                        <ResultItem>{result.name}</ResultItem>
                                                    </p>
                                                );
                                            }

                                            // Return null if the result is in listExerciseCurrent
                                            return null;
                                        })}
                                    </ResultsContainer>
                                </>
                            ) : null}
                        </ContentLeftWrapper>

                        <ContentRightWrapper onClick={() => handleOnSave()}>
                            <IconTick src={IconCommon.tick_orange}></IconTick>
                            <Title>Chọn</Title>
                        </ContentRightWrapper>
                    </AddSetContent>
                    <BodyTitleWrapper>
                        <BodySubTitle>
                            Nhóm bài {nameExercise}
                            {listExerciseForAdd && listExerciseForAdd.length > 0 ? ` (${listExerciseForAdd.length})` : ""}
                        </BodySubTitle>
                    </BodyTitleWrapper>
                    <AddSetBody>
                        <BodyScrollBarItemWrapper>
                            {listExerciseForAdd &&
                                listExerciseForAdd.map((item, index) => (
                                    <>
                                        <BodyScrollBarItem
                                            mt={index === 0 ? "10px" : ""}
                                            onClick={() => {
                                                // selectedItems.includes(item) ? setSelectedItems(selectedItems.filter((item2) => item2 !== item)) : setSelectedItems([...selectedItems, item]);
                                                if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
                                                    setSelectedItems(selectedItems.filter((selectedItem) => selectedItem.id !== item.id));
                                                } else {
                                                    setSelectedItems([...selectedItems, item]);
                                                }
                                            }}
                                        >
                                            <WrapperBodyScrollBarItem bg={selectedItems.some((selectedItem) => selectedItem.id === item.id) ? "#0352FE" : " "}></WrapperBodyScrollBarItem>

                                            <SBItemLeft>
                                                <SBIconLeftWrapper>
                                                    <SBIconLeft src={selectedItems.some((selectedItem) => selectedItem.id === item.id) ? Tick.tickdone : Tick.tick}></SBIconLeft>
                                                </SBIconLeftWrapper>
                                                <SBItemLeftWrapper src={item.thumbnail}>
                                                    <LeftTitleWrapper
                                                    // bg={
                                                    //   item.type === 1
                                                    //     ? " linear-gradient(0deg, #0352fe 0%, rgba(3, 82, 254, 0) 100%)"
                                                    //     : item.type === 2
                                                    //     ? "linear-gradient(0deg, #FF5C00 0%, rgba(255, 92, 0, 0.00) 100%);"
                                                    //     : "linear-gradient(0deg, #F5002C 0%, rgba(245, 0, 44, 0.00) 100%);"
                                                    // }
                                                    >
                                                        <LeftTitle>{configTypeExercise[item.exerciseTypeId]}</LeftTitle>
                                                    </LeftTitleWrapper>
                                                </SBItemLeftWrapper>
                                                <SBItemCenter>
                                                    <SBItemCenterTitle>{item.name}</SBItemCenterTitle>
                                                    <SBItemCenterSubTitle>{item.nameEN}</SBItemCenterSubTitle>
                                                    <SBItemCenterSubTitle>Thời gian tập: {item.timeSpan}s/6 reps</SBItemCenterSubTitle>
                                                    <SBItemCenterSubTitle>Calo tiêu thụ: {item.calories} calories</SBItemCenterSubTitle>
                                                </SBItemCenter>
                                            </SBItemLeft>

                                            <SBItemRight>
                                                <SBIcon src={Arrow.chevronright}></SBIcon>
                                            </SBItemRight>
                                        </BodyScrollBarItem>
                                    </>
                                ))}
                        </BodyScrollBarItemWrapper>
                    </AddSetBody>
                    <NavigationPickMuscle
                        onSave={(item) => {
                            setSelectedMenuTopic(item.id);
                            setNameExercise(item.name);
                        }}
                        currentIndex={currentIndex}
                    ></NavigationPickMuscle>
                </AddSetInner>
            </ModalAddSetWrapper>
        </>
    );
};

export default ModalAddSet;

const ResultsContainer = styled.div`
    max-height: 300px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    z-index: 999;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background: #505050;
    width: 100%;
    transition: all 0.2s ease;

    p {
        display: flex;
        align-items: center;
        padding: 12px 16px;
        cursor: pointer;
        color: #fff;
        font-size: 14px;
        margin: 0;
        background: #505050;
        &:hover {
            background: #636363;
        }
        img {
            margin-right: 8px;
            width: 24px;
            height: 24px;
        }
    }
`;

// Individual result item
const ResultItem = styled.div`
    cursor: pointer;
    color: #fff;
    font-size: 13px;
`;

const slideInFromTop = keyframes`
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateY(0);
    }
`;

const ModalAddSetWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    position: fixed;
    flex-direction: column-reverse;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
`;

const AddSetInner = styled.div`
    width: 100%;
    height: 100%;
    background-color: #3a3a3a;
    position: relative;
    background: #272727;
    animation: ${slideInFromTop} 0.3s ease-in-out;
    transition: all 0.4s ease;
`;

const AddSetHeader = styled.div`
    width: 100%;
    height: 7%;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
`;

const AddSetHeaderTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    line-height: normal;
`;

const AddSetHeaderTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    width: 100%;

    flex-shrink: 0;
`;
const IconBack = styled.div``;

const AddSetHeaderRight = styled.img`
    color: #ff5c00;
    text-align: right;
    font-family: "Be Vietnam Pro";
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const AddSetBody = styled.div`
    width: 100%;
    margin-top: 12px;
    height: 100%;
`;

const BodyScrollBarItemWrapper = styled.div`
    width: 100%;
    height: 80%;
    overflow-y: scroll;
`;

const BodyScrollBarItem = styled.div`
    display: flex;
    z-index: 1;
    width: 100%;
    position: relative;
    justify-content: space-between;
    border-bottom: 1px solid #3f3f3f;
    border-top: 1px solid #3f3f3f;
    padding: 12px 0px;
    position: relative;
    margin-top: ${(props) => (props.mt ? props.mt : "")};
`;

const SBItemLeft = styled.div`
    width: 80%;
    height: 84px;
    display: flex;
    flex-shrink: 0;
`;

const SBItemCenter = styled.div`
    margin-left: 10px;
`;

const SBItemCenterTitle = styled.h2`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    line-height: normal;
`;

const SubTitleConfig = styled.h3`
    color: #939393;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const SBItemCenterSubTitle = styled(SubTitleConfig)`
    // Add your styles here
`;

const SBItemRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`;
const SBIcon = styled.img``;

const SBItemLeftWrapper = styled.div`
    width: 85px;
    position: relative;
    height: 84px;

    flex-shrink: 0;
    background-image: ${(props) => `url(${props.src})`};
    background-repeat: no-repeat;
`;

const LeftTitleWrapper = styled.div`
    width: 100%;
    height: 45px;
    position: absolute;
    bottom: 0;
    flex-shrink: 0;
    border-radius: 12px;
    background: ${(props) => (props.bg ? props.bg : "")};
    display: flex;
    align-items: flex-end;
    justify-content: center;
`;

const LeftTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    margin-bottom: 4px;
    font-weight: 500;
    line-height: normal;
`;

const AddSetFooter = styled.div`
    width: 100%;

    position: fixed;
    flex-direction: column;
    bottom: 0;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(39, 39, 39, 0.8);
`;
const ButtonSave = styled.button`
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    flex-shrink: 0;
    border-radius: 50px;
    border: 1px solid rgba(184, 229, 255, 0.2);
    background: linear-gradient(268deg, #0352fe 0.45%, #0060ff 97.59%);
    box-shadow: 0px 0px 15px 0px rgba(3, 82, 254, 0.4);
    color: #fff;

    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const AddSetHeaderRightWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const AddSetHeaderRightTitle = styled.h3`
    overflow: hidden;
    color: #fff;
    text-overflow: ellipsis;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const ActionLeft = styled.div`
    display: flex;
`;
const ActionRight = styled.div`
    display: flex;
`;
const AIcon = styled.img`
    margin-right: 12px;
`;
const TitleLeft = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;
const CSHRTWapper = styled.div`
    display: inline-flex;
    height: 32px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 30px;
    border: 1px solid rgba(3, 82, 254, 0.2);
    background: rgba(3, 82, 254, 0.2);
`;
const SBIconLeftWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
`;
const SBIconLeft = styled.img`
    width: 18px;
    height: 18px;

    margin-right: 5px;
`;

const IconAdd = styled.img`
    position: absolute;
    left: 50%;
    right: 50%;
    top: -10px;
`;
const WrapperBodyScrollBarItem = styled.div`
    width: 100%;
    height: 100%;
    border: 1px solid #3f3f3f;
    opacity: 0.2;
    position: absolute;
    background: ${(props) => (props.bg ? props.bg : "")};
    top: 0;
`;

const IconSave = styled.img`
    margin-right: 10px;
`;

const ButtonTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const ButtonAdd = styled.button`
    display: inline-flex;
    height: 42px;

    padding: 14px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 30px;
    border: 1px solid #484848;
    background: #3a3a3a;
`;

const IconAddButton = styled.img``;

const ButtonTop = styled.div`
    margin-bottom: 10px;
`;

const ButtonBottom = styled.div`
    width: 100%;
    height: 50%;
    display: flex;

    justify-content: center;
    align-items: center;
    padding: 15px 0px;
    background: rgba(39, 39, 39, 0.8);
    box-shadow: 0px -1px 0px 0px #3f3f3f;
    backdrop-filter: blur(4px);
`;
const IconClose = styled.img``;

const AddSetContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    position: relative;
    z-index: 999;
    background: rgba(39, 39, 39, 0.6);
    box-shadow: 0px 1px 0px 0px #3f3f3f;
    backdrop-filter: blur(4px);
    padding-bottom: 15px;
`;

const ContentLeftWrapper = styled.div`
    display: flex;
    width: 72%;
    position: relative;
    justify-content: space-between;
`;

const Input = styled.input`
    width: 100%;
    height: 42px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #484848;
    border: none;
    padding-left: 10px;
    color: white;
`;

const IconSearch = styled.img`
    position: absolute;
    right: 3%;
    top: 7px;
`;

const ContentRightWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const IconTick = styled.img`
    // Add your styles here
`;

const Title = styled.h3`
    color: #ff5c00;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

const BodyTitleWrapper = styled.div`
    display: flex;
    margin-top: 12px;
    margin-left: 12px;
    align-items: center;
`;

const BodyTitle = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-right: 5px;
`;

const BodySubTitle = styled.span`
    color: #939393;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const IconCancel = styled.div`
    position: absolute;
    right: 15%;
    top: 5px;
    img {
        opacity: 0.5;
    }
`;
