import React, { useContext, useEffect, useState } from "react";
import cookie from "react-cookies";
import Common from "../based/Common";
import { CONSTANTS, NOTIFY, SOCIAL } from "../based/Constants";
import LoadingR from "../based/LoadingR";
import { Notify } from "../based/Notify";
import RequestEcom from "../based/RequestEcom";
import AccountServices from "../based/services/AccountServices";
import Loading from "../based/Loading";
import { AuthContext } from "../context/AuthContext";
function LoginCallback(props) {
    const authContext = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    // const history = useNavigate();

    useEffect(() => {
        //kiểm tra tài khoản có token chưa
        //nếu có rồi thì chuyển về trang chủ, không cho login nữa
        //nếu chưa có thì là callback của các hệ thống khác
        var token = Common.GetToken();
        if (token) {
            window.location.href = "/";
        } else {
            loginWithZaloTiktok();
            loginWithFacebook();
            loginWithGoogle();
        }
    }, []);

    const loginWithZaloTiktok = async () => {
        setIsLoading(true);
        let url = window.location.href;
        let idx = url.indexOf("?");
        let param = `?`;
        let state = "";
        if (idx >= 0) {
            let params = url.slice(idx, url.length);
            let urlSearchParams = new URLSearchParams(params);
            state = urlSearchParams.get("state");
            param += `code=${urlSearchParams.get("code")}`;
            param += `&state=${state}`;
            param += `&code_challenge=${urlSearchParams.get("code_challenge")}`;
        }
        let platform = state.split("-")[0];
        let social = SOCIAL.Undefined;
        switch (platform) {
            case "zalo":
                social = SOCIAL.Zalo;
                break;
            case "tiktok":
                social = SOCIAL.Tiktok;
                break;
            default:
                break;
        }
        if (social == SOCIAL.Zalo || social == SOCIAL.Tiktok) {
            const [err, data] = await AccountServices.LoginSocial(param, social, Common.GetRUUID(), "https://ranus.vn");
            if (!err && data) {
                let res = JSON.parse(data);
                let today = new Date();
                let expireDay = new Date();
                expireDay.setDate(today.getDate() + 365);
                cookie.save("token", res.token, { path: "/", expires: expireDay });
                RequestEcom.UpdateInstance(res.token);
                // Save user info to local storage
                const auth = { ...res.user };
                authContext.login(auth);
                let redirect = localStorage.getItem(CONSTANTS.LOGIN_URL_CALLBACK);
                if (redirect && redirect.length > 0) redirect = redirect.replace("?redirect=", "");
                setIsLoading(false);
                // history.push(redirect ? Common.getAbsoluteUrl(redirect) : "/");
            } else {
                // history.push("/login");
            }
        }
        setIsLoading(false);
    };
    async function loginWithFacebook() {
        setIsLoading(true);
        let fullParamUrl = window.location.search;
        if (fullParamUrl && fullParamUrl.indexOf("code=") > -1 && fullParamUrl.indexOf("state=facebook") > -1) {
            let ruuid = Common.GetRUUID();
            let redirect = localStorage.getItem(CONSTANTS.LOGIN_URL_CALLBACK);
            if (redirect && redirect.length > 0) {
                redirect = redirect.replace("?redirect=", "");
            } else redirect = "/";
            const [err, data] = await AccountServices.LoginSocial(fullParamUrl, SOCIAL.Facebook, ruuid, redirect);
            if (!err && data) {
                let response = JSON.parse(data);
                if (response) {
                    const auth = { ...response.user };
                    let today = new Date();
                    let expireDay = new Date();
                    expireDay.setDate(today.getDate() + 365);
                    cookie.save("token", response.token, {
                        path: "/",
                        expires: expireDay,
                    });
                    authContext.login(auth);
                    localStorage.removeItem(CONSTANTS.LOGIN_URL_CALLBACK);
                    RequestEcom.UpdateInstance(response.token);
                    setIsLoading(false);
                    window.location.href = "/";
                } else Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
            } else {
                window.location.href = "/login";
            }
        }
        setIsLoading(false);
    }

    async function loginWithGoogle() {
        let fullParamUrl = window.location.search;
        if (fullParamUrl && fullParamUrl.indexOf("code=") > -1 && fullParamUrl.indexOf("google") > -1) {
            let ruuid = Common.GetRUUID();
            let redirect = localStorage.getItem(CONSTANTS.LOGIN_URL_CALLBACK);
            if (redirect && redirect.length > 0) {
                redirect = redirect.replace("?redirect=", "");
            } else redirect = "/";
            setIsLoading(true);
            const [err, data] = await AccountServices.LoginSocial(fullParamUrl, SOCIAL.Google, ruuid, redirect);
            if (!err && data) {
                let response = JSON.parse(data);
                if (response) {
                    const auth = { ...response.user };
                    let today = new Date();
                    let expireDay = new Date();
                    expireDay.setDate(today.getDate() + 365);
                    cookie.save("token", response.token, {
                        path: "/",
                        expires: expireDay,
                    });
                    RequestEcom.UpdateInstance(response.token);
                    authContext.login(auth);
                    let cartBuyNow = cookie.load("cartbuynow");
                    localStorage.removeItem(CONSTANTS.LOGIN_URL_CALLBACK);
                    // history.push(
                    //   cartBuyNow && cartBuyNow.length > 0
                    //     ? "/cart-new"
                    //     : Common.getAbsoluteUrl(response.redirect)
                    // );
                    authContext.login(auth);
                } else Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
                setIsLoading(false);
            } else {
                setIsLoading(false);
                if (err.data) Notify(NOTIFY.ERROR, "Lỗi", err.data);
                else Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
            }
        }
    }

    return (
        <div>
            <LoadingR fullScreen={true} isShow={isLoading} />
        </div>
    );
}

LoginCallback.propTypes = {};

export default LoginCallback;
