import { useEffect, useState } from "react";
import styled from "styled-components";
import { Shoulder, Abdomen, Arm, Leg, Back, Heart, Chest } from "./ConfigIconSvg";
import MuscleServices from "../based/services/MuscleService";

const NavigationPickMuscle = ({ onSave, currentIndex }) => {
    const [activeItem, setActiveItem] = useState(1);
    const [listMuscle, setListMuscle] = useState([]);

    const handleItemClick = (item) => {
        setActiveItem(item.orderNumber);
        onSave(item);
    };

    useEffect(() => {
        GetAllMuscle();
    }, []);

    useEffect(() => {
        setActiveItem(currentIndex);
    }, [currentIndex]);

    const GetAllMuscle = async () => {
        const [err, data] = await MuscleServices.GetAllMuscle();
        if (!err) {
            const listMuscleTemp = data;
            const listMuscleTempSort = listMuscleTemp.sort((a, b) => a.orderNumber - b.orderNumber);
            setListMuscle(listMuscleTempSort);
        }
    };

    // console.log(listMuscle);
    // 1. nguc 2. lung. 3 chan + dui 4. Vai + co 5. Tay. 6. Bung 7. Tim

    return (
        <>
            <NavigationWrapper>
                {listMuscle.map((item, index) => (
                    <NavigationItem onClick={() => handleItemClick(item)} active={activeItem === item.id}>
                        <NavigationIcon>
                            {/* <Abdomen active={activeItem === item.id} /> */}
                            {item.orderNumber === 1 ? (
                                <Chest active={activeItem === 1} />
                            ) : item.orderNumber === 2 ? (
                                <Back active={activeItem === 2} />
                            ) : item.orderNumber === 3 ? (
                                <Leg active={activeItem === 3} />
                            ) : item.orderNumber === 4 ? (
                                <Shoulder active={activeItem === 4} />
                            ) : item.orderNumber === 5 ? (
                                <Arm active={activeItem === 5} />
                            ) : item.orderNumber === 6 ? (
                                <Abdomen active={activeItem === 6} />
                            ) : (
                                <Heart active={activeItem === 7} />
                            )}
                            {/* <ImgIcon src={item.thumbnail} alt={item.name} style={{ width: "24px", height: "24px", fill: "red" }} /> */}
                        </NavigationIcon>
                        <NavigationTitle> {item.name}</NavigationTitle>
                    </NavigationItem>
                ))}
            </NavigationWrapper>
        </>
    );
};
export default NavigationPickMuscle;

const NavigationWrapper = styled.div`
    display: flex;
    position: fixed;
    bottom: 0;
    justify-content: space-between;
    width: 100vw;
    overflow: hidden;
    z-index: 2;
    padding-left: 10px;
    padding-right: 10px;
    height: 8%;

    background: rgba(39, 39, 39);
    box-shadow: 0px -1px 0px 0px #3f3f3f;
    backdrop-filter: blur(4px);
`;
const NavigationItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% / 7);
    margin-top: 10px;
`;

const NavigationIcon = styled.div`
    width: 24px;
    height: 24px;
    margin-bottom: 6px;
    flex-shrink: 0;
`;
const NavigationTitle = styled.h3`
    color: #878787;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const ImgIcon = styled.img``;
