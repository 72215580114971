import BaseServices from "./BaseServices";

const FolderPlanExerciseServices = {
    CreateUpdateFolderPlanExercise: async (model) => {
        return await BaseServices.Post(`/api/PlanExercise/create-update-folder-plan-exercise`, model);
    },

    GetFolderPlanExerciseById: async (id) => {
        return await BaseServices.Get(`/api/PlanExercise/get-folder-plan-exercise-by-id/${id}`);
    },

    GetListFolderByUserName: async () => {
        return await BaseServices.Get(`/api/PlanExercise/get-list-folder-plan-exercise-by-username`);
    },
    RemoveFolderPlanExerciseById: async (id) => {
        return await BaseServices.Post(`/api/PlanExercise/remove-folder-plan-exercise-by-id/${id}`);
    },
};

export default FolderPlanExerciseServices;
