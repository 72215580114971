import React, { Component } from "react";

class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: "100%",
            height: "100vh",
        };
    }

    render() {
        return (
            this.props.show && (
                <div className={this.props.fullScreen ? "loading-comp overlay" : "loading-comp"} ref={(ins) => (this.loadding = ins)} style={{ textAlign: "center" }}>
                    <div className="loading-content">
                        <h4 className="m-t-0 header-title" style={{ textAlign: "center", color: "#fff" }}>
                            {this.props.msg}
                        </h4>
                        <div className="sk-circle">
                            <div className="sk-circle1 sk-child"></div>
                            <div className="sk-circle2 sk-child"></div>
                            <div className="sk-circle3 sk-child"></div>
                            <div className="sk-circle4 sk-child"></div>
                            <div className="sk-circle5 sk-child"></div>
                            <div className="sk-circle6 sk-child"></div>
                            <div className="sk-circle7 sk-child"></div>
                            <div className="sk-circle8 sk-child"></div>
                            <div className="sk-circle9 sk-child"></div>
                            <div className="sk-circle10 sk-child"></div>
                            <div className="sk-circle11 sk-child"></div>
                            <div className="sk-circle12 sk-child"></div>
                        </div>
                    </div>
                </div>
            )
        );
    }
}

export default Loading;
