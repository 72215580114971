import { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Tick, IconCommon } from "../../based/IconShared";
import { jwtDecode } from "jwt-decode";
import { plan } from "../../based/FakeApi";
import cookies from "react-cookies";
import FolderPlanExerciseServices from "../../based/services/FolderPlanExerciseServices";
import ModalPlanOfMe from "./Modalplanofme";
import { Folder } from "../../based/ConfigIconSvg";
import ModalConfirm from "../ModalPickSet/ModalsSaveSet/ModalConfirm";

const ModalFolderOfUser = ({ isOpen, onClose, onSave }) => {
    const [isOpenModalPlanOfMe, setIsModalOpenPlanOfMe] = useState(false);
    const [selectedButtonPlan, setSelectedButtonPlan] = useState(0);
    const [folderOfUser, setFolderOfUser] = useState([]);
    const [folderPicker, setFolderPicker] = useState({});
    const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);
    const [itemRemove, setItemRemove] = useState({});
    const [isRemoveActive, setIsRemoveActive] = useState(false);

    useEffect(() => {
        GetFolderByUser();
    }, [isOpen]);

    async function GetFolderByUser() {
        const [err, data] = await FolderPlanExerciseServices.GetListFolderByUserName();
        if (!err) {
            setFolderOfUser(data);
        } else {
            console.log(err);
        }
    }

    const handleSave = (item) => {
        onSave({
            id: item.id,
            name: item.name,
        });
    };

    const handleOpenModalPlanOfMe = (item) => {
        setFolderPicker(item);
        setSelectedButtonPlan(item.id);

        setIsModalOpenPlanOfMe(true);
    };

    const handleCloseModalPlanOfMe = () => {
        setIsModalOpenPlanOfMe(false);
    };

    const handleSaveDataPlanOfMe = (data) => {
        setIsModalOpenPlanOfMe(false);
        handleSave(data);
    };

    const handleOpenModalConfirm = (item) => {
        setItemRemove(item);
        setIsOpenModalConfirm(true);
    };

    const handleCloseModalConfirm = () => {
        setIsOpenModalConfirm(false);
    };

    const handleSaveModalConfirm = (data) => {
        setIsOpenModalConfirm(false);
        if (data === "success") {
            const userName = cookies.load("userName");
            GetFolderByUser(userName);
        }
    };

    return (
        <>
            <ModalWrapper style={{ display: isOpen ? "flex" : "none" }}>
                <ModalPlanOfMe isOpen={isOpenModalPlanOfMe} onClose={handleCloseModalPlanOfMe} onSave={handleSaveDataPlanOfMe} FolderPicker={folderPicker}></ModalPlanOfMe>;
                <ModalConfirm isOpen={isOpenModalConfirm} onClose={handleCloseModalConfirm} onSave={handleSaveModalConfirm} item={itemRemove}></ModalConfirm>
                <ModalInner>
                    <ModalHeader>
                        <HeaderTitle>Buổi tập của tôi</HeaderTitle>
                        <ModalClose onClick={onClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M18 6L6 18" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                <path d="M18 18L6 6" stroke="white" strokeWidth="2" strokeLinecap="round" />
                            </svg>
                        </ModalClose>
                    </ModalHeader>
                    <FolderScroll>
                        {folderOfUser && folderOfUser.length > 0 ? (
                            folderOfUser.map((item, index) => (
                                <ButtonChoosePlan
                                    key={item.id} // Make sure to provide a unique key for each item in a list
                                    isSelected={item.id === selectedButtonPlan ? "True" : "False"}
                                >
                                    <LeftItemWrapper onClick={() => handleOpenModalPlanOfMe(item)}>
                                        <IconLeftPlan>
                                            <Folder /> {/* Assuming Folder is a component for displaying a folder icon */}
                                        </IconLeftPlan>
                                        <TitlePlan>{item.name}</TitlePlan>
                                    </LeftItemWrapper>
                                    <IconRightPlan onClick={() => handleOpenModalConfirm(item)}>
                                        <img src={IconCommon.recycle} alt="Recycle icon" />
                                    </IconRightPlan>
                                </ButtonChoosePlan>
                            ))
                        ) : (
                            <TitleEmpty>Bạn chưa có kế hoạch nào được tạo ! </TitleEmpty>
                        )}
                    </FolderScroll>

                    <FooterWrapper>
                        {/* <ButtonFooter onClick={handleSave}>Lưu</ButtonFooter>
            <ButtonFooter onClick={onClose}>Hủy</ButtonFooter> */}
                    </FooterWrapper>
                </ModalInner>
            </ModalWrapper>
        </>
    );
};

export default ModalFolderOfUser;

const slideInFromTop = keyframes`
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
`;
const ModalWrapper = styled.div`
    position: absolute;
    bottom: 0;
    flex-direction: column-reverse;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 2;
`;

const ModalInner = styled.div`
    width: 100%;
    max-height: 70%;
    overflow-y: scroll;
    height: 70%;
    justify-content: center;

    flex-wrap: wrap;
    border-radius: 16px;
    background: #272727;
    position: absolute;
    animation: ${slideInFromTop} 0.4s ease forwards;
    transition: 0.4s all;
`;

const ModalHeader = styled.div`
    width: 100%;
    height: 56px;

    justify-content: center;
    display: flex;
    align-items: center;
    background: rgba(39, 39, 39, 0.6);
    box-shadow: 0px 1px 0px 0px #3f3f3f;
    backdrop-filter: blur(4px);
`;

const HeaderTitle = styled.h2`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

const ModalClose = styled.button`
    background-color: transparent;
    position: absolute;
    right: 10px;
    border: none;
    cursor: pointer;
`;

const Title = styled.h3`
    font-size: 16px;
    margin-bottom: 10px;
`;

const Input = styled.input`
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
`;

const FooterWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
`;

const ButtonChoosePlan = styled.div`
    width: 100%;
    height: 52px;
    padding: 0px 10px 0px 10px;
    flex-shrink: 0;
    border: 1px solid #484848;
    background: ${(props) => (props.isSelected === "True" ? "#2c364c" : "#2F2F2F")};
    /* opacity: ${(props) => (props.isSelected === "True" ? "0.2" : "1")}; */
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const IconLeftPlan = styled.div`
    margin-right: 10px;
`;
const LeftItemWrapper = styled.div`
    display: flex;
    width: 90%;
    align-items: center;
`;
const TitlePlan = styled.h3`
    overflow: hidden;
    color: #fff;
    text-overflow: ellipsis;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const IconRightPlan = styled.div`
    width: 10%;
    display: flex;
    justify-content: flex-end;
`;
const TitleEmpty = styled.p`
    color: #878787;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-items: center;
    text-align: center;
    margin-top: 12px;
`;
const FolderScroll = styled.div`
    height: 75%;

    overflow-y: scroll;
`;
