import React, { useContext, useEffect, useState } from "react";
import cookie from "react-cookies";
import { AuthContext } from "../../context/AuthContext";
import Loading from "../Loading";
import AccountServices from "../services/AccountServices";
import { CONSTANTS, NOTIFY, SOCIAL } from "../../based/Constants";
import { Notify } from "../../based/Notify";
var PropTypes = require("prop-types");
const FacebookButton = (props) => {
    const [isLoading, setLoading] = useState(false);
    const [msg, setMsg] = useState("");
    const authContext = useContext(AuthContext);
    useEffect(() => {
        loginWithFacebook();
    }, []);
    async function loginWithFacebook() {
        let fullParamUrl = window.location.search;
        if (fullParamUrl && fullParamUrl.indexOf("code=") > -1 && fullParamUrl.indexOf("state=facebook") > -1) {
            setLoading(true);
            setMsg("Đang đăng nhập...");
            const [err, data] = await AccountServices.LoginSocial(fullParamUrl, SOCIAL.Facebook);
            if (!err && data) {
                let response = JSON.parse(data);
                if (response) {
                    authContext.login({
                        facebookUserId: response.facebookUserId,
                        email: response.user.Email,
                    });
                    let today = new Date();
                    let expireDay = new Date();
                    expireDay.setDate(today.getDate() + 365);
                    cookie.save("token", response.token, {
                        path: "/",
                        expires: expireDay,
                    });
                    window.location.href = "/";
                } else Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
                setLoading(false);
                setMsg("");
            } else {
                setLoading(false);
                setMsg("");
                if (err.data) Notify(NOTIFY.ERROR, "Lỗi", err.data);
                else Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
            }
        }
    }

    async function _handleRenderDialog() {
        setLoading(true);
        setMsg("Đang đăng nhập...");
        const [err, data] = await AccountServices.OAuthDialogSocial(SOCIAL.Facebook);
        if (!err && data) {
            window.location.href = data;
            setLoading(false);
            setMsg("");
        } else {
            Notify(NOTIFY.ERROR, "Lỗi", CONSTANTS.MSG_ERROR);
            setLoading(false);
            setMsg("");
        }
    }
    return (
        <React.Fragment>
            <Loading show={isLoading} fullScreen={true} msg={msg} />
            {/* <button
                className="btn-light-outline-dark hover_gray text-dark"
                style={{
                    display: "flex",
                    border: "0px",
                    justifyContent: "spaced",
                    alignItems: "center",
                }}
            ></button> */}
            <img onClick={() => _handleRenderDialog()} alt="FB login" style={{ marginRight: "5px" }} src="https://img.icons8.com/fluent/36/000000/facebook-new.png" />
        </React.Fragment>
    );
};

export default FacebookButton;

FacebookButton.propTypes = {
    redirect: PropTypes.string,
};
