import { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Tick, IconCommon } from "../../based/IconShared";
import PlanServices from "../../based/services/PLanServices";
import cookies from "react-cookies";
import { BackArrow, Folder } from "../../based/ConfigIconSvg";
import PlanExerciseServices from "../../based/services/PlanExerciseServices";
const ModalPlanOfMe = ({ isOpen, onClose, onSave, FolderPicker }) => {
    const [selectedButtonPlan, setSelectedButtonPlan] = useState(0);
    const [planOfUser, setPlanOfUser] = useState([]);
    const [planExerciseOfUser, setPlanExerciseOfUser] = useState([]);
    useEffect(() => {
        if (isOpen && FolderPicker && FolderPicker.id) {
            fetchData(FolderPicker.id);
        }
    }, [isOpen, FolderPicker]);

    async function fetchData(folderId) {
        try {
            const [err, data] = await PlanExerciseServices.GetListPlanExerciseByFolderId(folderId);
            if (!err) {
                setPlanExerciseOfUser(data);
            } else {
                console.error(err);
            }
        } catch (error) {
            console.error(error);
        }
    }
    const handleSave = (item) => {
        setSelectedButtonPlan(item.id);
        onSave({
            id: item.id,
            name: item.name,
        });
    };

    return (
        <>
            <ModalWrapper style={{ display: isOpen ? "flex" : "none" }}>
                <ModalInner>
                    <ModalHeader>
                        <IconBackArrow onClick={onClose}>
                            <BackArrow />
                        </IconBackArrow>
                        <HeaderTitle>Tạo kế hoạch mới</HeaderTitle>
                        <ModalClose onClick={onClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M18 6L6 18" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                <path d="M18 18L6 6" stroke="white" strokeWidth="2" strokeLinecap="round" />
                            </svg>
                        </ModalClose>
                    </ModalHeader>
                    <PlanOfMeScroll>
                        {planExerciseOfUser &&
                            planExerciseOfUser.map((item, index) => {
                                return (
                                    <>
                                        <ButtonChoosePlan
                                            isSelected={item.id === selectedButtonPlan ? "True" : "False"}
                                            onClick={() => {
                                                setSelectedButtonPlan(item.id);
                                                handleSave(item);
                                            }}
                                        >
                                            <LeftItemWrapper>
                                                <IconLeftPlan>
                                                    <img src={selectedButtonPlan === item.id ? Tick.tickdone : Tick.tick} alt="#" />
                                                </IconLeftPlan>
                                                <TitlePlan>{item.name}</TitlePlan>
                                            </LeftItemWrapper>
                                            <IconRightPlan>
                                                <img src={IconCommon.recycle} />
                                            </IconRightPlan>
                                        </ButtonChoosePlan>
                                    </>
                                );
                            })}
                    </PlanOfMeScroll>
                    <FooterWrapper>
                        {/* <ButtonFooter onClick={handleSave}>Lưu</ButtonFooter>
            <ButtonFooter onClick={onClose}>Hủy</ButtonFooter> */}
                    </FooterWrapper>
                </ModalInner>
            </ModalWrapper>
        </>
    );
};

export default ModalPlanOfMe;

const slideInFromTop = keyframes`
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
`;
const ModalWrapper = styled.div`
    position: absolute;
    bottom: 0;
    flex-direction: column-reverse;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 2;
`;

const ModalInner = styled.div`
    width: 100%;
    max-height: 70%;
    overflow-y: scroll;
    height: 70%;
    justify-content: center;

    flex-wrap: wrap;
    border-radius: 16px;
    background: #272727;
    position: relative;
    overflow-y: auto;
    animation: ${slideInFromTop} 0.4s ease forwards;
    transition: 0.4s all;
`;

const ModalHeader = styled.div`
    width: 100%;
    height: 56px;

    justify-content: center;
    display: flex;
    align-items: center;
    background: rgba(39, 39, 39, 0.6);
    box-shadow: 0px 1px 0px 0px #3f3f3f;
    backdrop-filter: blur(4px);
`;

const HeaderTitle = styled.h2`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

const ModalClose = styled.button`
    background-color: transparent;
    position: absolute;
    right: 10px;
    border: none;
    cursor: pointer;
`;

const Title = styled.h3`
    font-size: 16px;
    margin-bottom: 10px;
`;

const Input = styled.input`
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
`;

const FooterWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
`;

const ButtonChoosePlan = styled.div`
    width: 100%;
    height: 52px;
    padding: 0px 10px 0px 10px;
    flex-shrink: 0;
    border: 1px solid #484848;
    background: ${(props) => (props.isSelected === "True" ? "#2c364c" : "#2F2F2F")};
    /* opacity: ${(props) => (props.isSelected === "True" ? "0.2" : "1")}; */
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const IconLeftPlan = styled.div`
    margin-right: 10px;
`;
const LeftItemWrapper = styled.div`
    display: flex;
    align-items: center;
`;
const TitlePlan = styled.h3`
    overflow: hidden;
    color: #fff;
    text-overflow: ellipsis;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const IconRightPlan = styled.div``;
const IconBackArrow = styled.div`
    position: absolute;
    left: 15px;
    top: 15px;
`;
const PlanOfMeScroll = styled.div`
    height: 75%;

    overflow-y: scroll;
`;
