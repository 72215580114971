import React from "react";
import styled from "styled-components";
const SelectComponent = ({ defaultValue, options, value, onChange }) => {
    return (
        <SelectorWrapper>
            <StyledSelect
                value={value ? value.value : ""}
                onChange={(e) => {
                    const selectedId = e.target.value;

                    const selectedOption = options.find((option) => option.id === selectedId);

                    if (selectedOption) {
                        onChange(selectedOption);
                    } else {
                        onChange({ id: "", name: "" });
                    }
                }}
            >
                <option value={defaultValue.id}>{defaultValue}</option>
                {options.map((option) => (
                    <option key={option.id} value={option.id}>
                        {option.name}
                    </option>
                ))}
            </StyledSelect>
        </SelectorWrapper>
    );
};
const SelectorWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    ::before,
    ::after {
        --size: 0.3rem;
        content: "";
        position: absolute;
        right: 1rem;
        pointer-events: none;
        color: #fff;
    }
    ::before {
        border-left: var(--size) solid transparent;
        border-right: var(--size) solid transparent;
        border-bottom: var(--size) solid #fff;
        color: #fff;

        top: 40%;
    }
    ::after {
        border-left: var(--size) solid transparent;
        border-right: var(--size) solid transparent;
        border-top: var(--size) solid #fff;
        top: 55%;
    }
`;

const StyledSelect = styled.select`
    border-radius: 12px;
    background: #2f2f2f;
    width: 100%;
    height: 100%;
    color: #fff;
    cursor: pointer;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 12px;
    &:focus {
        border-color: #007bff;
        outline: none;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    &:hover {
        border-color: #0056b3;
    }
    option {
        width: 100%;
        border-radius: 12px;
        background: #2f2f2f;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
        color: #fff;
        font-family: "Be Vietnam Pro";
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    option:hover {
    }

    /* Điều chỉnh cho option đang được chọn */
    option:checked {
    }
`;

export default SelectComponent;
