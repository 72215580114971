import styled from "styled-components";
import { muscleIcons, IconCommon } from "../based/IconShared";
import { useState, useEffect } from "react";
import useStore from "../based/store/useStore";
import PlanServices from "../based/services/PLanServices";

const ModalChangeMuscle = ({ isOpen, onClose, onSave, muscleForDay, item }) => {
    const [state, dispatch] = useStore();
    const [listMuscle, setListMuscle] = useState([]);
    const [currentMuscle, setCurrentMuscle] = useState([]);

    useEffect(() => {
        setListMuscle(state.muscleList);
        setCurrentMuscle(muscleForDay);
    }, [isOpen]);

    const handleChangeMuscle = (item) => {
        if (currentMuscle.some((muscle) => muscle.id === item.id)) {
            setCurrentMuscle(currentMuscle.filter((muscle) => muscle.id !== item.id));
        } else {
            setCurrentMuscle([...currentMuscle, item]);
        }
    };

    const handleSave = (data) => {
        onSave(currentMuscle);
        onClose();
    };

    const handleClickChangeMuscle = async () => {
        const listMuscle = currentMuscle.map((item) => item.id);
        const formData = {
            planId: item.id,
            daysOfWeek: item.day_id,
            muscleId: listMuscle,
        };
        console.log(formData);
        const [err, data] = await PlanServices.UpdateListMuscleOneDay(formData);
        if (!err) {
            console.log(data);
            handleSave(data);
        } else {
            console.log(err);
        }
    };

    return (
        <>
            <ModalChangeWrapper style={{ display: isOpen ? "flex" : "none" }}>
                <ModalWrapperInner>
                    <ModalHeader>
                        <HeaderTitle>Điều chỉnh nhóm cơ</HeaderTitle>
                        <HeaderAction src={IconCommon.deleteicon} onClick={onClose}></HeaderAction>
                    </ModalHeader>
                    <ModalBody>
                        <BodyHeader>
                            <HeaderTitle>
                                Nhóm cơ
                                <SubTitle>(Được chọn nhiều)</SubTitle>
                            </HeaderTitle>
                        </BodyHeader>
                        <BodyItem>
                            {listMuscle.map((item) => {
                                return (
                                    <>
                                        <ItemWrapper bg={currentMuscle.some((muscle) => muscle.id === item.id) ? "linear-gradient(268deg, #0352FE 0.45%, #0060FF 97.59%);" : ""} onClick={() => handleChangeMuscle(item)}>
                                            <ItemIcon src={item.thumbnail}></ItemIcon>
                                            <ItemTitle>{item.name}</ItemTitle>
                                        </ItemWrapper>
                                    </>
                                );
                            })}
                        </BodyItem>
                    </ModalBody>
                    <ModalFooter>
                        <FooterActionWrapper>
                            <FooterAction>Hủy</FooterAction>
                            <FooterActionConfirm onClick={() => handleClickChangeMuscle()}>Xác nhận</FooterActionConfirm>
                        </FooterActionWrapper>
                    </ModalFooter>
                </ModalWrapperInner>
            </ModalChangeWrapper>
        </>
    );
};

export default ModalChangeMuscle;

const ModalChangeWrapper = styled.div`
    width: 100%;
    height: 100vh;
    top: 0;
    bottom: 0;
    display: flex;
    position: fixed;
    flex-direction: column-reverse;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
`;

const ModalWrapperInner = styled.div`
    width: 100%;
    height: 60%;
    padding-bottom: 25%;
    background-color: #3a3a3a;
    position: relative;
    border-radius: 16px 16px 0px 0px;
    background: #272727;
`;

const ModalHeader = styled.div`
    background: rgba(39, 39, 39, 0.6);
    box-shadow: 0px 1px 0px 0px #3f3f3f;
    height: 15%;
    backdrop-filter: blur(4px);
    display: flex;
    position: relative;
    align-items: center;

    justify-content: center;
`;

const HeaderTitle = styled.h3`
    color: #fff;

    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

const ModalBody = styled.div`
    padding: 12px;
    height: 80%;
`;

const BodyHeader = styled.div`
    margin-bottom: 12px;
`;

const BodyItem = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-height: 100%;
    height: auto;
    overflow-y: scroll;
`;

const ItemWrapper = styled.div`
    width: calc(100% / 4 - 12px);
    margin-right: 12px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 88px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid #484848;
    background: ${(props) => (props.bg ? props.bg : "#3a3a3a")};
`;

const ItemIcon = styled.img`
    width: 54px;
    height: 54px;
    flex-shrink: 0;
`;

const ItemTitle = styled.div`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const HeaderAction = styled.img`
    position: absolute;
    right: 10px;
    z-index: 2;
`;

const SubTitle = styled.span`
    margin-left: 10px;
    color: #727272;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const ModalFooter = styled.div`
    width: 100%;
    height: 10%;
    background: rgba(39, 39, 39, 0.8);
    box-shadow: 0px -1px 0px 0px #3f3f3f;
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 10px;
`;

const Button = styled.button`
    width: 40%;
    height: 42px;
    flex-shrink: 0;
    border-radius: 50px;
    border: 1px solid #484848;
    background: #3a3a3a;
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;
const FooterAction = styled(Button)`
    /* width: 40%;
  height: 42px;
  flex-shrink: 0;
  border-radius: 50px;
  border: 1px solid #484848;
  background: #3a3a3a; */
`;

const FooterActionWrapper = styled.div`
    width: 80%;
    align-items: center;
    height: 100%;
    display: flex;
    justify-content: space-around;
`;

const FooterActionConfirm = styled(Button)`
    border-radius: 50px;
    border: 1px solid rgba(184, 229, 255, 0.2);
    background: linear-gradient(268deg, #0352fe 0.45%, #0060ff 97.59%);
    box-shadow: 0px 0px 15px 0px rgba(3, 82, 254, 0.4);
`;
