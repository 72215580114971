import styled from "styled-components";
import { muscleIcons, IconCommon, Arrow } from "../../based/IconShared";
import { Tick, DropDown } from "../../based/ConfigIconSvg";
import { configPlace } from "../../based/FakeApi";
import ModalDetailsGymRoom from "./ModalDetailsGymRoom";
import PlaceServices from "../../based/services/PlaceServices";
import React, { useEffect, useState } from "react";
import SelectComponent from "../../based/refactor/SelectComponent";
import useDebounce from "../../based/hooks/useDebounce";
import { set } from "date-fns";
import Common from "../../based/Common";

const options = [
    { id: "chocolate", name: "Chocolate" },
    { id: "strawberry", name: "Strawberry" },
    { id: "vanilla", name: "Vanilla" },
];
const ModalDetailsPlace = ({ isOpen, onClose, onSave }) => {
    const [listPlace, setlistPlace] = useState([]);
    const [isOpenModalDetailsGymRoom, setIsOpenModalDetailsGymRoom] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const [results, setResults] = useState([]);

    const [selectedPlace, setSelectedPlace] = useState({
        province: "",
        district: "",
    });
    const [paging, setPaging] = useState({
        pageNumber: 1,
        pageSize: 15,
        keyword: "",
    });
    const [selectedLocation, setSelectedLocation] = useState({
        province: [],
        district: [],
    });
    const handleOpenModalDetailsGymRoom = () => {
        setIsOpenModalDetailsGymRoom(true);
    };

    const handleCloseModalDetailsGymRoom = () => {
        setIsOpenModalDetailsGymRoom(false);
    };

    const handleSaveModalDetailsGymRoom = () => {
        setIsOpenModalDetailsGymRoom(false);
    };

    useEffect(() => {
        _getPlace();
    }, []);

    const _getPlace = async () => {
        const [err, data] = await PlaceServices.GetListProvince();
        if (!err) {
            setSelectedLocation({
                ...selectedLocation,
                province: data,
            });
        }
    };

    const _selectProvince = async (item) => {
        setSelectedPlace({
            ...selectedPlace,
            province: item,
        });

        const [errProvince, dataProvince] = await PlaceServices.GetPlaceByProvince({
            ...paging,
            keyword: item.id,
        });
        if (!errProvince) {
            setlistPlace(dataProvince);
        }

        console.log(item);

        const [err, data] = await PlaceServices.GetListDistrict(item.id);
        if (!err) {
            setSelectedLocation({
                ...selectedLocation,
                district: data,
            });
        }
    };

    const _selectDistrict = async (item) => {
        const [err, data] = await PlaceServices.GetPlaceByDistrict({
            ...paging,
            keyword: item.id,
        });
        if (!err) {
            setlistPlace(data);
        }

        setSelectedPlace({
            ...selectedPlace,
            district: item,
        });
    };
    useEffect(() => {
        const fetchData = async () => {
            const [err, data] = await PlaceServices.SearchSuggestions({
                ...Common.Paging,
                keyword: debouncedSearchTerm,
            });
            if (!err) {
                setResults(data.listObjects);
            }
        };
        if (debouncedSearchTerm) {
            setIsSearching(true);
            fetchData();
        } else {
            setIsSearching(false);
            setResults([]);
        }
    }, [debouncedSearchTerm]);

    const _handleSearchKeyWord = async () => {
        const [err, data] = await PlaceServices.SearchSuggestions({
            ...Common.Paging,
            keyword: debouncedSearchTerm,
        });
        if (!err) {
            setSearchTerm("");
            setIsSearching(false);
            setlistPlace(data.listObjects);
        }
    };

    return (
        <>
            <ModalDetailsPlaceWrapper style={{ display: isOpen ? "flex" : "none" }}>
                <ModalWrapperInner>
                    <ModalDetailsGymRoom isOpen={isOpenModalDetailsGymRoom} onClose={handleCloseModalDetailsGymRoom} onSave={handleSaveModalDetailsGymRoom}></ModalDetailsGymRoom>
                    <ModalHeader>
                        <IconBack onClick={onClose} src={Arrow.chevronleft}></IconBack>
                        <HeaderTitle>Thêm phòng Gym</HeaderTitle>
                        <HeaderAction src={IconCommon.deleteicon} onClick={onClose}></HeaderAction>
                    </ModalHeader>
                    <ActionWrapper>
                        <ActionLeft>
                            {/* <TitleAction>Tỉnh/ Thành phố *</TitleAction> */}
                            <SelectComponent defaultValue="Tỉnh/Thành Phố *" options={selectedLocation.province} value={selectedPlace.province} onChange={(value) => _selectProvince(value)} />
                        </ActionLeft>
                        <ActionRight>
                            <SelectComponent defaultValue="Quận/Huyện *" options={selectedLocation.district} value={selectedPlace.district} onChange={(value) => _selectDistrict(value)} />
                        </ActionRight>
                    </ActionWrapper>
                    <FindWrapper>
                        <FindWrapper2>
                            <InputFind value={searchTerm} placeholder="Tìm kiếm phòng tập" onChange={(e) => setSearchTerm(e.target.value)}></InputFind>
                            <IconSearch onClick={() => _handleSearchKeyWord()} src={IconCommon.search}></IconSearch>

                            {isSearching ? (
                                <>
                                    <IconCancel
                                        onClick={() => {
                                            setSearchTerm("");
                                            setIsSearching(false);
                                        }}
                                    >
                                        <img src={IconCommon.deleteicon} alt="cancel" />
                                    </IconCancel>
                                    <ResultsContainer>
                                        {results &&
                                            results.map((result, index) => {
                                                return (
                                                    <p
                                                        key={index}
                                                        onClick={() => {
                                                            setlistPlace([result]);
                                                            setSearchTerm("");
                                                            setIsSearching(false);
                                                        }}
                                                    >
                                                        <img alt="#" src={result.thumbnail}></img>
                                                        <ResultItem>{result.name}</ResultItem>
                                                    </p>
                                                );
                                            })}
                                    </ResultsContainer>
                                </>
                            ) : null}
                        </FindWrapper2>
                    </FindWrapper>
                    <ModalBody>
                        <ScrollItemWrapper>
                            {listPlace &&
                                listPlace.map((item, index) => (
                                    <>
                                        <ItemWrapperScroll onClick={handleOpenModalDetailsGymRoom} key={index}>
                                            <ImagePlace bgImage={item.thumbnail}></ImagePlace>
                                            <TitlePlaceWrapper>
                                                <TitlePlace>{item.name}</TitlePlace>
                                                <ScoutDevice>34 Thiết bị tập</ScoutDevice>
                                            </TitlePlaceWrapper>
                                        </ItemWrapperScroll>
                                    </>
                                ))}
                        </ScrollItemWrapper>
                    </ModalBody>
                </ModalWrapperInner>
            </ModalDetailsPlaceWrapper>
        </>
    );
};

export default ModalDetailsPlace;

const ModalDetailsPlaceWrapper = styled.div`
    width: 100%;
    height: 100vh;
    top: 0;
    display: flex;
    position: fixed;
    justify-content: center;
    z-index: 2;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(4px);
`;

const ModalWrapperInner = styled.div`
    width: 100%;
    margin: 0 auto;
    height: 100vh;

    background: #272727;
    position: fixed;
    top: 0;

    border-radius: 16px;
`;

const ModalHeader = styled.div`
    height: 6%;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    background: rgba(39, 39, 39, 0.6);
    box-shadow: 0px 1px 0px 0px #3f3f3f;
`;

const HeaderTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

const ModalBody = styled.div`
    height: auto;
    width: 100%;
    margin-top: 20px;
    overflow-y: scroll;
    max-height: 67%;
`;

const HeaderAction = styled.img`
    margin-right: 10px;
`;

const ModalScrollBody = styled.div`
    height: 100%;
    overflow-y: scroll;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;

const ItemWrapperScroll = styled.div`
    padding: 0 12px;
    width: 48%;
    margin-bottom: 12px;
    height: auto;
`;

const ItemLeft = styled.div`
    display: flex;
    align-items: center;
`;

const ActionChoose = styled.div``;

const ImagePlace = styled.div`
    width: 100%;
    height: 103px;
    border-radius: 8px;
    border: 1px solid #484848;

    background-image: url(${(props) => props.bgImage});
    background-size: cover;
`;

const TitlePlaceWrapper = styled.div``;
const TitlePlace = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;
const ScoutDevice = styled.h3`
    color: #939393;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const ItemRight = styled.img`
    width: 16.515px;
    height: 19.6px;
    flex-shrink: 0;
`;

const IconBack = styled.img`
    margin-left: 12px;
`;

const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 18px 0px;
`;

const configActionLeftRight = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 182px;
    height: 42px;
    border-radius: 12px;
    background: #2f2f2f;
    flex-shrink: 0;
`;
const ActionLeft = styled(configActionLeftRight)``;
const ActionRight = styled(configActionLeftRight)``;

const FindWrapper = styled.div`
    width: 100%;
    height: 5%;
    display: flex;
    justify-content: center;
`;

const InputFind = styled.input`
    width: 100%;
    color: #fff;
    border-radius: 12px;
    background: #2f2f2f;
    border: none;
    padding: 0 19px;
    height: 100%;
`;

const TitleAction = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const IconAction = styled.div``;
const FindWrapper2 = styled.div`
    width: 90%;
    position: relative;
    margin: 0 auto;
`;

const IconSearch = styled.img`
    position: absolute;
    top: 8px;
    right: 10px;
`;
const ScrollItemWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: scroll;
    /* justify-content:space-around; */
    display: flex;
    flex-wrap: wrap;
`;

const IconCancel = styled.div`
    position: absolute;
    right: 15%;
    top: 5px;
    img {
        opacity: 0.5;
    }
`;

const ResultsContainer = styled.div`
    max-height: 300px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    z-index: 999;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background: #505050;
    width: 100%;
    transition: all 0.2s ease;

    p {
        display: flex;
        align-items: center;
        padding: 12px 16px;
        cursor: pointer;
        color: #fff;
        font-size: 14px;
        margin: 0;
        background: #505050;
        &:hover {
            background: #636363;
        }
        img {
            margin-right: 8px;
            width: 24px;
            height: 24px;
        }
    }
`;
const ResultItem = styled.div`
    cursor: pointer;
    color: #fff;
    font-size: 13px;
`;
