import BaseServices from "./BaseServices";

const PlanServices = {
    CreateUpdatePlan: async (model) => {
        return await BaseServices.Post(`/api/Planning/create-update-plan`, model);
    },
    CreateUpdatePlanResponseId: async (model) => {
        return await BaseServices.Post(`/api/Planning/create-update-plan-response-id`, model);
    },

    CreatePlanForUserByPlanAdmin: async (model) => {
        return await BaseServices.Post(`/api/Planning/create-plan-for-user-by-plan-admin`, model);
    },
    GetPlanById: async (id) => {
        return await BaseServices.Get(`/api/Planning/get-plan-by-id/${id}`);
    },
    GetPlanByUserName: async () => {
        return await BaseServices.Get(`/api/Planning/get-list-plan-by-user-name`);
    },
    GetListPlanAdmin: async () => {
        return await BaseServices.Get(`/api/Planning/get-list-plan-admin`);
    },
    GetPlanByPaging: async (paging) => {
        return await BaseServices.Post(`/api/Planning/get-list-plan-by-paging`, paging);
    },
    GetAllPlan: async () => {
        return await BaseServices.Get(`/api/Planning/get-all-plan`);
    },
    RemovePlanById: async (id) => {
        return await BaseServices.Post(`/api/Planning/remove-plan-by-id/${id}`);
    },

    // plan history services
    CreateUpdatePlanHistory: async (model) => {
        return await BaseServices.Post(`/api/Planning/create-update-plan-history`, model);
    },
    GetListPlanHistoryByUserId: async () => {
        return await BaseServices.Get(`/api/Planning/get-list-plan-history-by-user-id`);
    },
    GetListPlanHistoryByUserIdAndWorkingDate: async (workingDate) => {
        return await BaseServices.Get(`/api/Planning/get-list-plan-history-by-user-id-and-working-date?workingDate=${workingDate}`);
    },
    GetListPlanHistoryByMonth: async (dateTimeJson) => {
        return await BaseServices.Get(`/api/Planning/get-list-plan-history-by-month?dateTimeJson=${dateTimeJson}`);
    },
    GetPlanHistoryCurrentDay: async () => {
        return await BaseServices.Get(`/api/Planning/get-plan-history-current-day-by-user-id`);
    },

    // plan history exercise

    CreateUpdatePlanHistoryExerciseWithListExercise: async (model) => {
        return await BaseServices.Post(`/api/PlanHistoryExercise/create-update-plan-history-with-list-exercise`, model);
    },
    GetListExerciseByPlanHistoryId: async (id) => {
        return await BaseServices.Get(`/api/PlanHistoryExercise/get-exercise-by-plan-history-id/${id}`);
    },
    GetPlanHistoryExerciseById: async (id) => {
        return await BaseServices.Get(`/api/PlanHistoryExercise/get-plan-history-exercise-by-id/${id}`);
    },
    UpdateIsDonePlanHistoryExercise: async (id, isDone) => {
        return await BaseServices.Post(`/api/PlanHistoryExercise/update-is-done-plan-history-exercise/${id}?isDone=${isDone}`);
    },

    // plan muscle detail services
    GetListMuscleByPlanId: async (id) => {
        return await BaseServices.Get(`/api/PLanMuscleDetail/get-list-muscle-by-plan-id/${id}`);
    },
    GetListMuscleByPlanIdAndDaysOfWeek: async (id, daysOfWeek) => {
        return await BaseServices.Get(`/api/PLanMuscleDetail/get-list-muscle-by-plan-id-and-days-of-week?planId=${id}&daysOfWeek=${daysOfWeek}`);
    },
    CreateUpdatePlanMuscleDetail: async (model) => {
        return await BaseServices.Post(`/api/PLanMuscleDetail/create-update-plan-muscle-detail`, model);
    },

    // plan sport detail services
    CreateUpdatePlanSportDetail: async (model) => {
        return await BaseServices.Post(`/api/PlanSportDetail/create-update-plan-sport-detail`, model);
    },
    GetListSportByPlanId: async (id) => {
        return await BaseServices.Get(`/api/PlanSportDetail/get-list-sport-by-plan-id/${id}`);
    },
    GetListSportByPlanIdAndDaysOfWeek: async (id, daysOfWeek) => {
        return await BaseServices.Get(`/api/PlanSportDetail/get-list-sport-by-plan-id-and-days-of-week?planId=${id}&daysOfWeek=${daysOfWeek}`);
    },
    RemovePlanMuscleDetailById: async (id) => {
        return await BaseServices.Post(`/api/PLanMuscleDetail/remove-plan-muscle-detail-by-id/${id}`);
    },
    RemovePlanSportDetailById: async (id) => {
        return await BaseServices.Post(`/api/PlanSportDetail/remove-plan-sport-detail-by-id/${id}`);
    },
    DeletePlanById: async (id) => {
        return await BaseServices.Get(`/api/Planning/delete-plan-by-id/${id}`);
    },

    CreateUpdatePlanMuscleDetailWithListMuscle: async (models) => {
        return await BaseServices.Post(`/api/PLanMuscleDetail/create-update-plan-muscle-detail-with-list-muscle?PlanId=${models.planId}&DaysOfWeek=${models.daysOfWeek}`, models.muscleId);
    },

    // plan exercise services
    CreateUpdatePlanExercise: async (model) => {
        return await BaseServices.Post(`/api/PlanExercise/create-update-plan-exercise`, model);
    },
    GetPlanExerciseByPlanId: async (id) => {
        return await BaseServices.Get(`/api/PlanExercise/get-list-exercise-by-plan-id/${id}`);
    },
    GetPlanExerciseByUserName: async (username) => {
        return await BaseServices.Get(`/api/PlanExercise/get-plan-exercise-by-username?username=${username}`);
    },
    GetListExerciseByPlanId: async (id) => {
        return await BaseServices.Get(`/api/PlanExercise/get-list-exercise-by-plan-exercise-id/${id}`);
    },
    CreateUpdatePlanExerciseDetail: async (model) => {
        return await BaseServices.Post(`/api/PlanExercise/create-update-plan-exercise-detail`, model);
    },
    GetExerciseByPlanExerciseId: async (id) => {
        return await BaseServices.Get(`/api/PlanExercise/get-exercise-by-plan-history-id/${id}`);
    },

    // plan exercise detail services
    CreateUpdateListPlanExerciseDetail: async (model) => {
        return await BaseServices.Post(`/api/PLanMuscleDetail/create-update-list-plan-muscle-detail`, model);
    },
    UpdateListMuscleOneDay: async (model) => {
        return await BaseServices.Post(`/api/PLanMuscleDetail/update-list-muscle-one-day-plan-muscle-detail`, model);
    },
};

export default PlanServices;
