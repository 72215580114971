import styled from "styled-components";
import { useEffect } from "react";
import FacebookButton from "../based/refactor/FacebookButton";
import Common from "../based/Common";
import BannerLogin from "../assets/banner-login.png";

const LoginPage = () => {
    useEffect(() => {
        //kiểm tra tài khoản có token chưa
        //nếu có rồi thì chuyển về trang chủ, không cho login nữa
        //nếu chưa có thì là callback của các hệ thống khác
        var token = Common.GetToken();
        if (token) {
            window.location.href = "/";
        }
    }, []);

    return (
        <>
            <LoginWrapper>
                <ImageLogin bg={BannerLogin}></ImageLogin>
                <Body>
                    <HeaderLogin>
                        <BodyTitle>Đăng nhập tài khoản bằng</BodyTitle>
                        <FacebookButton redirect={"/"} />
                    </HeaderLogin>
                    <DescriptionTitle>
                        Bằng việc đăng ký, bạn đã đồng ý với Gym Train về <span> Điều khoản dịch vụ </span> & <span>Chính sách bảo mật.</span>
                    </DescriptionTitle>
                </Body>
            </LoginWrapper>
        </>
    );
};

export default LoginPage;

const LoginWrapper = styled.div`
    width: 100%;
    height: 100vh;
    background: rgb(34, 34, 34, 0.8);
    position: relative;
`;

const ImageLogin = styled.div`
    width: 100%;
    height: 55%;
    flex-shrink: 0;

    position: absolute;
    background-image: ${(props) => (props.bg ? `url(${props.bg}) ` : "")};
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 70%;
`;

const Body = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    background-position-x: 77%;
    background: linear-gradient(to top, rgb(34, 34, 34, 1), rgb(39, 39, 39, 0.9), rgb(160, 160, 160, 0.1));
`;

const BodyTitle = styled.p`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const DescriptionTitle = styled.p`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.14px;
    padding: 0 10px;

    margin: 0 auto;
    margin-top: 30px;

    span {
        color: #ff5c00;
    }
`;

const HeaderLogin = styled.div`
    display: flex;
    margin-top: 45%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;
