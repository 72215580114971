import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import Provider from "./based/store/Provider";
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
const setViewport = () => {
    const viewportMeta = document.createElement("meta");
    viewportMeta.setAttribute("name", "viewport");
    viewportMeta.setAttribute("content", "width=device-width, initial-scale=1.0");
    document.head.appendChild(viewportMeta);
    const mobileWebAppMeta = document.createElement("meta");
    mobileWebAppMeta.setAttribute("name", "mobile-web-app-capable");
    mobileWebAppMeta.setAttribute("content", "yes");
    document.head.appendChild(mobileWebAppMeta);
    const appleMobileWebAppMeta = document.createElement("meta");
    appleMobileWebAppMeta.setAttribute("name", "apple-mobile-web-app-capable");
    appleMobileWebAppMeta.setAttribute("content", "yes");
    document.head.appendChild(appleMobileWebAppMeta);
};

root.render(
    <BrowserRouter basename={baseUrl}>
        <Provider>
            <App />
        </Provider>
    </BrowserRouter>
);

setViewport();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
