import styled from "styled-components";
import { muscleIcons, IconCommon, Arrow } from "../../based/IconShared";
import { useState } from "react";
import { listPlace } from "../../based/FakeApi";
import { Tick, DropDown, Device } from "../../based/ConfigIconSvg";

const DeviceFake = [
    {
        id: 1,
        name: "Thiết bị 1",
        count: 50,
    },
    {
        id: 2,
        name: "Thiết bị 2",
        count: 50,
    },
    {
        id: 3,
        name: "Thiết bị 3",
        count: 50,
    },
    {
        id: 4,
        name: "Thiết bị 4",
        count: 50,
    },
];

const ModalDetailsGymRoom = ({ isOpen, onClose, onSave }) => {
    const [listPlaceTemp, setListPlaceTemp] = useState(listPlace);
    const [selectedDeviceType, setSelectedDeviceType] = useState();

    return (
        <>
            <ModalDetailsGymRoomWrapper style={{ display: isOpen ? "flex" : "none" }}>
                <ModalWrapperInner>
                    <ModalHeader>
                        <IconBack onClick={onClose} src={Arrow.chevronleft}></IconBack>
                        <HeaderTitle>Thêm New Gym Tân Bình</HeaderTitle>
                        <HeaderAction src={IconCommon.deleteicon} onClick={onClose}></HeaderAction>
                    </ModalHeader>

                    <ModalBody>
                        <HeaderItemWrapper>
                            <ImagePlaceWrapper>
                                {listPlaceTemp
                                    .filter((item) => item.id === 1)
                                    .map((item, index) => (
                                        <>
                                            <ImagePlace bgImage={item.image}></ImagePlace>
                                        </>
                                    ))}
                            </ImagePlaceWrapper>
                            <TitlePlaceWrapper>
                                <ScoutDevice>123 Địa chỉ, Phường 12, quận Tân Bình, TP. HCM</ScoutDevice>
                                <ScoutDevice>Giờ hoạt động: 24/7</ScoutDevice>
                            </TitlePlaceWrapper>
                        </HeaderItemWrapper>
                        <DeviceTypeWrapper>
                            {DeviceFake.map((item, index) => (
                                <DeviceType bg={selectedDeviceType === index ? "rgba(3, 82, 254, 0.3)" : ""} onClick={() => setSelectedDeviceType(index)} key={index}>
                                    <IconDevice>
                                        <Device />
                                    </IconDevice>
                                    <DetailDevice>
                                        <TitleDevice>{item.name}</TitleDevice>
                                        <CountDevice>{item.count} thiết bị</CountDevice>
                                    </DetailDevice>
                                </DeviceType>
                            ))}
                        </DeviceTypeWrapper>
                        <ListItemWrapperScroll>
                            {Array.from({ length: 18 }).map((item, index) => (
                                <DeviceItemWrapper>
                                    {" "}
                                    <DeviceItem>
                                        <ImageDeviceItem src="https://s3-alpha-sig.figma.com/img/485f/133c/8383d7f11cdbdcf810a501c83e8fd1ba?Expires=1712534400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=biA2OoXO0EfN~XuUXS24I~eHglvNUX5IHzU2wFeUfKUCsGbqZQaGLL3Xe8R0992LPPTjigRmTmgdlXo3ZANgEFUukPJ~ZZz~X9sSA6EpoE2mrOJSYNIWjPtpEL98U3a5W684o6T2DcVpKZc7BwIfKnfW085wx7lfs59bBTqUXhIYz7nR6x68UVVHHxJlWv3yxHCHDjlkQNbz5Sgzhd4ghkkn0D8kXZQNBqtM2HZSfrI5Wdbsb5F9t9FX7CAc988Qs9oGmRw59oYaUxM3g5UpW7skpo0x2wLiPznwlkXcL-BBT2Bn3KxqDcgjnmQnFYhfxhCAwdF8sOscEDZiEX6vWA__"></ImageDeviceItem>
                                    </DeviceItem>
                                    <DeviceTitle>Máy chạy bộ Máy chạy bộ</DeviceTitle>
                                </DeviceItemWrapper>
                            ))}
                        </ListItemWrapperScroll>
                    </ModalBody>
                    <ModalFooter>
                        <FooterActionWrapper>
                            <FooterAction onClick={() => onClose()}>Hủy</FooterAction>
                            <FooterActionConfirm onClick={() => onClose()}>Xác nhận</FooterActionConfirm>
                        </FooterActionWrapper>
                    </ModalFooter>
                </ModalWrapperInner>
            </ModalDetailsGymRoomWrapper>
        </>
    );
};

export default ModalDetailsGymRoom;

const ModalDetailsGymRoomWrapper = styled.div`
    width: 100%;
    height: 100vh;
    bottom: 0;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: flex-end;
    z-index: 2;
    background: rgba(0, 0, 0, 0.8);
`;

const ModalWrapperInner = styled.div`
    width: 100%;
    margin: 0 auto;
    height: 100%;

    background: #272727;
    position: fixed;
    bottom: 0;

    border-radius: 16px;
`;

const ModalHeader = styled.div`
    height: 6%;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    background: rgba(39, 39, 39, 0.6);
    box-shadow: 0px 1px 0px 0px #3f3f3f;
`;

const HeaderTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

const ModalBody = styled.div`
    height: 80vh;
    position: fixed;
    width: 100vw;
`;

const HeaderAction = styled.img`
    margin-right: 10px;
`;

const ModalScrollBody = styled.div`
    height: 100%;
    overflow-y: scroll;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;

const ItemWrapperScroll = styled.div`
    padding: 0 12px;
    width: 48%;
    margin-bottom: 12px;
    height: auto;
`;

const ImagePlace = styled.div`
    width: 100%;
    height: 103px;
    border-radius: 8px;
    border: 1px solid #484848;

    background-image: url(${(props) => props.bgImage});
    background-size: cover;
`;

const TitlePlaceWrapper = styled.div`
    width: 50%;
    display: flex;
    padding: 10px;
    flex-direction: column;
`;
const TitlePlace = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;
const ScoutDevice = styled.h3`
    color: #939393;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const IconBack = styled.img`
    margin-left: 12px;
`;

const configActionLeftRight = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 182px;
    height: 42px;
    border-radius: 12px;
    background: #2f2f2f;
    flex-shrink: 0;
`;

const HeaderItemWrapper = styled.div`
    display: flex;
    width: 90%;
    margin: 0 auto;
    margin-top: 5%;
`;
const ImagePlaceWrapper = styled.div`
    width: 50%;
`;
const DeviceTypeWrapper = styled.div`
    width: 90%;
    margin: 0 auto;
    margin-top: 6%;
    margin-bottom: 6%;
    display: flex;
    overflow-x: scroll;
    padding: 12 0px;
`;
const DeviceType = styled.div`
    display: flex;
    width: 33%;
    margin-right: 2%;
    height: auto;
    max-height: 60px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid rgba(184, 229, 255, 0.2);
    background: ${(props) => (props.bg ? props.bg : "#3a3a3a")};
    padding: 6px 16px;
`;
const CountDevice = styled.p`
    color: rgba(184, 229, 255, 0.5);
    font-family: "Be Vietnam Pro";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
`;
const IconDevice = styled.div`
    width: 30%;
`;
const DetailDevice = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
`;

const TitleDevice = styled.p`
    color: #b8e5ff;
    margin: 0;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;
const ListItemWrapperScroll = styled.div`
    width: 100vw;
    padding: 0 12px;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    max-height: 80%;
    overflow: scroll;

    justify-content: space-between;
`;

const DeviceItem = styled.div`
    width: 100%;
`;

const DeviceItemWrapper = styled.div`
    width: 32%;
    padding: 6px;
    border-radius: 12px;
    border: 1px solid #484848;
    background: #4b4b4b;
    height: auto;
    margin-top: 12px;
    padding-bottom: 12px;
`;
const ImageDeviceItem = styled.img`
    width: 100%;
    border-radius: 8px;
`;
const DeviceTitle = styled.p`
    color: #d9d9d9;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    margin-top: 10px;
`;

const ModalFooter = styled.div`
    width: 100%;
    height: 10%;
    background: rgba(39, 39, 39, 0.8);
    box-shadow: 0px -1px 0px 0px #3f3f3f;
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    position: fixed;
`;
const FooterActionWrapper = styled.div`
    width: 80%;
    align-items: center;
    height: 100%;
    display: flex;
    justify-content: space-around;
`;
const Button = styled.button`
    width: 40%;
    height: 42px;
    flex-shrink: 0;
    border-radius: 50px;
    border: 1px solid #484848;
    background: #3a3a3a;
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;
const FooterAction = styled(Button)`
    /* width: 40%;
  height: 42px;
  flex-shrink: 0;
  border-radius: 50px;
  border: 1px solid #484848;
  background: #3a3a3a; */
`;

const FooterActionConfirm = styled(Button)`
    border-radius: 50px;
    border: 1px solid rgba(184, 229, 255, 0.2);
    background: linear-gradient(268deg, #0352fe 0.45%, #0060ff 97.59%);
    box-shadow: 0px 0px 15px 0px rgba(3, 82, 254, 0.4);
`;
