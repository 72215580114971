import BaseServices from "./BaseServices";

const UserServices = {
    CreateUserDetail: async (model) => {
        return await BaseServices.Post(`/api/User/create-update-user-detail`, model);
    },
    GetUserDetailByUserId: async () => {
        return await BaseServices.Get(`/api/User/get-user-detail-by-user-id`);
    },
    CheckExistUserDetail: async (id) => {
        return await BaseServices.Get(`/api/User/check-exist-user-detail/${id}`);
    },
    CreateUpdateCurrentPlanUser: async (id) => {
        return await BaseServices.Post(`/api/User/create-update-current-plan-for-user?id=${id}`);
    },
};

export default UserServices;
