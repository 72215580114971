import BaseServices from "./BaseServices";

const DeviceServices = {
    GetAllDeviceType: async () => {
        return await BaseServices.Get(`/api/Device/get-all-device-type`);
    },

    CreateUpdateDeviceType: async (model) => {
        return await BaseServices.Post(`/api/Device/create-update-device-type`, model);
    },

    RemoveDeviceTypeById: async (id) => {
        return await BaseServices.Get(`/api/Device/remove-device-type-by-id/${id}`);
    },
    GetAllDevice: async () => {
        return await BaseServices.Get(`/api/Device/get-all-device`);
    },
    GetDeviceById: async (id) => {
        return await BaseServices.Get(`/api/Device/get-device-by-id/${id}`);
    },
    GetMuscleByDeviceId: async (id) => {
        return await BaseServices.Get(`/api/Device/get-muscle-by-device-id/${id}`);
    },

    CreteUpdateDevice: async (model) => {
        return await BaseServices.Post(`/api/Device/create-update-device`, model);
    },
    CreateUpdateMuscleDeviceWithListMuscle: async (id, muscleId) => {
        return await BaseServices.Post(`/api/Device/create-update-muscle-device-detail-with-list-muscle/${id}`, muscleId);
    },

    RemoveDeviceById: async (id) => {
        return await BaseServices.Post(`/api/Device/remove-device-by-id/${id}`);
    },
    CreateUpdateMuscleDeviceDetail: async (model) => {
        return await BaseServices.Post(`/api/Device/create-update-muscle-device-detail`, model);
    },
    GetDeviceByName: async (name) => {
        return await BaseServices.Get(`/api/Device/get-device-by-name?name=${name}`);
    },
    GetAllMuscleDevice: async () => {
        return await BaseServices.Get(`/api/Device/get-all-muscle-device-detail`);
    },
    GetDevicePagingByKeyWord: async (model) => {
        return await BaseServices.Post(`/api/Device/get-device-paging-by-key-word`, model);
    },
    GetDeviceByPaging: async (model) => {
        return await BaseServices.Post(`/api/Device/get-list-device-by-paging`, model);
    },
};

export default DeviceServices;
