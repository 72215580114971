import React, { Component, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import cookies from "react-cookies";
import "./custom.css";

export default class App extends Component {
    static displayName = App.name;
    async componentDidMount() {
        function adjustHeight() {
            const viewportHeight = window.innerHeight + "px";
            document.documentElement.style.setProperty("--vh", viewportHeight);
        }

        adjustHeight();

        window.addEventListener("resize", adjustHeight);
    }
    render() {
        return (
            <Routes>
                {AppRoutes.map((route, index) => {
                    const { element, ...rest } = route;
                    return <Route key={index} {...rest} path={route.path} element={element} />;
                })}
            </Routes>
        );
    }
}
