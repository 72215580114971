import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Tick, IconCommon } from "../../based/IconShared";
import PlanServices from "../../based/services/PLanServices";
import cookies from "react-cookies";
const ModalPlanOfMe = ({ isOpen, onClose, onSave }) => {
    const [selectedButtonPlan, setSelectedButtonPlan] = useState(0);

    const [listPlanOfMe, setListPlanOfMe] = useState([]);
    // const [userName, setUserName] = useState("duchoaang.dev@gmail.com");
    const handleSave = async (item) => {
        onSave({
            id: item.id,
            name: item.name,
        });
        onClose();
    };

    const HandleGetListPlanOfMe = async () => {
        var userName = cookies.load("userName");
        const [err, data] = await PlanServices.GetPlanByUserName(userName);
        if (!err && data) {
            setListPlanOfMe(data);
        } else {
            console.log(err);
        }
    };

    async function handleDeletePlanById(id) {
        const currentPlan = JSON.parse(localStorage.getItem("currentPlan"));
        if (currentPlan) {
            if (currentPlan.id === id) {
                localStorage.removeItem("currentPlan");
            }
        }
        const [err, data] = await PlanServices.DeletePlanById(id);
        if (!err && data) {
            localStorage.removeItem("currentPlan");
            window.location.reload();
        } else {
            console.log(err);
        }
    }

    useEffect(() => {
        HandleGetListPlanOfMe();
    }, []);

    return (
        <>
            <ModalWrapper style={{ display: isOpen ? "block" : "none" }}>
                <ModalInner>
                    <ModalHeader>
                        <HeaderTitle>Tạo kế hoạch mới</HeaderTitle>
                        <ModalClose onClick={onClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M18 6L6 18" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                <path d="M18 18L6 6" stroke="white" strokeWidth="2" strokeLinecap="round" />
                            </svg>
                        </ModalClose>
                    </ModalHeader>
                    <PlanOfMeScroll>
                        {listPlanOfMe.map((item, index) => {
                            return (
                                <>
                                    <ButtonChoosePlan isSelected={item.id === selectedButtonPlan ? "True" : "False"}>
                                        <LeftItemWrapper onClick={() => handleSave(item)}>
                                            <IconLeftPlan>
                                                <img src={selectedButtonPlan == item.id ? Tick.tickdone : Tick.tick} />
                                            </IconLeftPlan>
                                            <TitlePlan>{item.name}</TitlePlan>
                                        </LeftItemWrapper>
                                        <IconRightPlan
                                            onClick={() => {
                                                handleDeletePlanById(item.id);
                                            }}
                                        >
                                            <img src={IconCommon.recycle} />
                                        </IconRightPlan>
                                    </ButtonChoosePlan>
                                </>
                            );
                        })}
                    </PlanOfMeScroll>

                    <FooterWrapper>
                        {/* <ButtonFooter onClick={handleSave}>Lưu</ButtonFooter>
            <ButtonFooter onClick={onClose}>Hủy</ButtonFooter> */}
                    </FooterWrapper>
                </ModalInner>
            </ModalWrapper>
        </>
    );
};

export default ModalPlanOfMe;
const slideInFromTop = keyframes`
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateX(0);
    }
`;
const ModalWrapper = styled.div`
    position: absolute;
    bottom: 0;

    width: 100%;
    height: 70%;
    z-index: 2;
`;

const ModalInner = styled.div`
    width: 100%;

    height: 100%;
    justify-content: center;

    flex-wrap: wrap;
    border-radius: 16px;
    background: #272727;
    position: relative;
    animation: ${slideInFromTop} 0.2s ease;
`;

const ModalHeader = styled.div`
    width: 100%;
    height: 56px;

    justify-content: center;
    display: flex;
    align-items: center;
    background: rgba(39, 39, 39, 0.6);
    box-shadow: 0px 1px 0px 0px #3f3f3f;
    backdrop-filter: blur(4px);
`;

const HeaderTitle = styled.h2`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

const ModalClose = styled.button`
    background-color: transparent;
    position: absolute;
    right: 10px;
    border: none;
    cursor: pointer;
`;

const Title = styled.h3`
    font-size: 16px;
    margin-bottom: 10px;
`;

const Input = styled.input`
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
`;

const FooterWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
`;

const ButtonChoosePlan = styled.div`
    width: 100%;
    height: 52px;
    padding: 0px 10px 0px 10px;
    flex-shrink: 0;
    border: 1px solid #484848;
    background: ${(props) => (props.isSelected === "True" ? "#2c364c" : "#2F2F2F")};
    /* opacity: ${(props) => (props.isSelected === "True" ? "0.2" : "1")}; */
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const IconLeftPlan = styled.div`
    margin-right: 10px;
`;
const LeftItemWrapper = styled.div`
    display: flex;
    align-items: center;
`;
const TitlePlan = styled.h3`
    margin-top: 8px;
    overflow: hidden;
    color: #fff;
    text-overflow: ellipsis;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const IconRightPlan = styled.div``;
const PlanOfMeScroll = styled.div`
    height: 75%;

    overflow-y: scroll;
`;
