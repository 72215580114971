import styled, { keyframes } from "styled-components";
import { Arrow } from "../../based/IconShared";
import { IconCommon } from "../../based/IconShared";
import { useEffect, useState } from "react";
import ModalSaveSet from "./ModalsSaveSet/ModalSaveSet";
import ModalAddSet from "./ModalAddSet";
import PlanExerciseServices from "../../based/services/PlanExerciseServices";
import ModalCommon from "../../based/ModalCommon";
import ExerciseServices from "../../based/services/ExerciseServices";
const configTypeExercise = {
    1: "Compound",
    2: "Isolation",
    3: "Superset",
};
const configSet = {
    1: "SingleSet",
    2: "SuperSet",
    3: "Tri-Set",
};

const ModalChangeSet = ({ isOpen, onClose, onSave, listExercise, PlanExercisePicker, fullListExercise }) => {
    const [selectedItems, setSelectedItems] = useState(1);
    const [isOpenModalAddSet, setIsOpenModalAddSet] = useState(false);
    const [isOpenModalSaveSet, setIsOpenModalSaveSet] = useState(false);
    const [listExerciseRecommendChangeSet, setListExerciseRecommendChangeSet] = useState([]);
    const [isOpenModalCommon, setIsOpenModalCommon] = useState(false);
    const [selectedConfigSet, setSelectedConfigSet] = useState(1);
    useEffect(() => {
        const listExerciseTemp = JSON.parse(JSON.stringify(listExercise));
        setListExerciseRecommendChangeSet(listExerciseTemp);
    }, [isOpen]);

    const handleOpenModalAddSet = () => {
        setIsOpenModalAddSet(true);
    };
    const handleCloseModalAddSet = () => {
        setIsOpenModalAddSet(false);
    };
    const handleSaveModalAddSet = (data) => {
        const listTemp = listExerciseRecommendChangeSet;
        data.map((item) => listTemp.push(item));
        setListExerciseRecommendChangeSet(listTemp);
        setIsOpenModalAddSet(false);
    };

    const handleOpenModalSaveSet = async () => {
        if (PlanExercisePicker.id != null) {
            var listExerciseId = listExerciseRecommendChangeSet.map((item) => item.id);
            var planId = PlanExercisePicker.id;
            const [err, data] = await PlanExerciseServices.UpdatePlanExerciseDetailByPlanExerciseAndListExerciseId(planId, listExerciseId);
            if (!err) {
                onSave(listExerciseRecommendChangeSet);
            } else {
                console.log(err);
            }
        } else {
            setIsOpenModalSaveSet(true);
        }
    };
    const handleCloseModalSaveSet = () => {
        setIsOpenModalSaveSet(false);
    };

    const handleSaveModalSaveSet = (item) => {
        setIsOpenModalSaveSet(false);
        if (item === "success") {
            onSave("success");
        }
    };

    const handleOpenModalCommon = () => {
        setIsOpenModalCommon(true);
    };

    const handleCloseModalCommon = () => {
        setIsOpenModalCommon(false);
    };

    const handleSaveModalCommon = (item) => {
        setIsOpenModalCommon(false);
        if (item === "success") {
            onClose();
        }
    };

    const _switchExercise = async (itemSwitch, index) => {
        var sameExercise = fullListExercise.filter((item) => item.exerciseTypeId === itemSwitch.exerciseTypeId && item.id !== itemSwitch.id);

        var randomExercise = sameExercise[Math.floor(Math.random() * sameExercise.length)];

        listExerciseRecommendChangeSet.splice(index, 1, randomExercise);
        setListExerciseRecommendChangeSet([...listExerciseRecommendChangeSet]);
        // setSelectedItems(randomExercise.id);
    };

    return (
        <>
            <ModalChangeSetWrapper style={{ display: isOpen ? "flex" : "none" }}>
                <ModalCommon
                    isOpen={isOpenModalCommon}
                    onClose={handleCloseModalCommon}
                    onSave={handleSaveModalCommon}
                    nameButton1="Quay lại"
                    nameButton2="Xác nhận"
                    title="Bạn đang chỉnh sửa bài tập. Bạn có chắc là bỏ bản chỉnh sửa này không?"
                ></ModalCommon>

                <ChangeSetInner>
                    <ModalSaveSet isOpen={isOpenModalSaveSet} onClose={handleCloseModalSaveSet} onSave={handleSaveModalSaveSet} listExerciseSave={listExerciseRecommendChangeSet}></ModalSaveSet>
                    <ChangeSetHeader>
                        <ChangeSetHeaderTop>
                            <IconBack src={Arrow.chevronleft} onClick={handleOpenModalCommon}></IconBack>
                            <ChangeSetHeaderTitle>Chỉnh sửa buổi tập </ChangeSetHeaderTitle>
                            <ChangeSetHeaderRightWrapper>
                                <CSHRTWapper bg={selectedConfigSet === 1 ? "rgba(151, 71, 255, 0.20);" : selectedConfigSet === 2 ? "rgba(3, 82, 254, 0.20);" : "rgba(245, 0, 44, 0.20);"}>
                                    <ChangeSetHeaderRightTitle onClick={() => setSelectedConfigSet((selectedConfigSet % 3) + 1)}>{configSet[selectedConfigSet]}</ChangeSetHeaderRightTitle>
                                </CSHRTWapper>
                            </ChangeSetHeaderRightWrapper>
                        </ChangeSetHeaderTop>
                    </ChangeSetHeader>
                    <ChangeSetBody>
                        <BodyScrollBarItemWrapper>
                            {listExerciseRecommendChangeSet &&
                                listExerciseRecommendChangeSet.length > 0 &&
                                listExerciseRecommendChangeSet.map((item, index) => (
                                    <>
                                        <BodyScrollBarItem borderTop={index === 0 ? "" : "1px solid #3f3f3f"} mt={index === 0 ? "10px" : ""} onClick={() => setSelectedItems(item.id)}>
                                            <WrapperBodyScrollBarItem bg={item.id === selectedItems ? "linear-gradient(90deg, #0352FE 0%, rgba(3, 82, 254, 0.00) 100%);" : " "}></WrapperBodyScrollBarItem>
                                            <IconAdd onClick={() => console.log(item)} src={IconCommon.add}></IconAdd>
                                            <SBItemLeft>
                                                <SBIconLeftWrapper>
                                                    <SBIconLeft src={IconCommon.details}></SBIconLeft>
                                                </SBIconLeftWrapper>
                                                <SBItemLeftWrapper src={item.thumbnail}>
                                                    <LeftTitleWrapper
                                                        bg={
                                                            item.exerciseTypeId === 1
                                                                ? " linear-gradient(0deg, #FF5C00 0%, rgba(255, 92, 0, 0.00) 100%)"
                                                                : item.exerciseTypeId === 2
                                                                ? "linear-gradient(0deg, #0352FE 0%, rgba(3, 82, 254, 0.00) 100%)"
                                                                : "linear-gradient(0deg, #F5002C 0%, rgba(245, 0, 44, 0.00) 100%);"
                                                        }
                                                    >
                                                        <LeftTitle>{configTypeExercise[item.exerciseTypeId]}</LeftTitle>
                                                    </LeftTitleWrapper>
                                                </SBItemLeftWrapper>
                                                <SBItemCenter>
                                                    <SBItemCenterTitle>{item.name}</SBItemCenterTitle>

                                                    <SBItemCenterSubTitle>{item.nameEN}</SBItemCenterSubTitle>

                                                    <SBItemCenterSubTitle>Thời gian tập: {item.timeSpan}s/6 reps</SBItemCenterSubTitle>
                                                    <SBItemCenterSubTitle>Calo tiêu thụ: {item.calories} calories</SBItemCenterSubTitle>
                                                </SBItemCenter>
                                            </SBItemLeft>

                                            <SBItemRight>
                                                {item.id === selectedItems && (
                                                    <>
                                                        <SBIcon
                                                            onClick={() => {
                                                                const newList = listExerciseRecommendChangeSet.filter((itemRemove) => itemRemove.id !== item.id);
                                                                setListExerciseRecommendChangeSet(newList);
                                                            }}
                                                            src={IconCommon.warning}
                                                        ></SBIcon>
                                                        <SBIcon onClick={() => _switchExercise(item, index)} src={IconCommon.reload}></SBIcon>
                                                    </>
                                                )}
                                            </SBItemRight>
                                        </BodyScrollBarItem>
                                    </>
                                ))}
                            <SuperSetWrapper>
                                <IconAdd src={IconCommon.add}></IconAdd>
                                <h2>Superset</h2>
                                <BodyScrollBarItem>
                                    <WrapperBodyScrollBarItem></WrapperBodyScrollBarItem>

                                    <SBItemLeft>
                                        <SBIconLeftWrapper>
                                            <SBIconLeft src={IconCommon.details}></SBIconLeft>
                                        </SBIconLeftWrapper>
                                        <SBItemLeftWrapper src="https://api.tapta.net/images/stock-photo/2dz35oxc.5h3.png">
                                            <LeftTitleWrapper>
                                                <LeftTitle></LeftTitle>
                                            </LeftTitleWrapper>
                                        </SBItemLeftWrapper>
                                        <SBItemCenter>
                                            <SBItemCenterTitle></SBItemCenterTitle>

                                            <SBItemCenterSubTitle></SBItemCenterSubTitle>

                                            <SBItemCenterSubTitle>Thời gian tập:s/6 reps</SBItemCenterSubTitle>
                                            <SBItemCenterSubTitle>Calo tiêu thụ: calories</SBItemCenterSubTitle>
                                        </SBItemCenter>
                                    </SBItemLeft>
                                </BodyScrollBarItem>
                                <BodyScrollBarItem>
                                    <WrapperBodyScrollBarItem></WrapperBodyScrollBarItem>
                                    <SBItemLeft>
                                        <SBIconLeftWrapper>
                                            <SBIconLeft src={IconCommon.details}></SBIconLeft>
                                        </SBIconLeftWrapper>
                                        <SBItemLeftWrapper2>
                                            <LeftTitleWrapper>
                                                <IconAdd2 src={IconCommon.add} onClick={handleOpenModalAddSet}></IconAdd2>
                                            </LeftTitleWrapper>
                                        </SBItemLeftWrapper2>
                                        <SBItemCenter>
                                            <SBItemCenterTitle></SBItemCenterTitle>

                                            <SBItemCenterSubTitle></SBItemCenterSubTitle>

                                            <SBItemCenterSubTitle>Thời gian tập:s/6 reps</SBItemCenterSubTitle>
                                            <SBItemCenterSubTitle>Calo tiêu thụ: calories</SBItemCenterSubTitle>
                                        </SBItemCenter>
                                    </SBItemLeft>
                                </BodyScrollBarItem>
                            </SuperSetWrapper>
                            <TriSetWrapper>
                                <IconAdd src={IconCommon.add}></IconAdd>
                                <h2>Tri-set</h2>
                                <BodyScrollBarItem>
                                    <WrapperBodyScrollBarItem></WrapperBodyScrollBarItem>

                                    <SBItemLeft>
                                        <SBIconLeftWrapper>
                                            <SBIconLeft src={IconCommon.details}></SBIconLeft>
                                        </SBIconLeftWrapper>
                                        <SBItemLeftWrapper src="https://api.tapta.net/images/stock-photo/2dz35oxc.5h3.png">
                                            <LeftTitleWrapper>
                                                <LeftTitle></LeftTitle>
                                            </LeftTitleWrapper>
                                        </SBItemLeftWrapper>
                                        <SBItemCenter>
                                            <SBItemCenterTitle></SBItemCenterTitle>

                                            <SBItemCenterSubTitle></SBItemCenterSubTitle>

                                            <SBItemCenterSubTitle>Thời gian tập:s/6 reps</SBItemCenterSubTitle>
                                            <SBItemCenterSubTitle>Calo tiêu thụ: calories</SBItemCenterSubTitle>
                                        </SBItemCenter>
                                    </SBItemLeft>
                                </BodyScrollBarItem>
                                <BodyScrollBarItem>
                                    <WrapperBodyScrollBarItem></WrapperBodyScrollBarItem>
                                    <SBItemLeft>
                                        <SBIconLeftWrapper>
                                            <SBIconLeft src={IconCommon.details}></SBIconLeft>
                                        </SBIconLeftWrapper>
                                        <SBItemLeftWrapper2>
                                            <LeftTitleWrapper>
                                                <IconAdd2 src={IconCommon.add} onClick={handleOpenModalAddSet}></IconAdd2>
                                            </LeftTitleWrapper>
                                        </SBItemLeftWrapper2>
                                        <SBItemCenter>
                                            <SBItemCenterTitle></SBItemCenterTitle>

                                            <SBItemCenterSubTitle></SBItemCenterSubTitle>

                                            <SBItemCenterSubTitle>Thời gian tập:s/6 reps</SBItemCenterSubTitle>
                                            <SBItemCenterSubTitle>Calo tiêu thụ: calories</SBItemCenterSubTitle>
                                        </SBItemCenter>
                                    </SBItemLeft>
                                </BodyScrollBarItem>
                                <BodyScrollBarItem>
                                    <WrapperBodyScrollBarItem></WrapperBodyScrollBarItem>
                                    <SBItemLeft>
                                        <SBIconLeftWrapper>
                                            <SBIconLeft src={IconCommon.details}></SBIconLeft>
                                        </SBIconLeftWrapper>
                                        <SBItemLeftWrapper2>
                                            <LeftTitleWrapper>
                                                <IconAdd2 src={IconCommon.add} onClick={handleOpenModalAddSet}></IconAdd2>
                                            </LeftTitleWrapper>
                                        </SBItemLeftWrapper2>
                                        <SBItemCenter>
                                            <SBItemCenterTitle></SBItemCenterTitle>

                                            <SBItemCenterSubTitle></SBItemCenterSubTitle>

                                            <SBItemCenterSubTitle>Thời gian tập:s/6 reps</SBItemCenterSubTitle>
                                            <SBItemCenterSubTitle>Calo tiêu thụ: calories</SBItemCenterSubTitle>
                                        </SBItemCenter>
                                    </SBItemLeft>
                                </BodyScrollBarItem>
                            </TriSetWrapper>
                        </BodyScrollBarItemWrapper>
                    </ChangeSetBody>
                </ChangeSetInner>
                <ChangeSetFooter>
                    <ButtonTop>
                        <ButtonAdd onClick={() => setIsOpenModalAddSet(true)}>
                            <IconAddButton src={IconCommon.add}></IconAddButton>
                            <ButtonTitle>Thêm bài tập</ButtonTitle>
                        </ButtonAdd>
                    </ButtonTop>
                    <ButtonBottom>
                        <ButtonSave onClick={handleOpenModalSaveSet}>
                            <IconSave src={IconCommon.save}></IconSave>
                            <ButtonTitle>Lưu</ButtonTitle>
                        </ButtonSave>
                    </ButtonBottom>
                </ChangeSetFooter>
                <ModalAddSet isOpen={isOpenModalAddSet} onClose={handleCloseModalAddSet} onSave={handleSaveModalAddSet} listExerciseCurrent={listExerciseRecommendChangeSet}></ModalAddSet>
            </ModalChangeSetWrapper>
        </>
    );
};

export default ModalChangeSet;

const slideInFromTop = keyframes`
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
`;

const ModalChangeSetWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    position: fixed;
    flex-direction: column-reverse;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
    background: #272727;
`;

const ChangeSetInner = styled.div`
    width: 100%;
    height: 100%;
    background-color: #3a3a3a;
    position: relative;
    border-radius: 16px 16px 0px 0px;
    background: #272727;
    animation: ${slideInFromTop} 0.2s ease-in-out forwards;
`;

const ChangeSetHeader = styled.div`
    width: 100%;
    height: 8%;
    background: rgba(39, 39, 39, 0.6);
    box-shadow: 0px 1px 0px 0px #3f3f3f;
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
`;

const ChangeSetHeaderTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    line-height: normal;
`;

const ChangeSetHeaderTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    width: 100%;

    flex-shrink: 0;
`;
const IconBack = styled.img`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
`;

const ChangeSetHeaderRight = styled.img`
    color: #ff5c00;
    text-align: right;
    font-family: "Be Vietnam Pro";
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const ChangeSetBody = styled.div`
    width: 100%;
    height: 90%;
`;

const BodyScrollBarItemWrapper = styled.div`
    width: 100%;
    max-height: 80%;
    overflow-y: scroll;
`;

const BodyScrollBarItem = styled.div`
    display: flex;
    z-index: 1;
    width: 100%;
    position: relative;
    justify-content: space-between;
    border-bottom: 1px solid #3f3f3f;
    border-top: ${(props) => (props.borderTop ? props.borderTop : "")};
    padding: 12px 0px;
    position: relative;
    margin-top: ${(props) => (props.mt ? props.mt : "")};
`;

const SBItemLeft = styled.div`
    width: 80%;
    height: 84px;
    display: flex;
    flex-shrink: 0;
`;

const SBItemCenter = styled.div`
    margin-left: 10px;
`;

const SBItemCenterTitle = styled.h2`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const SubTitleConfig = styled.h3`
    color: #939393;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const SBItemCenterSubTitle = styled(SubTitleConfig)`
    // Add your styles here
`;

const SBItemRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    z-index: 2;
`;
const SBIcon = styled.img`
    margin-right: 15px;
`;

const SBItemLeftWrapper = styled.div`
    width: 85px;
    position: relative;
    height: 84px;

    flex-shrink: 0;
    background-image: ${(props) => `url(${props.src})`};
    background-repeat: no-repeat;
`;

const SBItemLeftWrapper2 = styled(SBItemLeftWrapper)`
    background-color: #d9d9d9;
    border-radius: 16px;
`;

const LeftTitleWrapper = styled.div`
    width: 100%;
    height: 45px;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: ${(props) => (props.bg ? props.bg : "")};

    transition: all 5s ease;
`;

const LeftTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    margin-bottom: 4px;
    font-weight: 500;
    line-height: normal;
`;

const ChangeSetFooter = styled.div`
    width: 100%;
    position: fixed;
    flex-direction: column;
    bottom: 0;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(39, 39, 39, 0.9);
    /* z-index: ; */
`;
const ButtonSave = styled.button`
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    flex-shrink: 0;
    border-radius: 50px;
    border: 1px solid rgba(184, 229, 255, 0.2);
    background: linear-gradient(268deg, #0352fe 0.45%, #0060ff 97.59%);
    box-shadow: 0px 0px 15px 0px rgba(3, 82, 254, 0.4);
    color: #fff;

    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const ChangeSetHeaderRightWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
`;

const ChangeSetHeaderRightTitle = styled.h3`
    overflow: hidden;
    color: #fff;
    text-overflow: ellipsis;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const ActionLeft = styled.div`
    display: flex;
`;
const ActionRight = styled.div`
    display: flex;
`;
const AIcon = styled.img`
    margin-right: 12px;
`;
const TitleLeft = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;
const CSHRTWapper = styled.div`
    display: inline-flex;
    height: 32px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 30px;
    border: 1px solid rgba(3, 82, 254, 0.2);
    background: ${(props) => (props.bg ? props.bg : "#3a3a3a")};
`;
const SBIconLeftWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
const SBIconLeft = styled.img`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-right: 5px;
`;

const IconAdd = styled.img`
    position: absolute;
    left: 50%;
    right: 50%;
    top: -10px;
`;
const WrapperBodyScrollBarItem = styled.div`
    width: 100%;
    height: 100%;
    border: 1px solid #3f3f3f;
    opacity: 0.2;
    position: absolute;
    background: ${(props) => (props.bg ? props.bg : "")};
    transition: background 1s ease-in-out;
    top: 0;
`;

const IconSave = styled.img`
    margin-right: 10px;
`;

const ButtonTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const ButtonAdd = styled.button`
    display: inline-flex;
    height: 42px;
    padding: 14px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 30px;
    border: 1px solid #484848;
    background: #3a3a3a;
`;

const IconAddButton = styled.img``;

const ButtonTop = styled.div`
    margin-bottom: 10px;
`;

const ButtonBottom = styled.div`
    width: 100%;
    height: 50%;
    display: flex;

    justify-content: center;
    align-items: center;
    background: rgba(39, 39, 39, 0.8);
    box-shadow: 0px -1px 0px 0px #3f3f3f;
    backdrop-filter: blur(4px);
`;
const SuperSetWrapper = styled.div`
    background: linear-gradient(90deg, rgba(245, 0, 44, 0.2) 0%, rgba(245, 0, 44, 0) 100%);
    color: red;
    position: relative;
    h2 {
        color: #f5002c;
        font-family: "Be Vietnam Pro";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        margin-left: 5px;
        width: 30%;
        padding-top: 10px;
        text-align: center;
    }
`;
const TriSetWrapper = styled.div`
    background: linear-gradient(90deg, rgba(175, 0, 236, 0.2) 0%, rgba(175, 0, 236, 0) 100%);
    position: relative;
    h2 {
        color: #9747ff;
        font-family: "Be Vietnam Pro";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        margin-left: 5px;
        width: 30%;
        padding-top: 10px;
        text-align: center;
    }
`;
const IconAdd2 = styled(IconAdd)`
    right: 0;
    left: 37%;
`;
