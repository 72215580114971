import BaseServices from "./BaseServices";

const PlanServices = {
    GetListPlanExerciseByFolderId: async (id) => {
        return await BaseServices.Get(`/api/PlanExercise/get-list-plan-exercise-by-folder-id/${id}`);
    },
    UpdatePlanExerciseDetailByPlanExerciseAndListExerciseId: async (planExerciseId, listExerciseId) => {
        return await BaseServices.Post(`api/PlanExercise/update-list-plan-exercise-detail-by-plan-exercise-id-and-list-exercise-id/${planExerciseId}`, listExerciseId);
    },
};

export default PlanServices;
