import styled from 'styled-components';
import { useState, useEffect } from "react";

const TrainingDetailsPage = () => {
    return (
        <>
            <TrainingWrapper>
                <TrainingHeader>
                    <HeaderLeft>
                        <LeftIcon>
                            123
                        </LeftIcon>
                        <LeftTitle>

                        </LeftTitle>
                    </HeaderLeft>
                    <HeaderRight>
                        <RightIcon>

                        </RightIcon>
                        <RightTitle>
                            
                        </RightTitle>
                    </HeaderRight>
                </TrainingHeader>
                    
            </TrainingWrapper>
        </>
    )

}

export default TrainingDetailsPage;


const TrainingWrapper = styled.div`
    /* Your styles here */
`;

const TrainingHeader = styled.div`
    /* Your styles here */
`;

const HeaderLeft = styled.div`
    /* Your styles here */
`;

const LeftIcon = styled.div`
    /* Your styles here */
`;

const LeftTitle = styled.div`
    /* Your styles here */
`;

const HeaderRight = styled.div`
    /* Your styles here */
`;

const RightIcon = styled.div`
    /* Your styles here */
`;

const RightTitle = styled.div`
    /* Your styles here */
`;