import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import useStore from "../based/store/useStore";
import { Button, ButtonWrapper, ButtonMen, ButtonFooter } from "../based/ButtonStyles";
import UserServices from "../based/services/UserServices";
import { jwtDecode } from "jwt-decode";
import TargetServices from "../based/services/TargetServices";
import Common from "../based/Common";
import MessageValidation from "../based/MessageValidation";
const Home = () => {
    const gender = { man: 1, woman: 0 };
    const navigate = useNavigate();
    const [selectedButton, setSelectedButton] = useState(gender.man);
    const [showMessage, setShowMessage] = useState(false);
    const [userInfo, setUserInfo] = useState({
        id: "",
        name: "",
        avt: "",
    });
    const [formAddUserDetail, setFormAddUserDetail] = useState({
        gender: 1,
        birthDay: "",
        height: "",
        weight: "",
    });
    const [currentInputBirthday, setCurrentInputBirthday] = useState("");
    const handleButtonClick = (button) => {
        setSelectedButton(button);
    };

    useEffect(() => {
        const userName = Common.GetUserName();
    }, []);

    async function CreateUpdateUserDetail() {
        console.log("form2", formAddUserDetail);
        const formAdd = formAddUserDetail;
        const dateConvert = Common.convertStringToDate(formAdd.birthDay);
        formAdd.birthDay = dateConvert;
        formAdd.height = parseInt(formAdd.height);
        formAdd.weight = parseInt(formAdd.weight);
        const [err, data] = await UserServices.CreateUserDetail(formAdd);
        if (!err) {
            console.log(data);
            window.location.href = ` /details/${selectedButton}`;
        } else {
            console.log(err);
        }
    }
    useEffect(() => {
        var token = Common.GetToken();
        const fetchData = async () => {
            if (token) {
                const [err, data] = await UserServices.GetUserDetailByUserId();
                if (!err && data != null) {
                    if (data.currentPlan !== 0) {
                        navigate(`/home/${data.currentPlan}`);
                    } else {
                        const [err2, data2] = await TargetServices.GetUserTargetByUserId();
                        if (!err && data2 != null) {
                            navigate(`/plan`);
                        } else {
                            navigate(`/details/${data.gender}`);
                        }
                    }
                } else {
                    console.log("null");
                }
            } else {
                window.location.href = `/login`;
            }
        };

        fetchData();
    }, []);

    function formatInputDate(inputDate) {
        setShowMessage(false);
        const valueInput = inputDate.replace(/[^0-9/]/g, "");

        if (valueInput.length <= currentInputBirthday.length) {
            setCurrentInputBirthday(valueInput);
            return valueInput;
        }
        setCurrentInputBirthday(valueInput);

        const date = valueInput.split("/").join("");

        if (date.length <= 2) {
            const day = date.substring(0, 2);
            if (day.length < 2) {
                if (day >= 0 && day <= 31) {
                    return date.substring(0, 2);
                }
            } else {
                if (day > 31 || day < 1) {
                    return date.substring(0, 1);
                }
            }
        }

        if (date.length <= 4) {
            const month = date.substring(2, 4);
            console.log("moth", month);
            if (month.length < 2) {
                if (month >= 0 && month <= 12) {
                    return date.substring(0, 2) + "/" + date.substring(2);
                }
            } else {
                if (month > 12 || month < 1) {
                    return date.substring(0, 2) + "/";
                }
            }
        }

        if (date.length <= 8) {
            const year = date.substring(4, 8);

            if (year.length < 4) {
                if (year <= 2022) {
                    console.log("zo");
                    return date.substring(0, 2) + "/" + date.substring(2, 4) + "/" + date.substring(4);
                } else {
                    return date.substring(0, 2) + "/" + date.substring(2, 4) + "/";
                }
            } else {
                if (year < 1900 || year > 2022) {
                    return date.substring(0, 2) + "/" + date.substring(2, 4) + "/";
                } else {
                    return date.substring(0, 2) + "/" + date.substring(2, 4) + "/" + date.substring(4, 8);
                }
            }
        }

        if (date.length > 10) {
            return date.substring(0, 2) + "/" + date.substring(2, 4) + "/" + date.substring(4, 8);
        }

        return date.substring(0, 2) + "/" + date.substring(2, 4) + "/" + date.substring(4, 8);
    }

    function formatInputTallHeight(input, type) {
        setShowMessage(false);
        console.log("value", input.length);
        if (type === 1) {
            if (input.length > 3) {
                return input.substring(0, 3);
            }
            if (input.length === 3) {
            }
        }
        if (type === 2) {
            if (input.length > 3) {
                return input.substring(0, 3);
            }
            if (input.length === 3) {
            }
        }
        const checkValue = input.substring(0, 1);
        if (checkValue === "0") {
            return input.substring(1, 3);
        }

        return input;
    }

    const HandleCreateUpdateUserDetail = () => {
        if (formAddUserDetail.birthDay === "" || formAddUserDetail.birthDay.length < 10) {
            setShowMessage(true);
            return;
        }
        if (formAddUserDetail.height === 0) {
            setShowMessage(true);
            return;
        }
        if (formAddUserDetail.weight === 0) {
            setShowMessage(true);
            return;
        } else {
            CreateUpdateUserDetail();
        }
    };

    console.log(formAddUserDetail);

    return (
        <>
            <HomeWrapperParent>
                <HomeWrapper>
                    <header>
                        <HeaderContainer>
                            <HeaderLeft>
                                Xin chào,
                                <NameUser color="#FF5C00">{userInfo.name}</NameUser>
                            </HeaderLeft>
                            <HeaderRight>
                                <Logo bgImage={userInfo.avatar}></Logo>
                            </HeaderRight>
                        </HeaderContainer>
                    </header>
                    <BodyWrapper>
                        <ContentBody>Chào mừng đến với Gym Train . Hãy cho chúng tôi biết 1 số thông tin về bạn để có những bài tập phù hợp nhất nhé</ContentBody>
                        <Gender>
                            <GenderTitle>Gender</GenderTitle>

                            <ButtonWrapper>
                                <ButtonMen
                                    style={{
                                        backgroundColor: selectedButton === gender.man ? "blue" : "",
                                    }}
                                    onClick={() => {
                                        handleButtonClick(gender.man);
                                        setFormAddUserDetail({
                                            ...formAddUserDetail,
                                            gender: gender.man,
                                        });
                                    }}
                                >
                                    Nam
                                </ButtonMen>
                                <Button
                                    style={{
                                        backgroundColor: selectedButton === gender.woman ? "blue" : "",
                                    }}
                                    onClick={() => {
                                        handleButtonClick(gender.woman);
                                        setFormAddUserDetail({
                                            ...formAddUserDetail,
                                            gender: gender.woman,
                                        });
                                    }}
                                >
                                    Nữ
                                </Button>
                            </ButtonWrapper>
                        </Gender>

                        <DetailsBody>
                            <DetailsTittle>Thông số cơ thể</DetailsTittle>

                            <DobWrapper>
                                <Input
                                    onChange={(e) => {
                                        const enteredDate = e.target.value;
                                        const formattedDate = formatInputDate(enteredDate);
                                        setFormAddUserDetail({ ...formAddUserDetail, birthDay: formattedDate });
                                    }}
                                    value={formAddUserDetail.birthDay}
                                    type="text"
                                    inputMode="numeric"
                                    required="required"
                                    placeholder="Nhập (dd/mm/yyyy)"
                                ></Input>
                                <Label>
                                    Năm sinh <span>*</span>
                                </Label>
                            </DobWrapper>

                            <TallWrapper>
                                <Input
                                    onChange={(e) => {
                                        const enteredTall = e.target.value;
                                        const formattedTall = formatInputTallHeight(enteredTall, 1);
                                        setFormAddUserDetail({ ...formAddUserDetail, height: formattedTall });
                                    }}
                                    value={formAddUserDetail.height}
                                    type="number"
                                    required="required"
                                    placeholder="Nhập"
                                    pr="45px"
                                    pattern="\d*"
                                    inputMode="numeric"
                                ></Input>
                                <Label>
                                    Chiều cao <span>*</span>
                                </Label>
                                <Label2>cm</Label2>
                            </TallWrapper>
                            <WeightWrapper>
                                <Input
                                    value={formAddUserDetail.weight}
                                    onChange={(e) => {
                                        const enteredWeight = e.target.value;
                                        const formattedWeight = formatInputTallHeight(enteredWeight, 2);
                                        setFormAddUserDetail({ ...formAddUserDetail, weight: formattedWeight });
                                    }}
                                    type="number"
                                    pattern="\d*"
                                    inputMode="numeric"
                                    required="required"
                                    placeholder="Nhập"
                                    pr="50px"
                                ></Input>
                                <Label2>kg</Label2>
                                <Label>
                                    Cân nặng <span>*</span>
                                </Label>
                            </WeightWrapper>
                        </DetailsBody>
                        <MessageValidation isShow={showMessage} message="Thông tin chưa đầy đủ hoặc chưa đúng định dạng !" />
                        <FooterWrapper>
                            <ButtonFooter onClick={() => HandleCreateUpdateUserDetail()}>Tiếp tục</ButtonFooter>
                            {/* <Link to={``}></Link> */}
                        </FooterWrapper>
                    </BodyWrapper>
                </HomeWrapper>
            </HomeWrapperParent>
        </>
    );
};
export default Home;

const HeaderContainer = styled.div`
    @media (max-width: 414px) {
        display: flex;
        justify-content: space-between;
    }
`;

const HeaderLeft = styled.div`
    display: flex;
    align-items: baseline;
    padding-top: 10px;
    color: var(--color-text);
`;

const HeaderRight = styled.div`
    padding: 10px 10px;
`;

const Logo = styled.div`
    background-image: url(${(props) => props.bgImage});
    background-size: cover;
    background-position: 50%;
    @media (max-width: 414px) {
        width: 36px;
        height: 36px;
        top: 21px;
        left: 21px;
        border-radius: 999px;
    }
`;

const NameUser = styled.h3`
    color: ${(props) => props.color};

    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;

    @media (max-width: 414px) {
    }
`;

const ContentBody = styled.h2`
    font-size: 14px;
    padding: 0 12px;
    color: var(--color-text);
    @media (max-width: 414px) {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        display: flex;
        flex-wrap: wrap;
    }
`;

const BodyWrapper = styled.div`
    height: 100%;
`;

const Gender = styled.div`
    margin-top: 24px;
    padding: 0 12px;
`;

const GenderTitle = styled.h3`
    margin-bottom: 16px;
    color: var(--color-text);
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
`;
const DetailsBody = styled.div`
    margin-top: 36px;
    margin-bottom: 22px;
`;
const DetailsTittle = styled.h3`
    font-size: 24px;
    font-weight: 600;
    color: var(--color-text);
    padding: 0 12px;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 22px;
`;
const WrapperInput = styled.div`
    width: 100vw;
    position: relative;
    height: 72px;
    margin-bottom: 16px;
    display: flex;
`;
const DobWrapper = styled(WrapperInput)``;
const TallWrapper = styled(WrapperInput)``;
const WeightWrapper = styled(WrapperInput)``;
const Input = styled.input`
    width: 100%;
    height: auto;
    border-radius: 16px;
    padding: 10px;
    color: var(--color-text);
    font-size: 28px;
    font-weight: 800;
    text-align: right;
    background-color: var(--color-input);
    outline: none;
    border: none;
    padding-right: ${(props) => (props.pr ? props.pr : "")};
    ::placeholder {
        color: #fff;
        text-align: right;
        font-family: "Be Vietnam Pro";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        opacity: 0.2;
        line-height: normal;
        margin-bottom: 12px;
    }
`;

const Label = styled.div`
    position: absolute;
    color: var(--color-text);
    display: flex;
    left: 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

    height: 18px;
    top: 27px;
    z-index: 1;
    span {
        color: red;
        margin-left: 5px;
    }
`;
const Label2 = styled.label`
    position: absolute;
    color: var(--color-text);
    display: flex;

    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    width: 17px;
    height: 18px;
    opacity: 0.7;
    top: 30px;
    right: 10px;
    font-weight: 600;
`;

const HomeWrapper = styled.div`
    display: flex;
    height: 100%;
    header {
        padding: 0 20px;
    }
    flex-direction: column;
`;
const FooterWrapper = styled.div`
    background: rgba(39, 39, 39, 0.8);
    position: fixed;
    bottom: 0;
    box-shadow: 0px -1px 0px 0px #3f3f3f;
    backdrop-filter: blur(4px);
    width: 414px;
    height: 72px;
    flex-shrink: 0;
    margin-top: 5%;

    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    /* position: fixed; */
`;
const HomeWrapperParent = styled.div`
    height: 100vh;
    position: fixed;
    width: 100vw;
    background-color: var(--bg-color);
`;
