// modal này hướng dẫn làm sao để tập bài tập đúng cách

import styled from "styled-components";

import { IconCommon, Tick } from "../based/IconShared";
import { useState } from "react";
import { GymImageShared } from "../based/GymImageShared";
import { GymExerciseImage, GymExerciseImageRectangleHowToDo, ImageForMuscle } from "../based/GymExerciseShared";
import { Arrow } from "../based/IconShared";

const configTypeExercise = {
    1: "Isolation",
    2: "Compound",
    3: "Superset",
};

const listExercise = [
    {
        id: 1,

        name: "Chống đẩy",
        image: GymExerciseImage.barbell,
        des: ["Dumbbell bench press", "Thời gian: 2 phút", "Max Rep: 10"],
        type: 1,
        topic: 1,
    },
    {
        id: 2,
        name: "Treo người nâng chân",
        image: GymExerciseImage.barbell,
        des: ["Dumbbell bench press", "Thời gian: 2 phút", "Max Rep: 10"],
        type: 2,
        topic: 1,
    },
    {
        id: 3,
        name: "Superset",
        image: GymExerciseImage.barbell,
        des: ["Dumbbell bench press", "Thời gian: 2 phút", "Max Rep: 10"],
        type: 3,
        topic: 1,
    },

    {
        id: 1,

        name: "Chống đẩy vai",
        image: GymExerciseImage.barbell,
        des: ["Dumbbell bench press", "Thời gian: 2 phút", "Max Rep: 10"],
        type: 1,
        topic: 2,
    },
    {
        id: 2,
        name: "Treo người nâng vai",
        image: GymExerciseImage.barbell,
        des: ["Dumbbell bench press", "Thời gian: 2 phút", "Max Rep: 10"],
        type: 2,
        topic: 2,
    },
    {
        id: 3,
        name: "Superset vai",
        image: GymExerciseImage.barbell,
        des: ["Dumbbell bench press", "Thời gian: 2 phút", "Max Rep: 10"],
        type: 3,
        topic: 2,
    },
    {
        id: 4,
        name: "Bench Press",
        image: GymExerciseImage.barbell,
        des: ["Bench Press", "Thời gian: 3 phút", "Max Rep: 8"],
        type: 1,
        topic: 3,
    },
    {
        id: 5,
        name: "Fly",
        image: GymExerciseImage.dumbbell,
        des: ["Dumbbell Fly", "Thời gian: 2 phút", "Max Rep: 12"],
        type: 2,
        topic: 3,
    },
    {
        id: 6,
        name: "Push-ups",
        image: GymExerciseImage.pushups,
        des: ["Push-ups", "Thời gian: 2 phút", "Max Rep: 15"],
        type: 3,
        topic: 3,
    },

    {
        id: 7,
        name: "Deadlift",
        image: GymExerciseImage.barbell,
        des: ["Deadlift", "Thời gian: 3 phút", "Max Rep: 6"],
        type: 1,
        topic: 4,
    },
    {
        id: 8,
        name: "Pull-ups",
        image: GymExerciseImage.pullups,
        des: ["Pull-ups", "Thời gian: 2 phút", "Max Rep: 10"],
        type: 2,
        topic: 4,
    },
    {
        id: 9,
        name: "Rowing",
        image: GymExerciseImage.rowing,
        des: ["Barbell Row", "Thời gian: 2 phút", "Max Rep: 12"],
        type: 3,
        topic: 4,
    },

    {
        id: 10,
        name: "Tricep Dips",
        image: GymExerciseImage.dips,
        des: ["Tricep Dips", "Thời gian: 2 phút", "Max Rep: 12"],
        type: 1,
        topic: 5,
    },
    {
        id: 11,
        name: "Bicep Curls",
        image: GymExerciseImage.dumbbell,
        des: ["Bicep Curls", "Thời gian: 2 phút", "Max Rep: 10"],
        type: 2,
        topic: 5,
    },
    {
        id: 12,
        name: "Hammer Curls",
        image: GymExerciseImage.dumbbell,
        des: ["Hammer Curls", "Thời gian: 2 phút", "Max Rep: 12"],
        type: 3,
        topic: 5,
    },

    {
        id: 13,
        name: "Squats",
        image: GymExerciseImage.squats,
        des: ["Squats", "Thời gian: 3 phút", "Max Rep: 8"],
        type: 1,
        topic: 6,
    },
    {
        id: 14,
        name: "Lunges",
        image: GymExerciseImage.lunges,
        des: ["Lunges", "Thời gian: 2 phút", "Max Rep: 10"],
        type: 2,
        topic: 6,
    },
    {
        id: 15,
        name: "Leg Press",
        image: GymExerciseImage.legpress,
        des: ["Leg Press", "Thời gian: 2 phút", "Max Rep: 12"],
        type: 3,
        topic: 6,
    },

    {
        id: 16,
        name: "Cardio - Running",
        image: GymExerciseImage.running,
        des: ["Cardio - Running", "Thời gian: 20 minutes", "Distance: 2 miles"],
        type: 1,
        topic: 7,
    },
    {
        id: 17,
        name: "Cardio - Cycling",
        image: GymExerciseImage.cycling,
        des: ["Cardio - Cycling", "Thời gian: 30 minutes", "Distance: 10 miles"],
        type: 2,
        topic: 7,
    },
    {
        id: 18,
        name: "Jump Rope",
        image: GymExerciseImage.jumprope,
        des: ["Jump Rope", "Thời gian: 15 minutes", "Reps: 500"],
        type: 3,
        topic: 7,
    },
];

const ModalHowTodo = ({ isOpen, onClose, onSave, selectedType }) => {
    const [selectedItems, setSelectedItems] = useState([0]);
    const [selectedMenuTopic, setSelectedMenuTopic] = useState(2);

    return (
        <>
            <HowToDoWrapper style={{ display: isOpen ? "flex" : "none" }}>
                <HowToDoInner>
                    <HowToDoHeader>
                        <HowToDoHeaderTop>
                            <IconBack onClick={onClose} src={Arrow.chevronleft}></IconBack>
                            <HowToDoHeaderTitle>Cách tập</HowToDoHeaderTitle>
                            <HowToDoHeaderRightWrapper>
                                <TitleClose onClick={onClose} src={IconCommon.deleteicon}></TitleClose>
                            </HowToDoHeaderRightWrapper>
                        </HowToDoHeaderTop>
                    </HowToDoHeader>
                    <HowToDoBody>
                        <BodyTitleHeaderWrapper>
                            <BodyTitleHeader>Đẩy tạ đôi trên ghế phẳng</BodyTitleHeader>
                            <BodyTitleSubHeader>Dumbbell bench press</BodyTitleSubHeader>
                        </BodyTitleHeaderWrapper>
                        <BodyItemWrapper>
                            <ImageItemWrapper>
                                <ImageItem src={GymExerciseImageRectangleHowToDo.barbell}></ImageItem>
                            </ImageItemWrapper>
                            <ContentItemWrapper>
                                <Meaning>
                                    Nằm xuống và chống bằng hay tay, giữ chúng hơi rộng hơn vai. Giữ hai chân với nhau, thân người và tay tạo thành một đường thẳng. Chân cùng với người tạo một đường thắng. Hít vào và hạ thấp người xuống sàn. Thở ra
                                    và nâng người lên cho đến khi thẳng tay.
                                </Meaning>
                                <Time>Thời gian tập: 2 phút</Time>
                                <ForMuscle>
                                    <TopItem>
                                        <ContentItem>Nhóm cơ được tác động (Compound):</ContentItem>
                                        <DetailItem>
                                            <Item>Cơ ngực</Item>
                                        </DetailItem>
                                    </TopItem>
                                    <BotItem>
                                        <ImageBotItem src={ImageForMuscle.nguc_tay_sau}></ImageBotItem>
                                    </BotItem>
                                </ForMuscle>
                            </ContentItemWrapper>
                        </BodyItemWrapper>
                    </HowToDoBody>
                    <DetailPracticeFooter>
                        <ActionButton onClick={onClose}>Đóng</ActionButton>
                    </DetailPracticeFooter>
                </HowToDoInner>
            </HowToDoWrapper>
        </>
    );
};

export default ModalHowTodo;

const HowToDoWrapper = styled.div`
    width: 100%;
    height: 100vh;
    top: 0;
    display: flex;
    position: absolute;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
`;

const HowToDoInner = styled.div`
    width: 100%;
    height: 100%;
    background-color: #3a3a3a;
    position: relative;
    border-radius: 16px 16px 0px 0px;
    background: #272727;
    overflow: scroll;
`;

const HowToDoHeader = styled.div`
    width: 100%;
    height: 10%;
    background: rgba(39, 39, 39, 0.6);
    box-shadow: 0px 1px 0px 0px #3f3f3f;
    backdrop-filter: blur(4px);
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
`;

const HowToDoHeaderTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

const HowToDoHeaderTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    width: 100%;

    flex-shrink: 0;
`;
const IconBack = styled.img``;

const HowToDoBody = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 0 16px;
`;

const CreateButton = styled.button`
    display: flex;
    height: 42px;
    padding: 14px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 30px;
    border: 1px solid #484848;

    background: #3a3a3a;
`;

const ButtonIcon = styled.img``;
const ButtonTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const HowToDoHeaderRightWrapper = styled.div``;

const IconClose = styled.img``;

const TitleClose = styled.img``;

const BodyTitleHeader = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 13px;
`;

const BodyTitleHeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const IconDone = styled.img`
    margin-right: 10px;
`;

const BodyScrollBarItemWrapper = styled.div`
    width: 100%;

    height: 80%;
    overflow-y: scroll;
`;

const BodyScrollBarItem = styled.div`
    display: flex;
    z-index: 1;
    width: 100%;
    position: relative;
    justify-content: space-between;
    border-bottom: 1px solid #3f3f3f;
    border-top: 1px solid #3f3f3f;
    padding: 12px 0px;
    position: relative;
    margin-top: ${(props) => (props.mt ? props.mt : "")};
`;

const WrapperBodyScrollBarItem = styled.div`
    width: 100%;
    height: 100%;
    border: 1px solid #3f3f3f;
    opacity: 0.5;
    position: absolute;
    background: ${(props) => (props.bg ? props.bg : "")};
    top: 0;
`;

const SBItemLeft = styled.div`
    width: 80%;
    height: 84px;
    display: flex;
    flex-shrink: 0;
`;

const SBIconLeftWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
`;
const SBIconLeft = styled.img`
    width: 18px;
    height: 18px;

    margin-right: 5px;
`;

const SBItemLeftWrapper = styled.div`
    width: 85px;
    position: relative;
    height: 84px;

    flex-shrink: 0;
    background-image: ${(props) => `url(${props.src})`};
    background-repeat: no-repeat;
`;

const LeftTitleWrapper = styled.div`
    width: 100%;
    height: 45px;
    position: absolute;
    bottom: 0;
    flex-shrink: 0;
    border-radius: 12px;
    background: ${(props) => (props.bg ? props.bg : "")};
    display: flex;
    align-items: flex-end;
    justify-content: center;
`;

const LeftTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    margin-bottom: 4px;
    font-weight: 500;
    line-height: normal;
`;

const SBItemCenterTitle = styled.h2`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

const SubTitleConfig = styled.h3`
    color: #939393;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const SBItemRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-right: 10px;
`;
const SBIcon = styled.img``;

const SBItemCenter = styled.div`
    margin-left: 10px;
`;

const SBItemCenterSubTitle = styled(SubTitleConfig)`
    // Add your styles here
`;

const CreateButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
`;

const BodyTitleSubHeader = styled.h3`
    color: #939393;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 12px;
    line-height: normal;
`;

const BodyItemWrapper = styled.div``;

const ImageItemWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
`;

const ImageItem = styled.img`
    width: 100%;
    height: 213px;
    flex-shrink: 0;
`;

const ContentItemWrapper = styled.div``;

const Meaning = styled.h3`
    color: #878787;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 6%;
    line-height: normal;
`;

const Time = styled.h3`
    color: #ff5c00;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10%;
`;

const ForMuscle = styled.div``;

const TopItem = styled.div`
    margin-bottom: 15px;
`;

const ContentItem = styled.h3`
    color: #ff5c00;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
`;
const BotItem = styled.div`
    display: flex;
    justify-content: center;
`;

const ImageBotItem = styled.img``;

const DetailItem = styled.div``;

const Item = styled.h3`
    display: inline-flex;
    height: 38px;
    padding: 14px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #3a3a3a;

    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const DetailPracticeFooter = styled.div`
    width: 100%;
    height: 10%;
    flex-shrink: 0;
    position: fixed;
    background: rgba(39, 39, 39, 0.8);
    box-shadow: 0px -1px 0px 0px #3f3f3f;
    backdrop-filter: blur(4px);
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ActionButton = styled.button`
    width: 148px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 50px;
    border: 1px solid #484848;
    background: #3a3a3a;

    //text color
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;
