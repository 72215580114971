import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { FooterWrapper, ButtonFooter } from "../../based/ButtonStyles";
import { BodyContent, BodyContentWrapper, ContentLeft, IconLeft, TitleLeftMonday, ContentRight, MatchedIcon } from "../Planpage";
import { days, daysName } from "../../based/ConfigDays";
import Modalchange from "../modalchange/Modalchange";
import PlanServices from "../../based/services/PLanServices";
import { BackArrow } from "../../based/ConfigIconSvg";
import cookies from "react-cookies";
const ModalCreatePlan = ({ isOpen, onClose, onSave, listMuscle, listSport }) => {
    const [newData, setNewData] = useState("");
    const [isModalOpenChange, setIsModalOpenChange] = useState(false);
    const [selectedDay, setSelectedDay] = useState(0);
    const [currentListMuscle, setCurrentListMuscle] = useState([]);
    const [currentListSport, setCurrentListSport] = useState([]);
    const [listMuscleDetailsJson, setListMuscleDetailsJson] = useState([]);
    const [listSportDetailsJson, setListSportDetailsJson] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState({
        id: 0,
        name: "",
        forTarget: 0,
    });

    useEffect(() => {}, [isOpen]);

    const handleSave = async () => {
        const userName = cookies.load("userName");
        const [err, data] = await PlanServices.CreateUpdatePlan({
            name: newData,
            username: userName,
        });
        if (!err && data) {
            // can fix lai  --- neu data qua lon
            const [err2, data2] = await PlanServices.GetPlanByUserName(userName);
            if (!err2 && data2) {
                const dataTemp = data2.filter((item) => item.name === newData);
                for (const item of listMuscleDetailsJson) {
                    const [err3, data3] = await PlanServices.CreateUpdatePlanMuscleDetail({
                        planId: dataTemp[0].id,
                        muscleId: item.muscleId,
                        daysOfWeek: item.daysOfWeek,
                    });

                    if (!err3 && data3) {
                    }
                }
                for (const item of listSportDetailsJson) {
                    const [err4, data4] = await PlanServices.CreateUpdatePlanSportDetail({
                        planId: dataTemp[0].id,
                        sportId: item.sportId,
                        daysOfWeek: item.daysOfWeek,
                    });

                    if (!err4 && data4) {
                    }
                }
            }
        }

        onSave(data);
    };

    function groupByDaysOfWeek(data) {
        return data.reduce((acc, obj) => {
            let group = acc.find((group) => group.daysOfWeek === obj.daysOfWeek);
            if (group) {
                group.muscleId.push(obj.muscleId);
            } else {
                acc.push({ planId: obj.planId, daysOfWeek: obj.daysOfWeek, muscleId: [obj.muscleId] });
            }
            return acc;
        }, []);
    }

    console.log(listMuscleDetailsJson);

    const handleOpenModalChange = (item2) => {
        setSelectedDay(item2);

        setIsModalOpenChange(true);
    };
    const handleCloseModalChange = () => {
        setIsModalOpenChange(false);
    };

    const handleSaveDataChange = (data) => {
        const updatedListMuscle = [...listMuscleDetailsJson];

        const filteredList = updatedListMuscle.filter((item) => item.daysOfWeek !== days[selectedDay]);

        data.selectedListMuscle.forEach((item) => {
            filteredList.push(item);
        });

        setListMuscleDetailsJson(filteredList);

        const updatedListSport = [...listSportDetailsJson];

        const filteredListSport = updatedListSport.filter((item) => item.daysOfWeek !== days[selectedDay]);

        data.selectedListSport.forEach((item) => {
            filteredListSport.push(item);
        });

        setListSportDetailsJson(filteredListSport);
    };

    return (
        <>
            <ModalWrapper style={{ display: isOpen ? "block" : "none" }}>
                <ModalInner>
                    <ModalHeader>
                        <ModalClose onClick={onClose}>
                            <BackArrow />
                        </ModalClose>
                        <HeaderTitle>Tạo mới kế hoạch</HeaderTitle>
                    </ModalHeader>
                    <Title>
                        Đặt tên kế hoạch <span>*</span>
                    </Title>
                    <InputWrapper>
                        {" "}
                        <Input type="text" value={newData} onChange={(e) => setNewData(e.target.value)} placeholder="Nhập tên"></Input>
                    </InputWrapper>

                    <BodyContent>
                        {Object.keys(days).map((item2, index) => (
                            <>
                                <BodyContentWrapper>
                                    <ContentLeft index={index} onClick={() => handleOpenModalChange(item2)}>
                                        <IconLeft>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
                                                <path d="M1 1H13" stroke="#484848" strokeWidth="2" strokeLinecap="round" />
                                                <path d="M1 6.82353H13" stroke="#484848" strokeWidth="2" strokeLinecap="round" />
                                                <path d="M1 12H13" stroke="#484848" strokeWidth="2" strokeLinecap="round" />
                                            </svg>
                                        </IconLeft>

                                        <TitleLeftMonday key={index}>{daysName[item2]}</TitleLeftMonday>
                                    </ContentLeft>
                                    <ContentRight index={index}>
                                        <ContentWrapper>
                                            {listMuscleDetailsJson &&
                                                listMuscleDetailsJson.map((item, index) => (
                                                    <>
                                                        {item.daysOfWeek === parseInt(days[item2]) ? (
                                                            <>
                                                                {listMuscle
                                                                    .filter((x) => x.id === item.muscleId)
                                                                    .map((muscleItem) => (
                                                                        <>
                                                                            <MatchedIcon src={muscleItem.thumbnail}></MatchedIcon>
                                                                        </>
                                                                    ))}
                                                            </>
                                                        ) : (
                                                            <> </>
                                                        )}
                                                    </>
                                                ))}

                                            {listSportDetailsJson &&
                                                listSportDetailsJson.map((item, index) => (
                                                    <>
                                                        {item.daysOfWeek === parseInt(days[item2]) ? (
                                                            <>
                                                                {listSport
                                                                    .filter((x) => x.id === item.sportId)
                                                                    .map((sportItem) => (
                                                                        <>
                                                                            <MatchedIcon src={sportItem.thumbnail}></MatchedIcon>
                                                                        </>
                                                                    ))}
                                                            </>
                                                        ) : (
                                                            <> </>
                                                        )}
                                                    </>
                                                ))}
                                        </ContentWrapper>
                                    </ContentRight>
                                </BodyContentWrapper>
                            </>
                        ))}
                        <SubDescription>Ấn vào một ngày cụ thể để chỉnh sửa *</SubDescription>
                    </BodyContent>
                    <FooterWrapper>
                        <ButtonFooter onClick={handleSave}>Lưu lại </ButtonFooter>
                    </FooterWrapper>
                </ModalInner>
            </ModalWrapper>
            <Modalchange isOpen={isModalOpenChange} onClose={handleCloseModalChange} onSave={handleSaveDataChange} listCurrentMuscle={currentListMuscle} listCurrentSport={currentListSport} selectedPlan={selectedPlan} selectedDay={selectedDay} />
        </>
    );
};

export default ModalCreatePlan;

const slideInFromTop = keyframes`
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
`;

const ModalWrapper = styled.div`
    position: absolute;
    height: 100%;
    bottom: 0;
    z-index: 2;
    width: 100%;
`;

const ModalInner = styled.div`
    width: 100%;
    padding: 10px;
    height: 100%;

    justify-content: center;

    flex-wrap: wrap;
    border-radius: 16px;
    background: #272727;
    position: relative;
    animation: ${slideInFromTop} 0.2s ease-in;
    .cancel {
    }
`;

const Title = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 16px 0px 12px 6px;
    span {
        color: #f5002c;
        font-family: "Be Vietnam Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;

const InputWrapper = styled.div`
    width: 100%;
    margin: 0 auto;
`;

const Input = styled.input`
    width: 100%;
    height: 42px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #2f2f2f;
    border: none;
    color: #fff;
    padding: 12px 16px;
    margin-bottom: 26px;
`;

const ChooseDayWrapper = styled.div``;
const ChooseDayTitle = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 12px;
`;
const Days = styled.div``;

const DaysButton = styled.button`
    display: inline-flex;
    width: 11%;
    height: 38px;
    padding: 14px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    margin-right: 11px;
    border-radius: 10px;
    border: 1px solid #484848;
    background: #3a3a3a;
    color: #fff;
    &.selected {
        background-color: #0352fe;
    }
    cursor: pointer;
`;
const ModalHeader = styled.div`
    width: 100%;
    height: auto;
    flex-shrink: 0;
    display: flex;
    padding: 16px;

    background: rgba(39, 39, 39, 0.6);
    box-shadow: 0px 1px 0px 0px #3f3f3f;
    backdrop-filter: blur(4px);
    align-items: center;
`;

const HeaderTitle = styled.div`
    width: 100%;
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    height: auto;
    display: flex;
    justify-content: center;
`;

const ModalClose = styled.div``;

const ContentWrapper = styled.div`
    width: 100%;
`;

const ContentHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 26px;
    margin-bottom: 13px;
`;
const ContentTitle = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const ContentAction = styled.button`
    color: #0352fe;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: #272727;
    border: none;
`;

const ContentBody = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
const ContentBodyWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
    width: 21%;
    height: 88px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid #484848;
    background: ${(props) => (props.isSelected ? "blue" : "#3A3A3A")};
    margin: ${(props) => {
        if (props.index !== 3) return "0px 16px 12px 0px";
    }};
    &.selected {
        background-color: blue;
    }
`;
const ContentBodyIcon = styled.div`
    margin-top: 4px;
`;
const ContentBodyTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const SubDescription = styled.div`
    color: #727272;
    margin-top: 12px;
    margin-left: 8px;

    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
