// modal này dùng để hiển thị hướng dẫn các thuật ngữ trong ứng dụng

import styled from "styled-components";
import { muscleIcons, IconCommon } from "../based/IconShared";
import { useState } from "react";

const listIntroduce = [
  {
    id: 1,
    name: "Max Rep",
    meaning:
      "(Max Repetition) là số Rep lớn nhất của mức tạ lớn nhất trong 7 buổi tập gần nhất.",
  },
  {
    id: 2,
    name: "Max Kg 7 buổi gần nhất",
    meaning:
      "là số cân nặng (Kilogram) cao nhất của bài tập này bạn đã tập trong 7 buổi tập gần nhất.",
  },
];

const ModalIntroduce = ({ isOpen, onClose, onSave, selectedType }) => {
  const [listMuscle, setListMuscle] = useState(muscleIcons);
  const [textNote, setTextNote] = useState("");
  const [selectedButton, setSelectedButton] = useState(1);


  return (
    <>
      <ModalChangeWrapper style={{ display: isOpen ? "flex" : "none" }}>
        <ModalWrapperInner>
          <ModalHeader>
            <HeaderTitle>Hướng dẫn</HeaderTitle>
            <HeaderAction
              src={IconCommon.deleteicon}
              onClick={onClose}
            ></HeaderAction>
          </ModalHeader>

          <BodyHeader>
            {selectedType === 1 ? (
              <>
                <BHItemLeftWrapper>
                  <SubTitle>Max rep</SubTitle>
                  <Title>12</Title>
                </BHItemLeftWrapper>
                <BHItemRightWrapper>
                  <SubTitle>Max Kg</SubTitle>
                  <Title>15</Title>
                </BHItemRightWrapper>
              </>
            ) : selectedType === 2 ? (
              <>
                <DropSetWrapper>
                  <DropSetIcon src={IconCommon.fire_dropset}></DropSetIcon>
                  <DropSetTitle>Dropset</DropSetTitle>
                </DropSetWrapper>
              </>
            ) : (
              <>
                <Introduce>
                        <IntroduceTitle>
                          % Max
                          <IntroduceMeaning>là tỉ lệ cân nặng hiện tại của tạ bài tập này so với mức tạ cao nhất trong 7 ngày qua.</IntroduceMeaning>
                        </IntroduceTitle>
                      </Introduce>
               </>
            )}
          </BodyHeader>

          <ModalBody>
            {selectedType === 1 ? (
              <>
                {" "}
                {listIntroduce.map((item, index) => {
                  return (
                    <>
                      <Introduce>
                        <IntroduceTitle>
                          {item.name}
                          <IntroduceMeaning>{item.meaning}</IntroduceMeaning>
                        </IntroduceTitle>
                      </Introduce>
                    </>
                  );
                })}
              </>
            ) : selectedType === 2 ? (
              <>
                <DropSetTitleMeaning>
                  Là phương pháp tập đòi hỏi bạn tập ở{" "}
                  <SpanSubTitle>mức tạ cao nhất</SpanSubTitle> rồi <SpanSubTitle>giảm dần
                  trọng lượng tạ </SpanSubTitle>qua từng set nhưng tăng dần Rep tập ở từng set
                  đến khi không thực hiện được nữa (Thường là 3-5 Set). Kỹ thuật
                  này nhằm giúp tối đa thời gian phát triển cơ bắp.
                </DropSetTitleMeaning>
              </>
            ) : (
              <></>
            )}
            <ModalFooter>
              <FooterActionWrapper>
                <FooterActionConfirm onClick={onClose}>
                  Đóng
                </FooterActionConfirm>
              </FooterActionWrapper>
            </ModalFooter>
          </ModalBody>
        </ModalWrapperInner>
      </ModalChangeWrapper>
    </>
  );
};

export default ModalIntroduce;

const ModalChangeWrapper = styled.div`
  width: 100%;
  height: 100vh;
  top: 0;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background: rgba(0, 0, 0, 0.8);
`;

const ModalWrapperInner = styled.div`
  width: 90%;
  margin: 0 auto;
  height:auto;
      max-height: 50%;
    min-height: 35%;
  padding: 10px 16px;

  background: #272727;
  position: relative;
  /* border-radius: 16px 16px 0px 0px; */

  border-radius: 16px;
`;

const ModalHeader = styled.div`
  background: rgba(39, 39, 39, 0.6);
  margin-top:10px;
  margin-bottom:5px;
  height: 13%;
  backdrop-filter: blur(4px);
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 12px;
`;

const HeaderTitle = styled.h3`
  color: #fff;

  font-family: "Be Vietnam Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const ModalBody = styled.div`
  height: 67%;
  width: 100%;
`;

const BodyHeader = styled.div`
  display: flex;

  margin: 10px 0px;
`;

const BodyItem = styled.div`
  display: flex;

  flex-wrap: wrap;
`;

const ItemWrapper = styled.div`
  width: calc(100% / 4 - 12px);
  margin-right: 12px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 88px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid #484848;
  background: ${(props) => (props.bg ? props.bg : "#3a3a3a")};
`;

const ItemIcon = styled.img`
  width: 54px;
  height: 54px;
  flex-shrink: 0;
`;

const ItemTitle = styled.div`
  color: #fff;
  text-align: center;
  font-family: "Be Vietnam Pro";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const HeaderAction = styled.img`
  position: absolute;
  right: 10px;
  margin-bottom: 0.5rem;
  z-index: 2;
`;

const ModalFooter = styled.div`
  width: 100%;
  height: 15%;
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  width: 40%;
  height: 42px;
  flex-shrink: 0;
  border-radius: 50px;
  border: 1px solid #484848;
  background: #3a3a3a;
  color: #fff;
  text-align: center;
  font-family: "Be Vietnam Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
const FooterAction = styled(Button)`
  /* width: 40%;
  height: 42px;
  flex-shrink: 0;
  border-radius: 50px;
  border: 1px solid #484848;
  background: #3a3a3a; */
`;

const FooterActionWrapper = styled.div`
  width: 80%;
  align-items: center;
  height: 100%;
  display: flex;
  justify-content: space-around;
`;

const FooterActionConfirm = styled(Button)`
  width: 117px;
  height: 42px;
  flex-shrink: 0;
  border-radius: 50px;
  border: 1px solid #484848;
  background: #3a3a3a;
`;
const NoteInput = styled.input`
  width: 90%;
  color: white;
  height: auto;
  margin-top: 7px;
  margin-left: 7px;

  border-radius: 12px;
  background: #2f2f2f;
  border: none;

  overflow-wrap: break-word;
  outline: none;
`;

const NoteWrapper = styled.div`
  width: 100%;
  height: 214px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #2f2f2f;
  border: none;
`;

const configItemBHItem = styled.div`
  width: 50%;
  height: 41px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const BHItemLeftWrapper = styled(configItemBHItem)`
  border-radius: 16px 0px 0px 16px;
  border: 1px solid rgba(72, 72, 72, 0.4);
  background: rgba(58, 58, 58, 0.4);
`;

const BHItemRightWrapper = styled(configItemBHItem)`
  border-radius: 0px 16px 16px 0px;
  border: 1px solid rgba(72, 72, 72, 0.4);
  background: rgba(58, 58, 58, 0.4);
`;

const SubTitle = styled.h3`
  color: #939393;
  font-family: "Be Vietnam Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const Title = styled.h3`
  color: #fff;
  font-family: "Be Vietnam Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
const Introduce = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const IntroduceTitle = styled.h3`
  color: #ff5c00;
  font-family: "Be Vietnam Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const IntroduceMeaning = styled.span`
  color: #878787;
  margin-left: 1%;
  font-family: "Be Vietnam Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const DropSetWrapper = styled.div`
  display: flex;
  align-items:center;

`;
const DropSetIcon = styled.img`
  margin-right: 10px;
`;
const DropSetTitle = styled.h3`
  color: #fff;
  text-align: center;
  font-family: "Be Vietnam Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const DropSetTitleMeaning = styled.span`
  color: #878787;
  font-family: "Be Vietnam Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const  SpanSubTitle = styled.span`
  color: #FF5C00;
font-family: "Be Vietnam Pro";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;

`;