import Homepage from "./homepage/Homepage";
import Details from "./detailspage/DetailsPage";
import Plan from "./planpage/Planpage";
import TrainingDetailsPage from "./trainingdetailspage/TraningDetailsPage";
import MainPage from "./mainpage/Mainpage";
import LoginPage from "./loginpage/Loginpage";
import PracticePage from "./practicepage/Practicepage";
import LoginCallback from "./loginpage/LoginCallback";
import Policy from "./policy/policy";
import Terms from "./terms/terms";
import DetailPractice from "./practicepage/DetailPracticle";
const AppRoutes = [
    {
        path: "/",
        element: <Homepage />,
    },
    {
        path: "/details/:gender",
        element: <Details />,
    },
    {
        path: "/plan",
        element: <Plan />,
    },
    {
        path: "/trainingdetails",
        element: <TrainingDetailsPage />,
    },
    {
        path: "/home/:planId",
        element: <MainPage />,
    },
    {
        path: "/practice/:planId/:id",
        element: <PracticePage />,
    },
    {
        path: "/login",
        element: <LoginPage />,
    },
    {
        path: "/login-social-callback",
        element: <LoginCallback />,
    },
    {
        path: "/privacy-policy",
        element: <Policy />,
    },
    {
        path: "/terms",
        element: <Terms />,
    },
    {
        path: "/practice-detail/:id/:detail",
        element: <DetailPractice />,
    },

    {
        path: "*",
        element: <Homepage />,
    },
];

export default AppRoutes;
