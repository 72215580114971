import BaseServices from "./BaseServices";

const AccountServices = {
    GetListUser: async (paging) => {
        return await BaseServices.Post("/api/Account/get-list-user", paging);
    },
    GetAgencyUserByUserId: async () => {
        return await BaseServices.Get(`/api/Account/get-agency-user-by-user-id`);
    },
    UpdateAgencyUser: async (model) => {
        return await BaseServices.Post(`/api/Account/update-agency-user`, model);
    },
    ConfirmEmail: async (url) => {
        return await BaseServices.Post(`/api/Account/confirm-email` + url, null);
    },
    GetRolesByUser: async () => {
        return await BaseServices.Get("/api/Account/get-roles");
    },
    UpdateIdentificationImage: async (name, value) => {
        return await BaseServices.Post(`/api/Account/update-identification-image?name=${name}&value=${value}`, null);
    },
    OAuthDialogSocial: async (social) => {
        return await BaseServices.Get(`/api/Account/oauth-dialog-social?social=${social}`);
    },
    LoginSocial: async (param, social) => {
        return await BaseServices.Get(`/api/Account/login-social` + param + "&social=" + social);
    },
    GetInfoUser: async () => {
        return await BaseServices.Post(`/api/Account/get-info-user`);
    },
    GetPolicy: async () => {
        return await BaseServices.Get(`/api/Account/get-html-policy`);
    },
};

export default AccountServices;
