const initState = {
    muscleList: [],
    sportList: [],
    userId: 0,
};

function reducer(state, action) {
    switch (action.type) {
    }
}

export { initState };
export default reducer;
