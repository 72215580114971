import styled from "styled-components";
import { Device } from "../../based/ConfigIconSvg";
import { useEffect, useState } from "react";
import { set } from "date-fns";
import DeviceServices from "../../based/services/DeviceServices";
import PlaceDeviceServices from "../../based/services/PlaceDeviceServices";
const DeviceItemScroll = ({ onChanged }) => {
    const [selectedDeviceType, setSelectedDeviceType] = useState();
    const [selectedListDevice, setSelectedListDevice] = useState([]);
    const [listDevice, setListDevice] = useState([]);
    const [listDeviceType, setListDeviceType] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [paging, setPaging] = useState({
        pageSize: 15,
        pageNumber: 1,
    });
    useEffect(() => {
        _getDeviceByPaging();
    }, []);
    const _getDeviceByPaging = async () => {
        const [err1, data1] = await DeviceServices.GetAllDeviceType();

        if (!err1) {
            setListDeviceType(data1);
            handleSearchDevice(data1[0]);
            setSelectedDeviceType(data1[0]);
        }
    };

    const handleSearchDevice = async (item) => {
        setSelectedDeviceType(item);
        const [err, data] = await DeviceServices.GetDevicePagingByKeyWord({
            ...paging,
            keyword: `${item.id}`,
        });
        if (!err) {
            setListDevice(data.listObjects);
        }
    };

    return (
        <>
            <DeviceTypeWrapper>
                {listDeviceType &&
                    listDeviceType.map((item, index) => (
                        <DeviceType
                            onClick={() => {
                                handleSearchDevice(item);
                            }}
                            key={index}
                            bg={selectedDeviceType === item ? "rgba(3, 82, 254, 0.30);" : "#4B4B4B;"}
                        >
                            <IconDevice>
                                <img alt="#" src={item.thumbnail} />
                            </IconDevice>
                            <DetailDevice>
                                <TitleDevice>{item.name}</TitleDevice>
                                <CountDevice></CountDevice>
                            </DetailDevice>
                        </DeviceType>
                    ))}
            </DeviceTypeWrapper>
            <ListItemWrapperScroll>
                {listDevice &&
                    listDevice.map((item, index) => (
                        <DeviceItemWrapper
                            onClick={() => {
                                if (selectedListDevice.includes(item)) {
                                    setSelectedListDevice(selectedListDevice.filter((itemFilter) => item !== itemFilter));
                                } else {
                                    setSelectedListDevice([...selectedListDevice, item]);
                                }
                                onChanged([...selectedListDevice, item]);
                            }}
                            bg={selectedListDevice.includes(item) ? "#0352FE" : "#4B4B4B;"}
                        >
                            {" "}
                            <WaterMarker isShow={selectedListDevice.includes(index)}>{index + 1}</WaterMarker>
                            <DeviceItem>
                                <ImageDeviceItem src={item.thumbnail}></ImageDeviceItem>
                            </DeviceItem>
                            <DeviceTitle>{item.name}</DeviceTitle>
                        </DeviceItemWrapper>
                    ))}
            </ListItemWrapperScroll>
        </>
    );
};

const DeviceTypeWrapper = styled.div`
    width: 90%;
    height: auto;
    margin: 0 auto;
    padding: 12px;
    display: flex;
    overflow-x: scroll;
    padding-bottom: 12px;
`;
const DeviceType = styled.div`
    display: flex;
    margin-right: 2%;
    height: 40px;
    max-height: 60px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid rgba(184, 229, 255, 0.2);
    background: ${(props) => props.bg};
    padding: 11px 14px;
`;
const CountDevice = styled.p`
    color: rgba(184, 229, 255, 0.5);
    font-family: "Be Vietnam Pro";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
`;
const IconDevice = styled.div`
    width: 30%;
    margin-right: 12px;
    img {
        width: 24px;
    }
`;
const DetailDevice = styled.div`
    height: 100%;
    width: 70%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
`;

const TitleDevice = styled.p`
    color: #b8e5ff;
    margin: 0;
    width: 100%;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const ListItemWrapperScroll = styled.div`
    width: 100vw;
    padding: 0 12px;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    max-height: 50vh;

    overflow: scroll;
`;

const DeviceItem = styled.div`
    width: 100%;
    height: 80%;
`;

const DeviceItemWrapper = styled.div`
    width: 32%;
    border-radius: 12px;
    margin-right: 1%;
    margin-bottom: 2%;
    border: 1px solid #484848;
    background: ${(props) => props.bg};
    height: auto;
    padding: 6px 10px;
    position: relative;
    padding-bottom: 20px;
`;
const ImageDeviceItem = styled.img`
    width: 100%;
    height: 90%;
    border-radius: 8px;
`;
const DeviceTitle = styled.p`
    color: #d9d9d9;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
`;
const WaterMarker = styled.div`
    position: absolute;
    text-align: center;
    color: #fff;
    display: ${(props) => (props.isShow ? "block" : "none")};
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    top: 0;
    left: 0;
    background-color: #0352fe;
    width: 29px;
    height: 20px;
    flex-shrink: 0;
    border-radius: 4px 0px 123px 70px;
    transition: 0.3s all ease;
`;

export { DeviceItemScroll };
