// muscleIcons.js
import icon_nguc from "../assets/icon/nguc.svg";
import icon_vai from "../assets/icon/vai.svg";
import icon_lung from "../assets/icon/lung.svg";
import icon_tay from "../assets/icon/tay.svg";
import icon_tim from "../assets/icon/heart.svg";
import icon_dui from "../assets/icon/dui.svg";
import icon_bung from "../assets/icon/bung.svg";

import chaybo from "../assets/icon/chaybo.svg";
import dapxe from "../assets/icon/dapxe.svg";
import boxing from "../assets/icon/boxing.svg";
import caulong from "../assets/icon/caulong.svg";
import bongda from "../assets/icon/bongda.svg";
import bongban from "../assets/icon/bongban.svg";
import bongchuyen from "../assets/icon/bongchuyen.svg";
import bongro from "../assets/icon/bongro.svg";
import boiloi from "../assets/icon/boi.svg";


import chevronright from "../assets/icon/chevron-right.svg"
import chevronleft from "../assets/icon/chevron-left.svg"


import tick from "../assets/icon/tick.svg"
import tickdone from "../assets/icon/tickdone.svg"
import tickdone_green from "../assets/icon/tickdone_green.svg"
import tick_2 from "../assets/icon/tick-2.svg"
// icon common

import recycle from "../assets/icon/recycle.svg"
import edit from "../assets/icon/edit.svg"
import create from "../assets/icon/create.svg"
import copy from "../assets/icon/copy.svg"
import calendar from "../assets/icon/calendar.svg"
import file_text from "../assets/icon/file-text.svg"
import load from "../assets/icon/load.svg"
import deleteicon from "../assets/icon/delete.svg"
import start from "../assets/icon/start.svg"
import rice from "../assets/icon/rice.svg"
import fire_red   from "../assets/icon/fire-red.svg"
import user_2 from "../assets/icon/user-2.svg"
import walk from "../assets/icon/walk.svg"
import heart_2 from "../assets/icon/heart-2.svg"
import create_new from "../assets/icon/create-new.svg"
import warning from "../assets/icon/warning.svg"
import reload from "../assets/icon/reload.svg"
import details from "../assets/icon/details.svg" 
import add from "../assets/icon/add.svg"
import save from "../assets/icon/save.svg"
import tick_orange from "../assets/icon/tick-orange.svg"
import search from "../assets/icon/search.svg"
import icon_fire_dropset from "../assets/icon/fire-dropset.svg"
import warning_delete from "../assets/icon/warning-delete.svg"
import help_circle from "../assets/icon/help-circle.svg"
import pause from "../assets/icon/pause.svg"
import pause_orange from "../assets/icon/pause_orange.svg"
// icon navigation
import calendar2 from "../assets/icon/calendar-2.svg"
import coach from "../assets/icon/coach.svg"
import cucta from "../assets/icon/cucta.svg"
import dinhduong from "../assets/icon/dinhduong.svg"
import user from "../assets/icon/user.svg"





export const muscleIcons = [
    {
        id: 1,
        name: "Ngực",
        icon: icon_nguc,
    },
    {
        id: 2,
        name: "Vai, cổ",
        icon: icon_vai,
    },
    {
        id: 3,
        name: "Lưng",
        icon: icon_lung,
    },
    {
        id: 4,
        name: "Chân + Mông",
        icon: icon_dui,
    },
    {
        id: 5,
        name: "Bụng",
        icon: icon_bung,
    },
    {
        id: 6,
        name: "Tim",
        icon: icon_tim,
    },
    {
        id: 7,
        name: "Tay",
        icon: icon_tay,
    },
];

export const sportIcon = [
    {
        id: 1,
        uid:8,
        name: "Chạy bộ",
        icon: chaybo,
    },
    {
        id: 2,
        uid:9,
        name: "Đạp xe",
        icon: dapxe,
    },
    {
        id: 3,
        uid:10,
        name: "Võ/Boxing",
        icon: boxing,
    },
    {
        id: 4,
        uid:11,
        name: "Cầu Lông",
        icon: caulong,
    },
    {
        id: 5,
        uid:12,
        name: "Bóng đá",
        icon: bongda,
    },
    {
        id: 6,
        uid:13,
        name: "Bóng bàn",
        icon: bongban,
    },
    {
        id: 7,
        uid:14,
        name: "Bóng chuyền",
        icon: bongchuyen,
    },
    {
        id: 8,
        uid:15,
        name: "Bóng rổ",
        icon: bongro,
    },
    {
        id: 9,
        uid:16,
        name: "Bơi lội",
        icon: boiloi,
    },
];
export const Arrow = {
        chevronright:chevronright,
        chevronleft:chevronleft,
    }


export const Tick = {
    tick:tick,
    tickdone:tickdone,
    tickdone_green:tickdone_green,
    tick_2: tick_2
}
export const IconCommon = {
    recycle: recycle,
    copy: copy,
    create: create,
    edit: edit,
    calendar: calendar,
    file_text: file_text,
    load:load,
    deleteicon :deleteicon,
    start:start,
    rice:rice,
    fire_red: fire_red,
    user_2:user_2,
    heart_2:heart_2,
    walk:walk,
    create_new:create_new,
    warning:warning,
    reload:reload,
    details:details,
    add:add,
    save:save,
    tick_orange:tick_orange,
    search:search,
    fire_dropset: icon_fire_dropset,
    warning_delete: warning_delete,
    help_circle: help_circle,
    pause: pause,
    pause_orange: pause_orange
}

export const IconNavigation = {
    calendar2: calendar2,
    coach: coach,
    exercise: cucta,
    nutrition: dinhduong,
    user: user
}

