import styled from "styled-components";
import { Arrow } from "../based/IconShared";
import { IconCommon, Tick } from "../based/IconShared";
import { useEffect, useState, useRef } from "react";
import ModalIntroduce from "./ModalIntroduce";
import ModalHowToDo from "./ModalHowToDo";
import { CheckProcess, CheckProcessPractice, Warning, Add } from "../based/ConfigIconSvg";
import ExerciseServices from "../based/services/ExerciseServices";
import ProcessPlanHistoryExerciseServices from "../based/services/ProcessPlanHistoryExerciseServices";
import PlanServices from "../based/services/PLanServices";
import { useParams, useNavigate } from "react-router-dom";
import { GymExerciseImage } from "../based/GymExerciseShared";
const configStatusName = {
    1: "Hoàn thành",
    2: "Đang thực hiện",
};
const configTypeExercise = {
    1: "Isolation",
    2: "Compound",
    3: "Superset",
};

const DetailPractice = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { detail } = useParams();
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [isOpenModalIntroduce, setIsOpenModalIntroduce] = useState(false);
    const [isOpenModalHowToDo, setIsOpenModalHowToDo] = useState(false);
    const [selectedType, setSelectedType] = useState(1); // 1: maxkg, 2: dropset, 3: %max
    const [exercise, setExercise] = useState(1);
    const [listSetDone, setListSetDone] = useState([]);
    const [planHistory, setPlanHistory] = useState([]);
    const [FlagChange, setFlagChange] = useState(false);
    const [FlagDone, setFlagDone] = useState(false);
    const [countDoneItem, setCountDoneItem] = useState(0);
    const [maxKgOfUser, setMaxKgOfUser] = useState(0);

    async function GetUnitForExercise(id) {
        const [err, data] = await ExerciseServices.GetUnitForOneExercise(id);
        if (!err) {
            const sets = data.sets;

            var tempArray = [];
            var itemPush = {};
            for (let i = 0; i < sets; i++) {
                itemPush = {
                    sets: i + 1,
                    reps: data.reps,
                    kg: 0,
                    percentMaxKg: data.percentMaxKg,
                    planHistoryExerciseId: detail,
                    isDone: false,
                };
                const [err2, data2] = await ProcessPlanHistoryExerciseServices.CreateUpdateProcessPlanHistoryExercise(itemPush);
                if (!err2) {
                    itemPush.id = data2;
                    tempArray.push(itemPush);
                }
            }
            const count = tempArray.filter((item) => item.isDone === true).length;
            setCountDoneItem(count);
            setListSetDone(tempArray);
        }
    }

    useEffect(() => {
        CheckDone();
    }, [listSetDone]);

    const CheckDone = async () => {
        const count = listSetDone.filter((item) => item.isDone === true).length;
        if (count === listSetDone.length) {
            setFlagDone(true);
            setPlanHistory((prevPlanHistory) => ({
                ...prevPlanHistory,
                isDone: true,
            }));
        } else {
            setFlagDone(false);
            setPlanHistory((prevPlanHistory) => ({
                ...prevPlanHistory,
                isDone: false,
            }));
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const [err, data] = await PlanServices.GetPlanHistoryExerciseById(detail);
            if (!err) {
                console.log("data", data);
                setPlanHistory(data.planHistoryExercise);
                setExercise(data.exercise);
                const [err2, data2] = await ProcessPlanHistoryExerciseServices.GetProcessPlanHistoryExerciseByProcessPlanHistoryId(detail);
                if (!err2) {
                    if (data2.length > 0) {
                        setListSetDone(data2);
                        const count = data2.filter((item) => item.isDone === true).length;
                        setCountDoneItem(count);
                    } else {
                        GetUnitForExercise(data.planHistoryExercise.exerciseId);
                    }
                }
            } else {
                console.log(err);
            }
        };

        fetchData();
    }, [id]);

    const GetListExerciseByPlanHistoryId = async (id) => {};

    async function GetProcessExercise(id) {}

    const handleOpenModalIntroduce = () => {
        setIsOpenModalIntroduce(true);
    };

    const handleCloseModalIntroduce = () => {
        setIsOpenModalIntroduce(false);
    };

    const handleSaveModalIntroduce = (data) => {
        setIsOpenModalIntroduce(false);
    };

    const handleOpenModalHowToDo = () => {
        setIsOpenModalHowToDo(true);
    };

    const handleCloseModalHowToDo = () => {
        setIsOpenModalHowToDo(false);
    };

    const handleSaveModalHowToDo = (data) => {
        setIsOpenModalHowToDo(false);
    };

    const [statusDropActive, setStatusDropActive] = useState(false);

    const handleDone = () => {
        updateListSetDoneAPI();
    };

    const updateListSetDoneAPI = async () => {
        for (let i = 0; i < listSetDone.length; i++) {
            const [err, data] = await ProcessPlanHistoryExerciseServices.UpdateIsDoneProcessPlanHistoryExercise(listSetDone[i].id, listSetDone[i].isDone);
            const [err3, data3] = await ProcessPlanHistoryExerciseServices.CreateUpdateProcessPlanHistoryExercise(listSetDone[i]);

            if (!err) {
                console.log("update success", data);
            } else {
                console.log(err);
            }
        }

        const [err2, data2] = await PlanServices.UpdateIsDonePlanHistoryExercise(planHistory.id, FlagDone);
        if (!err2) {
            // window.location.href = `/practice/${}/${id}`;
            navigate(-1);
        } else {
            console.log(err2);
        }
    };

    const CaculateMaxKG = () => {
        if (listSetDone.length > 0) {
            const maxKg = listSetDone.reduce((max, p) => (p.kg > max ? p.kg : max), listSetDone[0].kg);
            return maxKg;
        } else {
            return 0;
        }
    };

    return (
        <>
            <DetailPracticeWrapper>
                <ModalIntroduce isOpen={isOpenModalIntroduce} onClose={handleCloseModalIntroduce} onSave={handleSaveModalIntroduce} selectedType={selectedType}></ModalIntroduce>

                <ModalHowToDo isOpen={isOpenModalHowToDo} onClose={handleCloseModalHowToDo} onSave={handleSaveModalHowToDo}></ModalHowToDo>
                <DetailPracticeInner>
                    <DetailPracticeHeader bg={planHistory && planHistory.isDone ? " linear-gradient(rgb(0, 248, 158, 0.2) 0%, rgba(0, 248, 158, 0) 100%)" : "linear-gradient(180deg, rgba(255, 110, 29, 0.2) 0%, rgba(255, 110, 29, 0.00) 100%);"}>
                        <DetailPracticeHeaderTop>
                            <IconBack onClick={() => navigate(-1)} src={Arrow.chevronleft}></IconBack>

                            <DetailPracticeHeaderRightWrapper>
                                <IconClose onClick={() => navigate(-1)} src={IconCommon.deleteicon}></IconClose>
                            </DetailPracticeHeaderRightWrapper>
                        </DetailPracticeHeaderTop>

                        {exercise && (
                            <DPHeaderCenter>
                                <HeaderContentLeftWrapper>
                                    <ButtonLeft bg={exercise.exerciseTypeId === 1 ? "#FF5C00" : "#0352FE"}>{configTypeExercise[exercise.exerciseTypeId]}</ButtonLeft>
                                    <ContentLeft>{exercise.name}</ContentLeft>
                                    <SubContentLeft>{exercise.nameEN}</SubContentLeft>
                                </HeaderContentLeftWrapper>
                                <HeaderContentRightWrapper>
                                    <TitleRightWrapper>
                                        <TitleRight color={planHistory.isDone ? "#00F89E" : "#FF5C00"}>
                                            {planHistory.isDone === true ? "Hoàn thành " : "Đang thực hiện"}
                                            <span>
                                                <CheckProcess active={planHistory.isDone} />
                                            </span>
                                        </TitleRight>
                                    </TitleRightWrapper>
                                    <ContentRight>
                                        <ImageWrapper onClick={handleOpenModalHowToDo}>
                                            <ImageWrapperParent>
                                                {" "}
                                                <ImageRight src={exercise.thumbnail}></ImageRight>
                                                <IconPause src={IconCommon.pause}></IconPause>
                                            </ImageWrapperParent>
                                        </ImageWrapper>
                                    </ContentRight>
                                </HeaderContentRightWrapper>
                            </DPHeaderCenter>
                        )}
                        {/* {exercise.exerciseTypeId === 1 ? (
                            <></>
                        ) : exercise.exerciseTypeId === 2 ? (
                            <>
                                <DPHeaderCenter>
                                    <HeaderContentLeftWrapper>
                                        <ButtonLeft>Compound</ButtonLeft>
                                        <ContentLeft>Đẩy tạ đôi trên ghế phẳng</ContentLeft>
                                        <SubContentLeft>Dumbbell bench press</SubContentLeft>
                                    </HeaderContentLeftWrapper>
                                    <HeaderContentRightWrapper>
                                        <TitleRightWrapper>
                                            <TitleRight color="#FF5C00;">Đang thực hiện</TitleRight>
                                            <IconTitle src={IconCommon.pause_orange}></IconTitle>
                                        </TitleRightWrapper>
                                        <ContentRight>
                                            <ImageWrapper onClick={handleOpenModalHowToDo}>
                                                <ImageRight src={GymExerciseImage.barbell_rectangle}></ImageRight>
                                                <IconPause src={IconCommon.pause}></IconPause>
                                            </ImageWrapper>
                                        </ContentRight>
                                    </HeaderContentRightWrapper>
                                </DPHeaderCenter>
                            </>
                        ) : (
                            <>
                                <DPHeaderCenterSuperSet>
                                    <HeaderContentLeftWrapperSuperSet>
                                        <ButtonLeft>Superset</ButtonLeft>

                                        <TitleRightWrapper>
                                            <TitleRight color="#FF5C00;">Đang thực hiện</TitleRight>
                                            <IconTitle src={IconCommon.pause_orange}></IconTitle>
                                        </TitleRightWrapper>
                                    </HeaderContentLeftWrapperSuperSet>
                                    <ContentLeft>Superset Cơ liên sườn</ContentLeft>
                                    <ItemSuperSetWrapper
                                        bg="linear-gradient(180deg, rgba(0, 248, 158, 0.2)
 0%, rgba(0, 248, 158, 0.00) 100%);"
                                    >
                                        <MarkNumber>1</MarkNumber>
                                        <ImageSuperSet src={GymExerciseImage.barbell}></ImageSuperSet>
                                        <TitleSuperSet>Đẩy tạ đôi trên ghế phẳng</TitleSuperSet>
                                        <MaxRepWrapper>
                                            <TitleMax>Max rep</TitleMax>
                                            <NumberMax>12</NumberMax>
                                        </MaxRepWrapper>
                                        <MaxKgWrapper>
                                            <TitleMax>Max kg</TitleMax>
                                            <NumberMax>12</NumberMax>
                                        </MaxKgWrapper>
                                    </ItemSuperSetWrapper>
                                </DPHeaderCenterSuperSet>
                            </>
                        )} */}
                    </DetailPracticeHeader>

                    <DetailPracticeBody>
                        <BodyHeader>
                            <BHItemLeftWrapper>
                                <SubTitle>Max rep</SubTitle>
                                <Title>12</Title>
                            </BHItemLeftWrapper>
                            <BHItemRightWrapper>
                                <SubTitle>Max Kg</SubTitle>
                                <Title>15</Title>
                            </BHItemRightWrapper>

                            <IconHelpCircle
                                onClick={() => {
                                    setSelectedType(1);
                                    handleOpenModalIntroduce();
                                }}
                                src={IconCommon.help_circle}
                            ></IconHelpCircle>
                        </BodyHeader>

                        <ActionPractice>
                            <ActionLeft>
                                <IconAction
                                    onClick={() => {
                                        const fetchData = async () => {
                                            const formAddAPI = {
                                                sets: listSetDone.length + 1,
                                                reps: 0,
                                                kg: 0,
                                                percentMaxKg: 0,
                                                planHistoryExerciseId: detail,
                                                isDone: false,
                                            };
                                            const [err, data] = await ProcessPlanHistoryExerciseServices.CreateUpdateProcessPlanHistoryExercise(formAddAPI);
                                            if (!err) {
                                                formAddAPI.id = data;
                                                setListSetDone([...listSetDone, formAddAPI]);
                                            }
                                        };
                                        fetchData();
                                    }}
                                    src={IconCommon.add}
                                ></IconAction>
                                <TitleAction>Thêm Set</TitleAction>
                            </ActionLeft>
                            <ActionRight onClick={() => setStatusDropActive(!statusDropActive)}>
                                <IconAction src={IconCommon.fire_dropset}></IconAction>
                                <TitleAction>Dropset</TitleAction>
                                <IconHelpCircleDrop
                                    onClick={() => {
                                        setSelectedType(2);
                                        handleOpenModalIntroduce();
                                    }}
                                    src={IconCommon.help_circle}
                                ></IconHelpCircleDrop>
                            </ActionRight>
                        </ActionPractice>

                        <BodyCenter>
                            <TopItem>
                                <ItemFirst>
                                    <TitleTable>Set</TitleTable>
                                </ItemFirst>
                                <ItemSecond>
                                    <TitleTable>Rep</TitleTable>
                                </ItemSecond>
                                <ItemThree>
                                    <TitleTable>Kg</TitleTable>
                                </ItemThree>
                                <ItemFour>
                                    <TitleTable>
                                        % Max
                                        <IconHelpCircleMax
                                            onClick={() => {
                                                setSelectedType(3);
                                                handleOpenModalIntroduce();
                                            }}
                                            src={IconCommon.help_circle}
                                        ></IconHelpCircleMax>
                                    </TitleTable>
                                </ItemFour>
                                <ItemFive>
                                    <IconTick src={Tick.tick_2}></IconTick>
                                </ItemFive>
                            </TopItem>

                            {FlagChange
                                ? listSetDone
                                      .slice()
                                      .reverse()
                                      .map((item, index) => (
                                          <CenterItem bg={item.isDone ? "#29604C" : "#503526"} key={item.id}>
                                              <ItemFirst>{item.sets}</ItemFirst>
                                              <ItemSecond>
                                                  <InputItem
                                                      type="number"
                                                      value={item.reps}
                                                      onChange={(e) => {
                                                          const updatedListSetDone = listSetDone.map((listItem) => {
                                                              if (listItem.id === item.id) {
                                                                  return { ...listItem, reps: parseInt(e.target.value) };
                                                              }
                                                              return listItem;
                                                          });
                                                          setListSetDone(updatedListSetDone);
                                                      }}
                                                  />
                                              </ItemSecond>
                                              <ItemThree>
                                                  <InputItem
                                                      type="number"
                                                      value={item.kg}
                                                      onChange={(e) => {
                                                          const updatedListSetDone = listSetDone.map((listItem) => {
                                                              if (listItem.id === item.id) {
                                                                  return { ...listItem, kg: parseFloat(e.target.value) };
                                                              }
                                                              return listItem;
                                                          });
                                                          setListSetDone(updatedListSetDone);
                                                      }}
                                                  />
                                              </ItemThree>
                                              <ItemFour>
                                                  <InputItem
                                                      type="number"
                                                      value={item.percentMaxKg * 100}
                                                      onChange={(e) => {
                                                          const updatedListSetDone = listSetDone.map((listItem) => {
                                                              if (listItem.id === item.id) {
                                                                  return { ...listItem, percentMaxKg: parseInt(e.target.value) / 100 };
                                                              }
                                                              return listItem;
                                                          });
                                                          setListSetDone(updatedListSetDone);
                                                      }}
                                                  />
                                              </ItemFour>
                                              <ItemFive
                                                  onClick={() => {
                                                      const fetchData = async () => {
                                                          const [err, data] = await ProcessPlanHistoryExerciseServices.RemoveProcessPlanHistoryExercise(item.id);
                                                          if (!err) {
                                                              console.log("update success", data);
                                                          } else {
                                                              console.log(err);
                                                          }
                                                      };
                                                      fetchData();

                                                      const updatedListSetDone = listSetDone.indexOf(item) > -1 ? listSetDone.filter((listItem) => listItem.sets !== item.sets) : [...listSetDone, item];
                                                      setListSetDone(updatedListSetDone);
                                                  }}
                                              >
                                                  <ItemFiveIconWrapper>
                                                      <Warning />
                                                  </ItemFiveIconWrapper>
                                              </ItemFive>
                                          </CenterItem>
                                      ))
                                : listSetDone
                                      .slice()
                                      .reverse()
                                      .map((item, index) => (
                                          <CenterItem bg={item.isDone ? "#29604C" : ""} key={item.id}>
                                              <ItemFirst onClick={() => setFlagChange(true)}>{item.sets}</ItemFirst>
                                              <ItemSecond onClick={() => setFlagChange(true)}>
                                                  <ShowItem onClick={() => setFlagChange(true)} disabled={true}>
                                                      {item.reps}
                                                  </ShowItem>
                                              </ItemSecond>
                                              <ItemThree onClick={() => setFlagChange(true)}>
                                                  <ShowItem disabled={true} onClick={() => setFlagChange(true)}>
                                                      {item.kg}
                                                  </ShowItem>
                                              </ItemThree>
                                              <ItemFour onClick={() => setFlagChange(true)}>
                                                  <ShowItem disabled={true} onClick={() => setFlagChange(true)}>
                                                      {item.percentMaxKg * 100}
                                                  </ShowItem>
                                              </ItemFour>
                                              <ItemFive
                                                  onClick={() => {
                                                      const updatedListSetDone = listSetDone.map((listItem) => {
                                                          if (listItem.sets === item.sets) {
                                                              return { ...listItem, isDone: !listItem.isDone };
                                                          }
                                                          return listItem;
                                                      });
                                                      setListSetDone(updatedListSetDone);
                                                  }}
                                              >
                                                  <ItemFiveIconWrapper>
                                                      {" "}
                                                      <CheckProcessPractice active={item.isDone}></CheckProcessPractice>{" "}
                                                  </ItemFiveIconWrapper>
                                              </ItemFive>
                                          </CenterItem>
                                      ))}
                        </BodyCenter>
                        <BodyNote2 onClick={() => setFlagChange(false)}>
                            <TitleNote>* Nhấn vào nơi bất kì ngoài bảng để thoát chỉnh sửa.</TitleNote>
                        </BodyNote2>

                        <BodyNote>
                            <TitleNote>Ghi chú</TitleNote>
                            <InputNote></InputNote>
                        </BodyNote>
                    </DetailPracticeBody>
                    <DetailPracticeFooter>
                        <ActionButton bg={planHistory.isDone ? "linear-gradient(87deg, #00F89E 0%, #29604C 100%)" : ""} onClick={() => handleDone()}>
                            {planHistory.isDone ? "Hoàn thành" : "Tạm nghỉ"}
                        </ActionButton>
                    </DetailPracticeFooter>
                    <SuperSetTriSetDetail type="superset" />
                </DetailPracticeInner>
            </DetailPracticeWrapper>
        </>
    );
};

export default DetailPractice;

const SuperSetTriSetDetail = ({ type }) => {
    const configSuperSet = [
        {
            id: 1,
            image: GymExerciseImage.barbell,
            title: "Đẩy tạ đôi trên ghế phẳng",
            maxRep: 12,
            maxKg: 12,
        },
        {
            id: 2,
            image: GymExerciseImage.barbell,
            title: "Đẩy tạ đôi trên ghế phẳng",
            maxRep: 12,
            maxKg: 12,
        },
        {
            id: 2,
            image: GymExerciseImage.barbell,
            title: "Đẩy tạ đôi trên ghế phẳng",
            maxRep: 12,
            maxKg: 12,
        },
    ];
    return (
        <DPHeaderCenterSuperSet>
            <HeaderContentLeftWrapperSuperSet>{type === "superset" ? <ButtonLeft bg="#F5002C">Superset</ButtonLeft> : <ButtonLeft bg="#AF00EC"> TriSet</ButtonLeft>}</HeaderContentLeftWrapperSuperSet>
            <ContentLeft>Superset Cơ liên sườn</ContentLeft>

            {/* <ItemSuperSetWrapper
                bg="linear-gradient(180deg, rgba(0, 248, 158, 0.2)
 0%, rgba(0, 248, 158, 0.00) 100%);"
            >
                <MarkNumber>1</MarkNumber>
                <ImageSuperSet src={GymExerciseImage.barbell}></ImageSuperSet>
                <TitleSuperSet>Đẩy tạ đôi trên ghế phẳng</TitleSuperSet>
                <MaxRepWrapper>
                    <TitleMax>Max rep</TitleMax>
                    <NumberMax>12</NumberMax>
                </MaxRepWrapper>
                <MaxKgWrapper>
                    <TitleMax>Max kg</TitleMax>
                    <NumberMax>12</NumberMax>
                </MaxKgWrapper>
            </ItemSuperSetWrapper> */}
            <WrapperSuperSetTriSet>
                {configSuperSet.map((item, index) => (
                    <ItemSuperSetWrapper
                        bg={
                            index === 0
                                ? "linear-gradient(180deg, rgba(0, 248, 158, 0.2) 0%, rgba(0, 248, 158, 0.00) 100%);"
                                : index === 1
                                ? " linear-gradient(180deg, rgba(255,184,0, 0.2) 0%, rgba(0, 248, 158, 0.00) 100%);  "
                                : "linear-gradient(180deg, rgba(245,0,44, 0.2) 0%, rgba(0, 248, 158, 0.00) 100%);"
                        }
                    >
                        <MarkNumber colorText={index === 0 ? "#00F89E" : index === 1 ? "#FFC700" : "#F5002C"} bg={index === 0 ? "#20513F" : index === 1 ? " #62471D" : "#5F2426"}>
                            {index + 1}
                        </MarkNumber>
                        <p>
                            <ImageSuperSet src={item.image}></ImageSuperSet>
                            <TitleSuperSet>{item.title}</TitleSuperSet>
                            <MaxRepWrapper>
                                <TitleMax>Max rep</TitleMax>
                                <NumberMax>{item.maxRep}</NumberMax>
                            </MaxRepWrapper>
                            <MaxKgWrapper>
                                <TitleMax>Max kg</TitleMax>
                                <NumberMax>{item.maxKg}</NumberMax>
                            </MaxKgWrapper>
                        </p>
                    </ItemSuperSetWrapper>
                ))}
            </WrapperSuperSetTriSet>
            <SetTitle>Set 1</SetTitle>
            <BodyCenter2>
                <TopItem>
                    <ItemFirst>
                        <TitleTable>Set</TitleTable>
                    </ItemFirst>
                    <ItemSecond>
                        <TitleTable>Rep</TitleTable>
                    </ItemSecond>
                    <ItemThree>
                        <TitleTable>Kg</TitleTable>
                    </ItemThree>
                    <ItemFour>
                        <TitleTable>
                            % Max
                            <IconHelpCircleMax
                                // onClick={() => {
                                //     setSelectedType(3);
                                //     handleOpenModalIntroduce();
                                // }}
                                src={IconCommon.help_circle}
                            ></IconHelpCircleMax>
                        </TitleTable>
                    </ItemFour>
                    <ItemFive>
                        <IconTick src={Tick.tick_2}></IconTick>
                    </ItemFive>
                </TopItem>

                {configSuperSet.map((item, index) => (
                    <CenterItem2
                        bg={
                            index === 0
                                ? "linear-gradient(90deg, rgb(0, 248, 158, 0.3) 0%, rgba(0, 248, 158, 0) 100%)"
                                : index === 1
                                ? "linear-gradient(90deg, rgb(255, 184, 0, 0.3) 0%, rgba(255, 184, 0, 0) 100%);"
                                : " linear-gradient(90deg, rgb(245, 0, 44,0.3) 0%, rgba(245, 0, 44, 0) 100%); "
                        }
                    >
                        <ItemFirstSuperSetTriSet colorText={index === 0 ? "#00F89E" : index === 1 ? "#FFC700" : "#F5002C"}>12</ItemFirstSuperSetTriSet>
                        <ItemSecond>
                            <InputItem type="number" />
                        </ItemSecond>
                        <ItemThree>
                            <InputItem type="number" />
                        </ItemThree>
                        <ItemFour>
                            <InputItem type="number" />
                        </ItemFour>
                        <ItemFive>
                            <ItemFiveIconWrapper>
                                <CheckProcessPractice active={false} />
                            </ItemFiveIconWrapper>
                        </ItemFive>
                    </CenterItem2>
                ))}

                {/* : listSetDone
                          .slice()
                          .reverse()
                          .map((item, index) => (
                              <CenterItem bg={item.isDone ? "#29604C" : ""} key={item.id}>
                                  <ItemFirst onClick={() => setFlagChange(true)}>{item.sets}</ItemFirst>
                                  <ItemSecond onClick={() => setFlagChange(true)}>
                                      <ShowItem onClick={() => setFlagChange(true)} disabled={true}>
                                          {item.reps}
                                      </ShowItem>
                                  </ItemSecond>
                                  <ItemThree onClick={() => setFlagChange(true)}>
                                      <ShowItem disabled={true} onClick={() => setFlagChange(true)}>
                                          {item.kg}
                                      </ShowItem>
                                  </ItemThree>
                                  <ItemFour onClick={() => setFlagChange(true)}>
                                      <ShowItem disabled={true} onClick={() => setFlagChange(true)}>
                                          {item.percentMaxKg * 100}
                                      </ShowItem>
                                  </ItemFour>
                                  <ItemFive
                                      onClick={() => {
                                          const updatedListSetDone = listSetDone.map((listItem) => {
                                              if (listItem.sets === item.sets) {
                                                  return { ...listItem, isDone: !listItem.isDone };
                                              }
                                              return listItem;
                                          });
                                          setListSetDone(updatedListSetDone);
                                      }}
                                  >
                                      <ItemFiveIconWrapper>
                                          {" "}
                                          <CheckProcessPractice active={item.isDone}></CheckProcessPractice>{" "}
                                      </ItemFiveIconWrapper>
                                  </ItemFive>
                              </CenterItem> */}
            </BodyCenter2>
            <ButtonAdd>
                <Add />
                Thêm set
            </ButtonAdd>
        </DPHeaderCenterSuperSet>
    );
};

const SetTitle = styled.p`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-left: 5%;
    line-height: normal;
`;

const ButtonAdd = styled.div`
    display: flex;
    width: 100px;
    height: 32px;
    position: absolute;
    padding: 20px 12px;
    bottom: 0;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #fff;
    border-radius: 20px;
    background: rgba(58, 58, 58, 0.5);
    backdrop-filter: blur(3.5px);
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const SetRep = styled.div``;
const ItemFirstSuperSetTriSet = styled.p`
    width: 15%;
    height: 100%;
    display: flex;

    justify-content: center;
    align-items: center;

    border-right: 1px solid rgba(72, 72, 72, 0.4);

    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    color: ${(props) => props.colorText};
    line-height: normal;
`;
const WrapperSuperSetTriSet = styled.div`
    display: flex;
`;

const DetailPracticeWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    position: fixed;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
`;

const DetailPracticeInner = styled.div`
    width: 100%;
    height: auto;
    max-height: 100vh;
    overflow-y: scroll;
    background-color: #3a3a3a;
    position: relative;
    background: #272727;
`;

const DetailPracticeHeader = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    align-items: center;
    flex-direction: column;

    background: ${(props) => props.bg};
`;

const DetailPracticeHeaderTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

const DetailPracticeHeaderTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    width: 100%;
    margin-top: 12px;

    flex-shrink: 0;
`;
const IconBack = styled.img``;

const DetailPracticeBody = styled.div`
    width: 100%;
    height: 100vh;
    padding: 10px 16px;
    margin-top: 10px;
`;

const CreateButton = styled.button`
    display: inline-flex;
    height: 42px;
    padding: 14px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 30px;
    border: 1px solid #484848;

    background: #3a3a3a;
`;

const ButtonIcon = styled.img``;
const ButtonTitle = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const DetailPracticeHeaderRightWrapper = styled.div``;

const IconClose = styled.img``;

const ActionCreateItem = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const DPHeaderCenter = styled.div`
    display: flex;
    width: 100%;
    margin-top: 5%;
`;

const HeaderContentLeftWrapper = styled.div`
    padding-left: 12px;
    width: 50vw;
`;

const ButtonLeft = styled.button`
    display: inline-flex;
    height: 24px;
    color: #fff;
    padding: 12px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid rgba(255, 197, 164, 0.2);
    background: ${(props) => props.bg};
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const ContentLeft = styled.div`
    color: #fff;
    width: 80%;
    margin: 10px 0px;
    font-family: "Be Vietnam Pro";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

const SubContentLeft = styled.div`
    color: #939393;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const HeaderContentRightWrapper = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
`;

const ContentRight = styled.div`
    width: 100%;
    position: relative;
`;
const ImageRight = styled.img`
    margin-right: 20px;
`;

const BodyHeader = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    margin: 0 auto;
`;
const configItemBHItem = styled.div`
    width: 50%;
    height: 48px;
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
`;
const BHItemLeftWrapper = styled(configItemBHItem)`
    border-radius: 16px 0px 0px 16px;
    border: 1px solid rgba(72, 72, 72, 0.4);
    background: rgba(58, 58, 58, 0.4);
`;

const BHItemRightWrapper = styled(configItemBHItem)`
    border-radius: 0px 16px 16px 0px;
    border: 1px solid rgba(72, 72, 72, 0.4);
    background: rgba(58, 58, 58, 0.4);
`;

const SubTitle = styled.h3`
    color: #939393;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const Title = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

const BodyCenter = styled.div`
    width: 100%;
    border-radius: 16px;
    border: 1px solid rgba(72, 72, 72, 0.4);
    background: rgba(58, 58, 58, 0.4);
    height: auto;
    max-height: 50%;
    overflow-y: scroll;
    margin-top: 10px;
`;

const TopItem = styled.div`
    width: 100%;
    display: flex;
    border-bottom: 1px solid rgba(72, 72, 72, 0.4);
    height: 49px;
`;

const CenterItem = styled.div`
    border-bottom: 1px solid rgba(72, 72, 72, 0.4);
    height: 48px;
    display: flex;

    background: ${(props) => props.bg};
    transition: background-color 0.3s ease;
`;

const BottomItem = styled.div`
    height: 49px;
    display: flex;
`;

const ItemFirst = styled.div`
    width: 15%;
    height: 100%;
    display: flex;

    justify-content: center;
    align-items: center;

    border-right: 1px solid rgba(72, 72, 72, 0.4);

    color: #878787;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;
const ItemConfig = styled.div`
    width: calc(70% / 3);
    height: 100%;
    border-right: 1px solid rgba(72, 72, 72, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

const ItemSecond = styled(ItemConfig)`
    color: #fff;

    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    input {
    }
`;

const ItemThree = styled(ItemConfig)``;

const ItemFour = styled(ItemConfig)`
    display: flex;
`;

const ItemFive = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    height: 100%;
`;

const ItemFiveIconWrapper = styled.div`
    border-radius: 20px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.bg};
`;
const IconTick = styled.img``;

const TitleTable = styled.h3`
    color: #939393;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const BodyNote = styled.div`
    background: rgba(39, 39, 39, 0.6);
    box-shadow: 0px 1px 0px 0px #3f3f3f;
    backdrop-filter: blur(4px);
    padding: 15px 15px;
`;
const TitleNote = styled.h3`
    color: #878787;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const InputNote = styled.input`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    flex-wrap: wrap;
    height: auto;
    background-color: #272727;
    border: none;
    outline: none;
    line-height: normal;
`;

const DetailPracticeFooter = styled.div`
    width: 100%;
    height: 10%;
    flex-shrink: 0;
    position: fixed;
    background: rgba(39, 39, 39, 0.8);
    box-shadow: 0px -1px 0px 0px #3f3f3f;
    backdrop-filter: blur(4px);
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ActionButton = styled.button`
    width: 148px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 50px;
    border: 1px solid #484848;
    background: #3a3a3a;
    background: ${(props) => props.bg};

    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    line-height: normal;
`;

const ActionPractice = styled.div`
    display: flex;
    margin-top: 16px;
    justify-content: space-between;
`;

const ActionLeft = styled.div`
    display: flex;
    align-items: center;
    width: 40%;
    margin-left: 10px;
    position: relative;
`;

const IconAction = styled.img`
    margin-right: 8px;
`;

const TitleAction = styled.h3`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const ActionRight = styled.div`
    display: flex;
    align-items: center;
    width: 40%;
    display: flex;
    justify-content: flex-end;
    margin-right: 16px;
    position: relative;
`;

const BodyNote2 = styled.div`
    margin-top: 12px;
`;
const IconHelpCircle = styled.img`
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    position: absolute;
    right: -5px;
    top: -5px;
`;
const IconHelpCircleDrop = styled.img`
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    margin-left: 10px;
`;

const IconHelpCircleMax = styled.img`
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    margin-left: 5px;
`;
const TitleRightWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;
    align-items: center;
`;
const TitleRight = styled.h3`
    color: ${(props) => props.color || ""};
    text-align: right;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    span {
        margin: 0 12px;
    }
`;
const IconTitle = styled.img`
    width: 16px;
    margin-right: 16px;
    height: 16px;
    flex-shrink: 0;
    margin-left: 5px;
`;

const IconPause = styled.img`
    position: absolute;
    left: 10%;
    top: 10px;
`;

const ImageWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
`;
const HeaderContentLeftWrapperSuperSet = styled(HeaderContentLeftWrapper)`
    display: flex;
    justify-content: space-between;
`;

const ItemSuperSetWrapper = styled.div`
    width: 30%;
    height: auto;
    margin: 0 10px;
    position: relative;
    background: ${(props) => props.bg};
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    p {
        width: 100%;
        img {
            width: 100%;
        }
        padding: 6px;
    }
`;

const MarkNumber = styled.div`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 12px 0px;
    background: ${(props) => props.bg};
    position: absolute;
    color: ${(props) => props.colorText};
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

const ImageSuperSet = styled.img``;

const TitleSuperSet = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const MaxRepWrapper = styled.div`
    display: flex;

    justify-content: space-between;
    border-bottom: 1px solid rgba(72, 72, 72, 0.4);
    border-top: 1px solid rgba(72, 72, 72, 0.4);
`;

const TitleMax = styled.h3`
    margin: 5px 0px;
    color: rgba(255, 255, 255, 0.6);
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const NumberMax = styled.h3`
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

const MaxKgWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;
const DPHeaderCenterSuperSet = styled(DPHeaderCenter)`
    display: flex;
    width: 100%;
    position: relative;

    height: auto;
    max-height: 100vh;
    overflow-y: scroll;
    margin-top: 5%;
    min-height: 70vh;

    flex-direction: column;
`;
const ImageWrapperParent = styled.div`
    position: relative;
    display: flex;
    width: fit-content;
`;
const InputItem = styled.input`
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: -webkit-fill-available;
    background-color: transparent;
    border: none;
    outline: none;
    color: #fff;
`;
const ShowItem = styled.div`
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: -webkit-fill-available;
    background-color: transparent;
    border: none;
    color: #fff;
`;
const CenterItem2 = styled(CenterItem)``;
const BodyCenter2 = styled(BodyCenter)`
    width: 95%;
    margin: 0 auto;
`;
