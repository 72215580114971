import BaseServices from "./BaseServices";

const PlaceServices = {
    GetAllPlace: async () => {
        return await BaseServices.Get(`/api/Place/get-all-place`);
    },
    CreateUpdatePlace: async (model) => {
        return await BaseServices.Post(`/api/Place/create-update-place`, model);
    },

    RemovePlaceById: async (id) => {
        return await BaseServices.Get(`/api/Place/remove-place-by-id/${id}`);
    },
    GetPlaceByPaging: async (paging) => {
        return await BaseServices.Post(`/api/Place/get-list-place-by-paging`, paging);
    },
    GetAllPlaceType: async () => {
        return await BaseServices.Get(`/api/Place/get-all-place-type`);
    },
    GetListPlaceByPlaceTypeId: async (id) => {
        return await BaseServices.Get(`/api/Place/get-place-by-place-type-id?placeTypeId=${id}`);
    },

    GetListProvince: async () => {
        return await BaseServices.Get(`/api/Location/get-list-province`);
    },
    GetListDistrict: async (id) => {
        return await BaseServices.Get(`/api/Location/get-list-district-by-province-id?id=${id}`);
    },
    GetPlaceByUserName: async () => {
        return await BaseServices.Get(`/api/Place/get-place-by-user-name`);
    },
    GetPlaceByDistrict: async (model) => {
        return await BaseServices.Post(`/api/Place/get-place-by-distrcit`, model);
    },
    GetPlaceByProvince: async (model) => {
        return await BaseServices.Post(`/api/Place/get-place-by-province`, model);
    },
    SearchSuggestions: async (model) => {
        return await BaseServices.Post(`/api/Place/search-suggest-place`, model);
    },
};

export default PlaceServices;
