import styled, { keyframes } from "styled-components";
import { muscleIcons, IconCommon, Arrow } from "../../../based/IconShared";
import { useEffect, useState } from "react";
import ModalSelectFolder from "./ModalSelectFolder";
import PlanServices from "../../../based/services/PLanServices";
import cookies from "react-cookies";

const ModalSaveSet = ({ isOpen, onClose, onSave, listExerciseSave }) => {
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [isOpenModalSelectFolder, setIsOpenModalSelectFolder] = useState(false);
    const [formSaveSet, setFormSaveSet] = useState({
        name: "",
        username: "",
        folderId: 0,
    });
    useEffect(() => {
        const userName = cookies.load("userName");
        setFormSaveSet({ ...formSaveSet, username: userName });
    }, [isOpen]);
    const handleOpenModalSelectFolder = () => {
        setIsOpenModalSelectFolder(true);
    };
    const handleCloseModalSelectFolder = () => {
        setIsOpenModalSelectFolder(false);
    };
    const handleSaveModalSelectFolder = (data) => {
        setIsOpenModalSelectFolder(false);
        setSelectedFolder(data);
        setFormSaveSet({ ...formSaveSet, folderId: data.id });
    };

    const handleOnSave = async () => {
        const [err, data] = await PlanServices.CreateUpdatePlanExercise(formSaveSet);
        if (!err) {
            const listExerciseId = listExerciseSave.map((item) => item.id);
            listExerciseId.forEach(async (item) => {
                const [err2, data2] = await PlanServices.CreateUpdatePlanExerciseDetail({
                    planExerciseId: data,
                    exerciseId: item,
                });
                if (!err2) {
                    console.log("data2", data2);
                } else {
                    console.log("err2", err2);
                }
            });
            onSave("success");
        } else {
            console.log("err", err);
        }
    };

    return (
        <>
            <ModalChangeWrapper style={{ display: isOpen ? "flex" : "none" }}>
                <ModalSelectFolder isOpen={isOpenModalSelectFolder} onClose={handleCloseModalSelectFolder} onSave={handleSaveModalSelectFolder}></ModalSelectFolder>
                <ModalWrapperInner>
                    <ModalHeader>
                        <HeaderTitle>Lưu buổi tập</HeaderTitle>
                        <HeaderAction src={IconCommon.deleteicon} onClick={onClose}></HeaderAction>
                    </ModalHeader>
                    <ModalBody>
                        <BodyItemTop>
                            <Title>Đặt tên *</Title>
                            <Input value={formSaveSet.name} onChange={(e) => setFormSaveSet({ ...formSaveSet, name: e.target.value })} placeholder="Nhập tên"></Input>
                        </BodyItemTop>
                        <BodyItemBot>
                            <Title>Lưu vào Folder</Title>
                            <ButtonSelectWrapper onClick={handleOpenModalSelectFolder}>
                                {selectedFolder != null ? (
                                    <>
                                        {" "}
                                        <ButtonSelect>{selectedFolder.name}</ButtonSelect>
                                    </>
                                ) : (
                                    <>
                                        <ButtonSelect>Chọn folder</ButtonSelect>
                                    </>
                                )}

                                <Icon src={Arrow.chevronright}></Icon>
                            </ButtonSelectWrapper>
                        </BodyItemBot>
                    </ModalBody>
                    <ModalFooter>
                        <FooterActionWrapper>
                            <FooterActionConfirm onClick={() => handleOnSave()}>Lưu</FooterActionConfirm>
                        </FooterActionWrapper>
                    </ModalFooter>
                </ModalWrapperInner>
            </ModalChangeWrapper>
        </>
    );
};

export default ModalSaveSet;

const slideInFromTop = keyframes`
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateY(0);
    }
`;

const ModalChangeWrapper = styled.div`
    width: 100%;
    height: 100vh;
    top: 0;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    z-index: 2;
    background: rgba(0, 0, 0, 0.8);
`;

const ModalWrapperInner = styled.div`
    width: 90%;
    margin: 0 auto;
    height: 33%;
    padding: 10px 16px;

    background: #272727;
    position: relative;
    animation: ${slideInFromTop} 0.3s ease-in-out forwards;
    transition: 1s all;
    /* border-radius: 16px 16px 0px 0px; */

    border-radius: 16px;
`;

const ModalHeader = styled.div`
    background: rgba(39, 39, 39, 0.6);

    height: 13%;
    backdrop-filter: blur(4px);
    display: flex;
    position: relative;
    align-items: center;
`;

const HeaderTitle = styled.h3`
    color: #fff;

    font-family: "Be Vietnam Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

const ModalBody = styled.div`
    height: 67%;
    width: 100%;
    margin-top: 3%;
`;

const BodyHeader = styled.div`
    /* Add your styles here */
`;

const BodyItem = styled.div`
    display: flex;

    flex-wrap: wrap;
`;

const ItemWrapper = styled.div`
    width: calc(100% / 4 - 12px);
    margin-right: 12px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 88px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid #484848;
    background: ${(props) => (props.bg ? props.bg : "#3a3a3a")};
`;

const ItemIcon = styled.img`
    width: 54px;
    height: 54px;
    flex-shrink: 0;
`;

const ItemTitle = styled.div`
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const HeaderAction = styled.img`
    position: absolute;
    right: 10px;
    margin-bottom: 0.5rem;
    z-index: 2;
`;

const SubTitle = styled.span`
    margin-left: 10px;
    color: #727272;
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const ModalFooter = styled.div`
    width: 100%;
    height: 15%;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const Button = styled.button`
    width: 40%;
    height: 42px;
    flex-shrink: 0;
    border-radius: 50px;
    border: 1px solid #484848;
    background: #3a3a3a;
    color: #fff;
    text-align: center;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;
const FooterAction = styled(Button)`
    /* width: 40%;
  height: 42px;
  flex-shrink: 0;
  border-radius: 50px;
  border: 1px solid #484848;
  background: #3a3a3a; */
`;

const FooterActionWrapper = styled.div`
    width: 80%;
    align-items: center;
    height: 100%;
    display: flex;
    justify-content: space-around;
`;

const FooterActionConfirm = styled(Button)`
    border-radius: 50px;
    border: 1px solid rgba(184, 229, 255, 0.2);
    background: linear-gradient(268deg, #0352fe 0.45%, #0060ff 97.59%);
    box-shadow: 0px 0px 15px 0px rgba(3, 82, 254, 0.4);
`;
const NoteInput = styled.input`
    width: 90%;
    color: white;
    height: auto;
    margin-top: 7px;
    margin-left: 7px;

    border-radius: 12px;
    background: #2f2f2f;
    border: none;

    overflow-wrap: break-word;
    outline: none;
`;

const NoteWrapper = styled.div`
    width: 100%;
    height: 214px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #2f2f2f;
    border: none;
`;

const BodyItemTop = styled.div`
    margin-bottom: 3%;
`;

const Title = styled.h3`
    color: #878787;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
`;
const Input = styled.input`
    width: 100%;
    height: 42px;
    border-radius: 12px;
    padding-left: 10px;
    color: #fff;
    background: #2f2f2f;
    flex-shrink: 0;
    border: none;
`;

const BodyItemBot = styled.div``;

const ButtonSelect = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
    border-radius: 12px;
    background: #2f2f2f;
    height: 42px;
    color: #fff;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const Icon = styled.img`
    position: absolute;
    right: 10px;
    top: 6px;
`;

const ButtonSelectWrapper = styled.div`
    position: relative;
`;
